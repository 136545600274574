import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//actions
// components
import { FormInput } from "../../components/";

import { resetPassword } from "../../services/api/auth";
import AuthLayout from "./AuthLayout";

/* bottom link */
const BottomLink = () => {
    const { t } = useTranslation();

    return (
        <Row className="mt-3">
            <Col className="text-center">
                <p className="text-white-50">
                    {t("Back to")}{" "}
                    <Link to={"/auth/login"} className="text-white ms-1">
                        <b>{t("Log in")}</b>
                    </Link>
                </p>
            </Col>
        </Row>
    );
};

const ForgetPassword = (props: any) => {
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [errors, setErrors] = useState<any>();
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState<String | null>(null);

    /*
     * handle form submission
     */
    const onSubmit = async () => {
        const { uidb64, token } = props.match.params;

        setErrors(undefined);
        setError(null);

        if (
            !new RegExp("^(?=.*[0-9])(?=.*[aA-zZ])(?!.* ).{8,16}$").test(
                password
            )
        ) {
            setErrors({
                password:
                    "Your password must be a minimum of 8 characters and have at least 1 letter and 1 number",
            });
            return;
        }

        if (password !== rePassword) {
            setErrors({ "re-password": "Passwords don't match" });
            return;
        }

        try {
            await resetPassword(uidb64, token, password);
            setChanged(true);
            // ToastNotification.success("Send reset password link to your email");
            // history.push("/login");
        } catch (error: any) {
            if (error.isFormDataError()) {
                const errors = error.getErrors();
                setErrors(errors);
            } else {
                setError(error.data);
            }
        }
    };

    return (
        <>
            <AuthLayout
                title={"Create new password"}
                helpText={
                    "Password must be a minimum of 8 characters and have at least 1 letter and 1 number"
                }
                bottomLinks={<BottomLink />}
            >
                {!changed && (
                    <div>
                        <FormInput
                            label={"Password"}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            value={password}
                            errors={errors}
                            placeholder="Enter your password"
                            containerClass={"mb-3"}
                        ></FormInput>

                        <FormInput
                            label={"Re-Password"}
                            type="password"
                            onChange={(e) => setRePassword(e.target.value)}
                            name="re-password"
                            value={rePassword}
                            errors={errors}
                            placeholder="Re-type password"
                            containerClass={"mb-3"}
                        ></FormInput>

                        <div className="text-center d-grid">
                            <Button variant="primary" onClick={onSubmit}>
                                {"Reset Password"}
                            </Button>
                        </div>
                    </div>
                )}

                {changed && (
                    <div className={"text-success mt-3 mb-0"}>
                        <p>Your account password was changed</p>
                        <Link to={"/auth/login"}>{"Login"}</Link>
                    </div>
                )}
                {error && (
                    <div className={"text-danger mt-3 mb-0"}>
                        <p>{error}</p>
                    </div>
                )}
            </AuthLayout>
        </>
    );
};

export default ForgetPassword;
