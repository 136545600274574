import { ReactChild } from "react";
import { Dropdown } from "react-bootstrap";

type ActionCellMenuProps = {
    id: string;
    items: Array<{
        label?: string;
        icon?: ReactChild;
        onClick: () => void;
        active?: boolean;
        id: string;
        disabled?: boolean;
    }>;
};

/**
 * ActionCellMenu
 */
export default function ActionCellMenu({ id, items }: ActionCellMenuProps) {
    const config = {
        modifiers: [
            {
                name: "computeStyles",
                options: {
                    gpuAcceleration: false, // true by default
                },
            },
        ],
    };
    return (
        <Dropdown>
            <Dropdown.Toggle
                as="span"
                role="button"
                id={`dropdown-${id}`}
                className="cursor-pointer"
                bsPrefix="no-chevron"
            >
                <i className="mdi mdi-dots-vertical"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu popperConfig={config}>
                {items.map((i) => (
                    <Dropdown.Item
                        onClick={i.onClick}
                        key={i.id}
                        disabled={i.disabled}
                    >
                        {i.icon}
                        {` `}
                        {i.label}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}
