import Request from "./request";
import Response from "./response";
import { parseException } from "../../utils/exceptions/parse_exception";
import { SiteModel } from "./models";

export const getSitesAPI = async () => {
    try {
        const response = await Request.getAuth("sites/");
        return new Response<SiteModel[]>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const createSiteAPI = async (site: Omit<SiteModel, 'id'|'archive'>) => {
    try {
        let body = {
            name: site.name,
            preamble: site.preamble,
            address: site.address,
            city: site.city,
            state: site.state,
            zip: site.zip,
            timezone: site.timezone
        };

        const response = await Request.postAuth("sites/", body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const editSiteAPI = async (site: SiteModel) => {
    try {
        let body = {
            name: site.name,
            preamble: site.preamble,
            address: site.address,
            city: site.city,
            state: site.state,
            zip: site.zip,
            archive: site.archive,
            timezone: site.timezone
        };

        const response = await Request.patchAuth(`sites/${site.id}/`, body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const getSiteAPI = async (id: number) => {
    try {
        const response = await Request.getAuth(`sites/${id}/`);
        return new Response<SiteModel>(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};
