import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// components

const Error404Svg = () => {
    return (
        <svg viewBox="0 0 600 200">
            <symbol id="s-text">
                <text textAnchor="middle" x="50%" y="50%" dy=".35em">
                    403!
                </text>
            </symbol>
            <use className="text" href="#s-text"></use>
            <use className="text" href="#s-text"></use>
            <use className="text" href="#s-text"></use>
            <use className="text" href="#s-text"></use>
            <use className="text" href="#s-text"></use>
        </svg>
    );
};

const Error403 = () => {
    return (
        <>
            <Row className="justify-content-center">
                <Col lg={6} xl={4} className="mb-4">
                    <div className="error-text-box">
                        <Error404Svg />
                    </div>

                    <div className="text-center">
                        <h3 className="mt-0 mb-2">Resource not found</h3>
                        <p className="text-muted mb-3">
                            It's look we can't find your resource
                        </p>
                        <Link
                            to="/"
                            className="btn btn-success waves-effect waves-light"
                        >
                            Back to Dashboard
                        </Link>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Error403;
