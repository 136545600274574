import Request from "./request";
import Response from "./response";
import LocalStorage from "../local_storage";
import {parseException} from "../../utils/exceptions/parse_exception";

export const login = async (
    username: string,
    password: string
): Promise<Response> => {
    try {
        const responseBody = await Request.post("auth/login/", {
            username: username,
            password: password,
        });
        const response: Response = new Response(
            responseBody.data,
            responseBody.status
        );
        const data = response.data;
        LocalStorage.setToken(data.token);
        LocalStorage.setUser(data.user);
        return response;
    } catch (error) {
        throw parseException(error);
    }
};
export const forgotPassword = async (
    email: string,
): Promise<Response> => {
    try {
        const responseBody = await Request.post("auth/forgot_password/", {
            email: email,
        });
        return new Response(
            responseBody.data,
            responseBody.status
        );
    } catch (error) {
        throw parseException(error);
    }
};

export const resetPassword = async (
    uidb64: string,
    token: string,
    password: string,
): Promise<Response> => {
    try {
        const responseBody = await Request.post("auth/reset_password/", {
            uidb64: uidb64,
            token: token,
            password: password,
        });
        return new Response(
            responseBody.data,
            responseBody.status
        );
    } catch (error) {
        throw parseException(error);
    }
};

export const isAuthenticated = () => {
    return LocalStorage.getUser() != null && LocalStorage.getToken() != null;
};

export const logout = async () => {
    try {
        LocalStorage.clear();
        // const response = await Request.post("auth/login/", {
        //   username: username,
        //   password: password,
        // });
        // return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};
