import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { createSiteAPI } from "../../services/api/sites";
import { ToastNotification } from "../../services/toast_notification";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";

const CreateSite = () => {
    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [errors, setErrors] = useState();

    const [name, setName] = useState("");
    const [preamble, setPreamble] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState<number>(0);
    const [timezone, setTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    const handleSubmit = (event: any) => {
        event.preventDefault();
        createSite();
    };

    const createSite = async () => {
        setCreating(true);
        try {
            const response = await createSiteAPI({
                name: name,
                city: city,
                address: address,
                preamble: preamble,
                state: state,
                zip: zip,
                timezone: timezone,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Site is created");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setCreating(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Sites",
                                        path: "/sites",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Create ".concat(
                                    name.length > 0 ? name : "Site"
                                )}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <FormInput
                                        required
                                        type="text"
                                        label="Site Name"
                                        placeholder="Site Name"
                                        value={name}
                                        errors={errors}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        name="name"
                                    />

                                    <FormInput
                                        required
                                        type="text"
                                        label="Preamble"
                                        placeholder="Preamble"
                                        value={preamble}
                                        errors={errors}
                                        onChange={(e) =>
                                            setPreamble(e.target.value)
                                        }
                                        name="preamble"
                                    />

                                    <FormInput
                                        required
                                        type="text"
                                        label="Address"
                                        placeholder="Address"
                                        value={address}
                                        errors={errors}
                                        onChange={(e) =>
                                            setAddress(e.target.value)
                                        }
                                        name="address"
                                    />

                                    <FormInput
                                        required
                                        type="text"
                                        label="City"
                                        placeholder="City"
                                        value={city}
                                        errors={errors}
                                        onChange={(e) =>
                                            setCity(e.target.value)
                                        }
                                        name="city"
                                    />

                                    <FormInput
                                        required
                                        type="text"
                                        label="State"
                                        placeholder="State"
                                        value={state}
                                        errors={errors}
                                        onChange={(e) =>
                                            setState(e.target.value)
                                        }
                                        name="state"
                                    />

                                    <FormInput
                                        required
                                        type="number"
                                        label="Zip"
                                        placeholder="Zip"
                                        value={zip}
                                        errors={errors}
                                        onChange={(e) =>
                                            setZip(parseInt(e.target.value))
                                        }
                                        name="zip"
                                    />

                                    <FormInput
                                        type="timezone"
                                        label="Timezone"
                                        placeholder="Timezone"
                                        value={timezone}
                                        errors={errors}
                                        onChange={(e: any) =>
                                            setTimezone(e.value)
                                        }
                                        name="timezone"
                                    />

                                    <Row className="mt-3 ">
                                        <Col>
                                            <Button
                                                type="submit"
                                                className="float-end"
                                                onClick={handleSubmit}
                                                disabled={creating}
                                            >
                                                <i
                                                    className={
                                                        (creating
                                                            ? "mdi mdi-spin mdi-loading"
                                                            : "mdi mdi-check") +
                                                        " me-1"
                                                    }
                                                />
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default CreateSite;
