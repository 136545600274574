import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import PageLoader from "../../components/PageLoader";
import { importClassChildrenAPI } from "../../services/api/class_children";
import { UserModel } from "../../services/api/models";
import { getUsersAPI } from "../../services/api/user";
import { ToastNotification } from "../../services/toast_notification";
import { handleException } from "../../utils/exceptions/handle_exception";
import ErrorLines, { LineWithError } from "./error_lines";
import { set } from "react-hook-form";

const ImportClassChildren = (props: any) => {
    const { id } = props.match.params;
    const [pageLoading, setPageLoading] = useState();
    const [file, setFile] = useState<FileList | null>(null);
    const history = useHistory();
    const [importing, setImporting] = useState(false);
    const [checkInNotification, setCheckInNotification] = useState(false);
    const [checkOutNotification, setCheckOutNotification] = useState(false);
    const [groupNotification, setGroupNotification] = useState(true);
    const [feedNotification, setFeedNotification] = useState(true);
    const [sCheckInNotification, setSCheckInNotification] = useState(false);
    const [sCheckOutNotification, setSCheckOutNotification] = useState(false);
    const [sGroupNotification, setSGroupNotification] = useState(false);
    const [sFeedNotification, setSFeedNotification] = useState(false);
    const [users, setUser] = useState<UserModel[]>([]);
    const [nonAdminUsers, setNonAdminUsers] = useState<UserModel[]>([]);
    const [selectedUser, setSelectedUser] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [linesWithErrors, setLinesWithErrors] = useState<LineWithError[]>([]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        if (file == null) {
            ToastNotification.error("Please select file");
            return;
        }

        uploadChildren();
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const userResponse = await getUsersAPI();
            const localUsers = userResponse.data;
            setUser(localUsers);

            // const user: UserModel | null = LocalStorage.getUser();
            // if (user != null) {
            //     setSelectedUser([user]);
            // }

            if (localUsers.length >= 0) {
                setNonAdminUsers(
                    localUsers.filter(
                        (user) =>
                            user.role === "site_lead" || user.role === "staffer"
                    )
                );
            }
        } catch (e) {
            ToastNotification.unknownError();
            handleException(history, e);
        }
    };
    async function uploadChildren() {
        if (file == null) return;
        setImporting(true);
        var usersId: any;
        if (selectedUser.length === 0) {
            const site_admin = users.filter(
                (user) => user.role === "site_admin"
            );
            usersId = site_admin.map((user) => user.id);
            setSelectedUser(
                site_admin.map((a) => ({
                    id: a.id,
                    label: `${a.first_name} ${a.last_name}`,
                }))
            );
        } else {
            usersId = selectedUser.map((user) => user.id);
        }

        try {
            const response = await importClassChildrenAPI({
                id: id,
                file: file[0],
                checkout_notification: checkOutNotification,
                checkin_notification: checkInNotification,
                feed_notification: feedNotification,
                group_notification: groupNotification,
                s_checkin_notification: sCheckInNotification,
                s_checkout_notification: sCheckOutNotification,
                s_group_notification: sGroupNotification,
                s_feed_notification: sFeedNotification,
                usersId: usersId,
            });

            if (response.isSuccess()) {
                const errors = Object.entries(response.data?.errors ?? {})
                    .map(([key, value]) => ({
                        lineNum: key,
                        errors: value as string[],
                    }))
                    .filter((e) => e.errors.length > 0);
                if (errors.length > 0) {
                    setLinesWithErrors(errors);
                    ToastNotification.error(
                        "Some lines have errors and others are imported successfully. Please correct them in the file and try again (only failed lines)."
                    );
                } else {
                    history.goBack();
                    ToastNotification.success(
                        "Children are imported successfully"
                    );
                }
            }
        } catch (e) {
            const errors = handleException(history, e);
            if (errors != null) {
                ToastNotification.error(errors.error);
            } else {
                ToastNotification.unknownError();
            }
        }
        setImporting(false);
    }

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <div className="page-title-box">
                                <div className="page-title-right"></div>
                                <h4 className="page-title">
                                    {" "}
                                    {"Session Class"}
                                </h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Form onSubmit={handleSubmit}>
                            <input
                                type="file"
                                onChange={(e) => setFile(e.target.files)}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />

                            <Button type="submit" className="float-end">
                                <i
                                    className={
                                        (importing
                                            ? "mdi mdi-spin mdi-loading"
                                            : "mdi mdi-check") + " me-1"
                                    }
                                />{" "}
                                Import
                            </Button>
                        </Form>

                        <div className="ms-2 mt-3">
                            <Form.Label> Notification Settings: </Form.Label>

                            <div className="d-flex mt-2">
                                <div>
                                    <Form.Label> Primary Contact </Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        id="check-in-check"
                                        className="mt-2"
                                        label="Check In "
                                        checked={checkInNotification}
                                        onChange={(e) =>
                                            setCheckInNotification(
                                                e.target.checked
                                            )
                                        }
                                    />

                                    <Form.Check
                                        type="checkbox"
                                        id="check-out-check"
                                        label="Check Out"
                                        className="mt-2"
                                        checked={checkOutNotification}
                                        onChange={(e) =>
                                            setCheckOutNotification(
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="group-check"
                                        label="Group Message"
                                        className="mt-2"
                                        checked={groupNotification}
                                        onChange={(e) =>
                                            setGroupNotification(
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="feed-check"
                                        label="Feed"
                                        className="mt-2"
                                        checked={feedNotification}
                                        onChange={(e) =>
                                            setFeedNotification(
                                                e.target.checked
                                            )
                                        }
                                    />
                                </div>
                                <div className="ms-5">
                                    <Form.Label> Secondary Contact </Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        id="s-check-in-check"
                                        className="mt-2"
                                        label="Check In "
                                        checked={sCheckInNotification}
                                        onChange={(e) =>
                                            setSCheckInNotification(
                                                e.target.checked
                                            )
                                        }
                                    />

                                    <Form.Check
                                        type="checkbox"
                                        id="s-check-out-check"
                                        label="Check Out"
                                        className="mt-2"
                                        checked={sCheckOutNotification}
                                        onChange={(e) =>
                                            setSCheckOutNotification(
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="s-group-check"
                                        label="Group Message"
                                        className="mt-2"
                                        checked={sGroupNotification}
                                        onChange={(e) =>
                                            setSGroupNotification(
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="s-feed-check"
                                        label="Feed"
                                        className="mt-2"
                                        checked={sFeedNotification}
                                        onChange={(e) =>
                                            setSFeedNotification(
                                                e.target.checked
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Col lg={12} md={8} className="mx-auto">
                            <Form.Label className="mt-4">
                                Give access to the following users:
                            </Form.Label>

                            <CustomSelect
                                id="user-select"
                                multiple={true}
                                emptyLabel={"There is no user"}
                                onChange={(e) => {
                                    setSelectedUser(e);
                                }}
                                options={nonAdminUsers.map((a) => ({
                                    id: a.id,
                                    label: `${a.first_name} ${a.last_name}`,
                                }))}
                                placeholder="Select users"
                                selected={selectedUser}
                                className={"overflowY"}
                            />
                        </Col>
                    </Row>
                    {linesWithErrors.length > 0 ? (
                        <ErrorLines linesWithErrors={linesWithErrors} />
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </>
    );
};

export default ImportClassChildren;
