import Request from "./request";
import Response from "./response";
import { parseException } from "../../utils/exceptions/parse_exception";

export const getFamilyRelationsAPI = async () => {
    try {
        const response = await Request.getAuth("family_relations/");
        return new Response(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};
