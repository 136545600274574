import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import PlaceHolderImage from "../../components/PlaceholderImage";
import Table from "../../components/Table";
import {
    getCheckInsAPI,
    markCheckinLatePickupAPI,
    markCheckinTardyAPI,
} from "../../services/api/check_ins";
import { CheckinModel } from "../../services/api/models";
import Request from "../../services/api/request";
import { ToastNotification } from "../../services/toast_notification";
import { getFormattedDate } from "../../utils/DateFormat";
import { handleException } from "../../utils/exceptions/handle_exception";

// components

const CheckInList = () => {
    const [checkIns, setCheckIns] = useState<CheckinModel[]>([]);
    const [pageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getCheckInList();
    }, []);

    async function getCheckInList() {
        setPageLoading(true);

        try {
            const response = await getCheckInsAPI();
            setCheckIns(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    async function markAsTardy(checkin_id: number) {
        const response = await markCheckinTardyAPI(checkin_id);
        if (response.isSuccess()) {
            ToastNotification.success("Marked as tardy");
            let data = checkIns.map((checkin) => {
                if (checkin.id === checkin_id) {
                    return {
                        ...checkin,
                        tardy: true,
                    };
                } else {
                    return checkin;
                }
            });
            setCheckIns(data);
        }
    }

    async function markAsLatePickup(checkin_id: number) {
        const response = await markCheckinLatePickupAPI(checkin_id);
        if (response.isSuccess()) {
            ToastNotification.success("Marked as late pickup");
            let data = checkIns.map((checkin) => {
                if (checkin.id === checkin_id) {
                    return {
                        ...checkin,
                        late_pickup: true,
                    };
                } else {
                    return checkin;
                }
            });
            setCheckIns(data);
        }
    }

    const columns = [
        {
            Header: "ID",
            accessor: "id",
            sort: true,
            maxWidth: 60,
        },
        {
            Header: "Name",
            accessor: "child",
            sort: true,
            Cell: (data: any) => {
                data = data.row.original;

                function getAvatar() {
                    let avatar = data.child.avatar;

                    if (avatar != null) {
                        return PlaceHolderImage({
                            src: Request.getImageUrl(avatar),
                            rounded: true,
                            size: 36,
                        });
                    }
                    return PlaceHolderImage({
                        rounded: true,
                        size: 36,
                    });
                }

                return (
                    <div className="d-flex align-items-center">
                        {getAvatar()}
                        <span className="ms-2 cursor-pointer">
                            {data.child.first_name + " " + data.child.last_name}
                        </span>
                    </div>
                );
            },
        },
        {
            Header: "Checkin",
            accessor: "checkin",
            sort: true,
            Cell: (data: any) => {
                return (
                    <span>{getFormattedDate(new Date(data.cell.value))}</span>
                );
            },
        },
        {
            Header: "Checkout",
            accessor: "checkout",
            sort: true,
            Cell: (data: any) => {
                if (data.cell.value === null) {
                    return <span className="text-info">Not checked out</span>;
                }
                return (
                    <span>{getFormattedDate(new Date(data.cell.value))}</span>
                );
            },
        },

        {
            Header: "Edit",
            accessor: "id1",
            maxWidth: 60,
            Cell: (data: any) => {
                let id = data.row.allCells[0].value;

                return (
                    <Link to={`/check_ins/${id}`}>
                        <i className="mdi mdi-pencil-outline"></i>
                    </Link>
                );
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: checkIns.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Check Ins",
                                        active: true,
                                    },
                                ]}
                                title="Check Ins"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Table
                                        id="check-ins"
                                        columns={columns}
                                        data={checkIns}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={true}
                                        tableClass={"table-striped"}
                                        actions={
                                            <Link
                                                to="/check_ins/create"
                                                className="btn btn-primary"
                                            >
                                                <i className="mdi mdi-plus-circle  me-1"></i>
                                                Create Checkin
                                            </Link>
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default CheckInList;
