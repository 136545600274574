import React, {useState} from "react";
import avatarPlaceholder from "../assets/images/avatar_placeholder.png";
import {Modal} from "react-bootstrap";

interface PlaceHolderImageProps {
    rounded?: boolean;
    size?: number;
    src?: string | null;
    showModal?: boolean;
    className?: string | undefined;
    style?: object | undefined;
}

const PlaceHolderImage = (props: PlaceHolderImageProps) => {
    const [modal, setModal] = useState<boolean>(false);

    const toggle = () => {
        props.src && setModal(!modal);
    };

    return (
        <div className="d-inline-block">
            <img
                src={props.src ?? avatarPlaceholder}
                alt=""
                className={`${props.rounded ? "rounded-circle" : ""} ${
                    props.className
                } `}
                width={`${props.size}`}
                height={`${props.size}`}
                onClick={toggle}
                style={props.style}
            />
            <Modal
                show={modal}
                onHide={toggle}
                dialogClassName="modal-dialog-centered modal-xl"
            >
                <img
                    src={props.src ?? avatarPlaceholder}
                    alt=""
                    className="img-fluid"
                />
            </Modal>
        </div>
    );
};

PlaceHolderImage.props = {
    showModal: false,
};

export default PlaceHolderImage;
