import { parseException } from "../../utils/exceptions/parse_exception";
import { FeedModel } from "./models";
import Request from "./request";
import Response from "./response";

export const getGuestFeedsAPI = async (search: string) => {
    try {
        const response = await Request.get("guest_feeds" + search);
        return new Response<FeedModel[]>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};
