import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import ActionCellMenu from "../../components/ActionCellMenu";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import { getCheckinStatusFromChild } from "../../services/api/check_ins";
import {
    getClassesAPI,
    getUniqueChildrenFromClass,
} from "../../services/api/classes";
import {
    ITableData,
    SessionClassModel,
    statuses,
} from "../../services/api/models";
import { Permission } from "../../services/permission";
import { handleException } from "../../utils/exceptions/handle_exception";
import { capitalize } from "../../utils/strings";

const ClassList = () => {
    const [classes, setClasses] = useState<SessionClassModel[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<
        CustomSelectOption<string>[]
    >([{ id: "active", label: "Active" }]);
    const [pageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getClassesList();
    }, []);

    async function getClassesList(statuses?: string[]) {
        setPageLoading(true);
        try {
            const response = await getClassesAPI(statuses);
            setClasses(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    async function changeStatus(s: CustomSelectOption<string>[]) {
        setSelectedStatus(s);
        await getClassesList(s.map(({ id }) => id));
    }

    const clone = (row: SessionClassModel) => {
        history.push("/classes/create", { sourceClassId: row.id });
    };

    let columns = [
        {
            Header: "Name",
            accessor: "name",
            sort: true,
            Cell: (data: ITableData<SessionClassModel, any>) => {
                let id = data.row.original.id;
                return (
                    <Link to={"/class_children/" + id}>{data.cell.value}</Link>
                );
            },
        },
        {
            Header: "Session",
            accessor: "session",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value.name}</span>;
            },
        },

        {
            Header: "Site",
            accessor: "site",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.row.original.session.site.name}</span>;
            },
        },
        {
            Header: "Enrolled",
            accessor: "total_kids",
            sort: true,
            Cell: (data: ITableData<SessionClassModel, any>) => {
                const children = getUniqueChildrenFromClass(data.row.original);
                // const unique2 = children.filter((obj, index) => {
                //     return index === children.findIndex(o => obj.id === o.id);
                // });

                children.filter(
                    (item, index) => children.indexOf(item) === index
                );
                return <span>{children?.length}</span>;
            },
        },
        {
            Header: "Checked In",
            accessor: "checked_in",
            sort: true,
            Cell: (data: ITableData<SessionClassModel, any>) => {
                const children = getUniqueChildrenFromClass(data.row.original);
                let count = 0;

                // const unique2 = children.filter((obj, index) => {
                //     return index === children.findIndex(o => obj.id === o.id);
                // });
                for (const child of children) {
                    if (getCheckinStatusFromChild(child) === "checkin") {
                        count++;
                    }
                }
                return <span>{count}</span>;
            },
        },
        {
            Header: "Absent",
            accessor: "absent",
            sort: true,
            Cell: (data: ITableData<SessionClassModel, any>) => {
                const children = getUniqueChildrenFromClass(data.row.original);
                let count = 0;
                // const unique2 = children.filter((obj, index) => {
                //     return index === children.findIndex(o => obj.id === o.id);
                // });
                for (var child of children) {
                    if (getCheckinStatusFromChild(child) === "absent") {
                        count++;
                    }
                }
                return <span>{count}</span>;
            },
        },

        {
            Header: "Status",
            accessor: "status",
            sort: true,
            Cell: (data: any) => {
                let draft = data.row.original.draft;

                let archive = data.row.original.archive;
                if (archive) {
                    return (
                        <span>
                            <small className="mdi mdi-circle text-danger fs-6" />{" "}
                            Archived
                        </span>
                    );
                } else if (draft) {
                    return (
                        <span>
                            <small className="mdi mdi-circle text-secondary fs-6" />{" "}
                            Draft
                        </span>
                    );
                }
                return (
                    <span>
                        <small className="mdi mdi-circle text-success fs-6" />{" "}
                        Active
                    </span>
                );
            },
        },
        {
            Header: "Actions",
            show: false,
            isVisible: false,
            accessor: "id1",
            maxWidth: 70,
            Cell: (data: any) => {
                const id = data.row.original.id;
                return (
                    <ActionCellMenu
                        id={`classes-${id}-menu`}
                        items={[
                            ...(Permission.canEditSessionClass()
                                ? [
                                      {
                                          id: `edit-${id}`,
                                          label: "Edit",
                                          icon: (
                                              <i className="mdi mdi-pencil-outline"></i>
                                          ),
                                          onClick: () => {
                                              history.push(`/classes/${id}`);
                                          },
                                      },
                                  ]
                                : []),
                            ...(Permission.canEditSessionClass()
                                ? [
                                      {
                                          id: `clone-${id}`,
                                          label: "Clone",
                                          icon: (
                                              <i className="mdi mdi-content-copy"></i>
                                          ),
                                          onClick: () => {
                                              clone(data.row.original);
                                          },
                                      },
                                  ]
                                : []),
                        ]}
                    />
                );
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: classes.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Classes",
                                        active: true,
                                    },
                                ]}
                                title={"Session Classes"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Table
                                        id="classes"
                                        columns={columns}
                                        data={classes}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={true}
                                        tableClass={"table-striped"}
                                        filters={
                                            <CustomSelect
                                                id="status-select"
                                                multiple={true}
                                                emptyLabel={
                                                    "There are no classes"
                                                }
                                                onChange={(e) => {
                                                    changeStatus(e);
                                                }}
                                                options={statuses.map((s) => ({
                                                    id: s,
                                                    label: capitalize(s),
                                                }))}
                                                placeholder="Select a status"
                                                selected={selectedStatus}
                                            />
                                        }
                                        actions={
                                            Permission.canCreateSessionClass() && (
                                                <Link
                                                    to="/classes/create"
                                                    className="btn btn-primary"
                                                >
                                                    <i className="mdi mdi-plus-circle  me-1"></i>
                                                    Add Class
                                                </Link>
                                            )
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default ClassList;
