import { parseException } from "../../utils/exceptions/parse_exception";
import { SessionClassModel } from "./models";
import Request from "./request";
import Response from "./response";

export const getClassesAPI = async (statuses?: string[]) => {
    try {
        let url =
            "session_classes/" +
            (statuses != null ? "?statuses=" + statuses : "");
        const response = await Request.getAuth(url);
        return new Response<SessionClassModel[]>(
            response.data,
            response.status
        );
    } catch (e) {
        throw parseException(e);
    }
};

export const createClassAPI = async ({
    name,
    startDate,
    endDate,
    sendFeedUrlAt,
    sessionId,
    draft,
    autoCheckout,
    sendCheckoutNotification,
    autoCheckoutAt,
    sendReportTo,
    attendanceNotification,
    sendOn,
    checkedIn,
    checkedOut,
    absent,
    sendAt,
    sendTo,
}: {
    name: string;
    startDate: Date;
    endDate: Date;
    sendFeedUrlAt: Date;
    sessionId: number;
    draft: boolean;
    autoCheckout: boolean;
    sendCheckoutNotification?: boolean;
    autoCheckoutAt?: Date;
    sendReportTo?: string;
    attendanceNotification?: boolean;
    sendOn?: string;
    checkedIn?: boolean;
    checkedOut?: boolean;
    absent?: boolean;
    sendAt?: Date;
    sendTo?: string;
}) => {
    let autoCheckoutTime = null;
    if (autoCheckoutAt != null) {
        autoCheckoutTime = autoCheckoutAt.getTime();
    }
    let sendFeedUrlAtTime = null;
    if (sendFeedUrlAt != null) {
        sendFeedUrlAtTime = sendFeedUrlAt.getTime();
    }
    let sendAtTime = null;
    if (sendAt != null) {
        sendAtTime = sendAt.getTime();
    }
    try {
        let body = {
            name: name,
            start_date: startDate,
            end_date: endDate,
            session_id: sessionId,
            send_feed_link_at: sendFeedUrlAtTime,
            draft: draft,
            auto_checkout: autoCheckout,
            auto_checkout_at: autoCheckoutTime,
            send_checkout_notification: sendCheckoutNotification,
            send_report_to: sendReportTo,
            attendance_notification: attendanceNotification,
            attendance_send_on: sendOn,
            attendance_checked_in: checkedIn,
            attendance_checked_out: checkedOut,
            attendance_absent: absent,
            attendance_sent_at: sendAtTime,
            attendance_to: sendTo,
        };

        const response = await Request.postAuth("session_classes/", body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const editClassAPI = async (
    id: number,
    {
        name,
        startDate,
        endDate,
        sendFeedUrlAt,
        sessionId,
        draft,
        archive,
        autoCheckout,
        sendCheckoutNotification,
        autoCheckoutAt,
        sendReportTo,
        attendanceNotification,
        sendOn,
        checkedIn,
        checkedOut,
        absent,
        sendAt,
        sendTo,
    }: {
        name: string;
        startDate: Date;
        endDate: Date;
        sendFeedUrlAt?: Date;
        sessionId: number;
        archive: boolean;
        draft: boolean;
        autoCheckout: boolean;
        sendCheckoutNotification?: boolean;
        autoCheckoutAt?: Date;
        sendReportTo?: string;
        attendanceNotification?: boolean;
        sendOn?: string;
        checkedIn?: boolean;
        checkedOut?: boolean;
        absent?: boolean;
        sendAt?: Date;
        sendTo?: string;
    }
) => {
    try {
        let autoCheckoutTime = null;
        if (autoCheckoutAt != null) {
            autoCheckoutTime = autoCheckoutAt.getTime();
        }
        let sendFeedUrlAtTime = null;
        if (sendFeedUrlAt != null) {
            sendFeedUrlAtTime = sendFeedUrlAt.getTime();
        }
        let sendAtTime = null;
        if (sendAt != null) {
            sendAtTime = sendAt.getTime();
        }
        let body = {
            name: name,
            start_date: startDate,
            end_date: endDate,
            session_id: sessionId,
            archive: archive,
            draft: draft,
            send_feed_link_at: sendFeedUrlAtTime,
            auto_checkout: autoCheckout,
            auto_checkout_at: autoCheckoutTime,
            send_checkout_notification: sendCheckoutNotification,
            send_report_to: sendReportTo,
            attendance_notification: attendanceNotification,
            attendance_send_on: sendOn,
            attendance_checked_in: checkedIn,
            attendance_checked_out: checkedOut,
            attendance_absent: absent,
            attendance_sent_at: sendAtTime,
            attendance_to: sendTo,
        };

        const response = await Request.patchAuth(
            `session_classes/${id}/`,
            body
        );
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const getClassAPI = async (id: number) => {
    try {
        const response = await Request.getAuth(`session_classes/${id}`);
        return new Response<SessionClassModel>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const getChildrenFromClass = (session_class: SessionClassModel) => {
    const children = [];
    for (const group of session_class.groups ?? []) {
        if (group.children != null) children.push(...group.children);
    }
    return children;
};

export const getUniqueChildrenFromClass = (
    session_class: SessionClassModel
) => {
    const children: any[] = getChildrenFromClass(session_class);

    const unique2 = children.filter((obj, index) => {
        return index === children.findIndex((o) => obj.id === o.id);
    });
    return unique2;
};
