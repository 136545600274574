import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// actions
import { changeSidebarType } from "../../redux/actions";

// store
import { AppDispatch, RootState } from "../../redux/store";

// constants
import { LayoutTypes, SideBarTypes } from "./constants";

// utils
import { changeBodyAttribute } from "../../utils";
import { ToastNotification } from "../../services/toast_notification";
import { ToastContent, ToastOptions } from "react-toastify/dist/types";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import("./Topbar"));
const LeftSidebar = React.lazy(() => import("./LeftSidebar"));
const Footer = React.lazy(() => import("./Footer"));
const RightSidebar = React.lazy(() => import("./RightSidebar"));

const loading = () => <div className=""></div>;

interface VerticalLayoutProps {
    children?: any;
}

const VerticalLayout = ({ children }: VerticalLayoutProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const {
        layoutColor,
        layoutWidth,
        menuPosition,
        leftSideBarTheme,
        leftSideBarType,
        showSidebarUserInfo,
        showTwoToneIcons,
        topbarTheme,
        isOpenRightSideBar,
    } = useSelector((state: RootState) => ({
        layoutColor: state.Layout.layoutColor,
        layoutWidth: state.Layout.layoutWidth,
        menuPosition: state.Layout.menuPosition,
        leftSideBarTheme: state.Layout.leftSideBarTheme,
        leftSideBarType: state.Layout.leftSideBarType,
        showSidebarUserInfo: state.Layout.showSidebarUserInfo,
        showTwoToneIcons: state.Layout.showTwoToneIcons,
        topbarTheme: state.Layout.topbarTheme,
        isOpenRightSideBar: state.Layout.isOpenRightSideBar,
    }));

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

    /*
      layout defaults
      */
    useEffect(() => {
        changeBodyAttribute("data-layout-mode", LayoutTypes.LAYOUT_VERTICAL);
        ToastNotification.attach("main-layout", notify);
    }, []);

    useEffect(() => {
        changeBodyAttribute("data-layout-color", layoutColor);
    }, [layoutColor]);

    useEffect(() => {
        changeBodyAttribute("data-layout-width", layoutWidth);
    }, [dispatch, layoutWidth]);

    useEffect(() => {
        changeBodyAttribute("data-menu-position", menuPosition);
    }, [menuPosition]);

    useEffect(() => {
        changeBodyAttribute("data-leftbar-color", leftSideBarTheme);
    }, [leftSideBarTheme]);

    useEffect(() => {
        changeBodyAttribute("data-leftbar-size", leftSideBarType);
    }, [leftSideBarType]);

    useEffect(() => {
        changeBodyAttribute("data-topbar-color", topbarTheme);
    }, [topbarTheme]);

    useEffect(() => {
        changeBodyAttribute(
            "data-sidebar-icon",
            showTwoToneIcons ? "twotones" : ""
        );
    }, [showTwoToneIcons]);

    useEffect(() => {
        changeBodyAttribute("data-sidebar-user", showSidebarUserInfo);
    }, [showSidebarUserInfo]);

    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => !prevState);

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove("sidebar-enable");
            } else {
                document.body.classList.add("sidebar-enable");
            }
        }
    };

    const updateDimensions = useCallback(() => {
        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (window.innerWidth > 768 && window.innerWidth <= 1028) {
            dispatch(
                changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED)
            );
        } else if (window.innerWidth > 1028) {
            dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT));
        }
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);

        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, [dispatch, updateDimensions]);

    const isCondensed: boolean =
        leftSideBarType === SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED;

    const notify = (content: ToastContent, options?: ToastOptions) =>
        toast(content, options);

    return (
        <>
            <div id="wrapper">
                <Suspense fallback={loading()}>
                    <Topbar openLeftMenuCallBack={openMenu} hideLogo={false} />
                </Suspense>
                <Suspense fallback={loading()}>
                    <LeftSidebar isCondensed={isCondensed} />
                </Suspense>
                <div className="content-page">
                    <div className="content">
                        <Container fluid>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>

                    <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>
                </div>
            </div>
            <ToastContainer />

            {isOpenRightSideBar && (
                <Suspense fallback={loading()}>
                    <RightSidebar />
                </Suspense>
            )}
        </>
    );
};
export default VerticalLayout;
