import { ToastContent, ToastOptions } from "react-toastify/dist/types";

export interface ToastNotificationOptions {}

export type ToastNotificationObserver = (
    content: ToastContent,
    options?: ToastOptions
) => void;

export class ToastNotification {
    private static observers: Map<String, ToastNotificationObserver> = new Map<
        String,
        ToastNotificationObserver
    >();

    public static attach(key: string, observer: ToastNotificationObserver) {
        this.observers.set(key, observer);
    }

    public static _notify(content: ToastContent, options?: ToastOptions) {
        this.observers.forEach((observer, key) => {
            observer(content, options);
        });
    }

    public static success(content: string) {
        this._notify(content, {
            type: "success",
            position: "bottom-right",
        });
    }

    public static error(content: string) {
        this._notify(content, {
            type: "error",
            position: "bottom-right",
        });
    }

    public static unknownError() {
        this.error("Something went wrong");
    }
}
