import { Typeahead, TypeaheadProps } from "react-bootstrap-typeahead";

export type CustomSelectOption<TId extends string | number> = {
    id: TId;
    label: string;
};

const CustomSelect = <TId extends string | number>({
    options,
    ...props
}: TypeaheadProps<CustomSelectOption<TId>> & {
    id: string;
    options: CustomSelectOption<TId>[];
}) => {
    return (
        <Typeahead
            {...props}
            flip={props.flip ?? true}
            options={options}
            labelKey={(option) => `${option.label}`}
            filterBy={(option, props) => {
                if (props.selected.length) {
                    return true;
                }
                return (
                    option.label
                        .toLowerCase()
                        .indexOf(props.text.toLowerCase()) !== -1
                );
            }}
            className="custom-select"
        />
    );
};

export default CustomSelect;
