import React from "react";
import {Form} from "react-bootstrap";

interface ValidationFeedbackProps {
    errors?: any,
    name?: string
}

const ValidationFeedback = (props: ValidationFeedbackProps) => {

    return <>{props.errors && props.name && props.errors[props.name] ? (
        <Form.Control.Feedback type="invalid" className='d-block'>
            {props.errors[props.name]}
        </Form.Control.Feedback>) : null}</>;
};


export default ValidationFeedback;
