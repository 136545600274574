import { MouseEventHandler, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface ConfirmationModalProps {
    show?: boolean;
    body?: any;
    title?: any;
    onConfirm?: MouseEventHandler;
    onCancel?: Function;
    actions?: any;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show ?? false);
    }, [props.show]);

    const cancel = () => {
        if (props.onCancel == null) {
            setShow(false);
        } else {
            props.onCancel();
        }
    };

    return (
        <Modal show={show}>
            <Modal.Header onHide={cancel} closeButton>
                <h4 className="modal-title">{props.title}</h4>
            </Modal.Header>
            <Modal.Body>{props.body}</Modal.Body>
            <Modal.Footer>
                {props.actions == null ? (
                    <>
                        <Button variant="light" onClick={(e) => cancel()}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={props.onConfirm}>
                            Confirm
                        </Button>
                    </>
                ) : (
                    props.actions
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
