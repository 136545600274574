import { useCallback, useEffect } from "react";
import { Button } from "react-bootstrap";

export default function FullscreenImage({
    imageUrl,
    onClose,
}: {
    imageUrl: string;
    onClose: () => void;
}) {
    const escFunction = useCallback(
        (event) => {
            if (event.key === "Escape") {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                position: "fixed",
                zIndex: 10000,
            }}
        >
            <div
                style={{
                    background: `url(${imageUrl}) center center no-repeat`,
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 10000,
                }}
            ></div>
            <Button
                className="text-white"
                variant="link"
                onClick={() => onClose()}
                style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    fontSize: "20px",
                    zIndex: 10001,
                }}
            >
                <span>{"✕"}</span>
            </Button>
        </div>
    );
}
