import { UserModel } from "./api/models";

export default class LocalStorage {
    private static token_key = "looksee_token";
    private static user_key = "looksee_user";

    private static token?: string | null;
    private static user?: string | null;

    static setToken(key: string) {
        localStorage.setItem(this.token_key, key);
    }

    static setUser(user: string) {
        localStorage.setItem(this.user_key, JSON.stringify(user));
    }

    static getToken(): string | null {
        if (this.token == null) {
            this.token = localStorage.getItem(this.token_key) ?? null;
        }
        return this.token;
    }

    static getUser(): UserModel | null {
        if (this.user == null) {
            this.user = localStorage.getItem(this.user_key) ?? null;
        }
        if (this.user == null) return null;
        try {
            return JSON.parse(this.user);
        } catch (e) {
            return null;
        }
    }

    static clear() {
        this.token = null;
        this.user = null;
        localStorage.removeItem(this.token_key);
        localStorage.removeItem(this.user_key);
    }
}
