import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { PhoneNumberModel } from "../../services/api/models";
import {
    editPhoneNumberAPI,
    getPhoneNumberAPI,
} from "../../services/api/phone_number";
import { ToastNotification } from "../../services/toast_notification";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";

const EditPhoneNumber = (props: { match: { params: { id: number } } }) => {
    const { id } = props.match.params;

    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [errors, setErrors] = useState();
    const [editableData, setEditableData] =
        useState<Pick<PhoneNumberModel, "name" | "forwarding_number">>();

    const getData = async () => {
        setPageLoading(true);
        try {
            const response = await getPhoneNumberAPI({ id });
            setEditableData(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        updatePhoneNumber();
    };

    const updatePhoneNumber = async () => {
        setUpdating(true);
        try {
            if (editableData) {
                const response = await editPhoneNumberAPI(id, editableData);
                if (response.isSuccess()) {
                    history.goBack();
                    ToastNotification.success("Phone number is edited");
                }
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setUpdating(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Phone Numbers",
                                        path: "/phonenumbers",
                                    },
                                    {
                                        label: "Edit Phone Number",
                                        active: true,
                                    },
                                ]}
                                title={"Edit ".concat(
                                    editableData?.name ?? "Phone Number"
                                )}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <FormInput
                                        required
                                        type="text"
                                        label="Name"
                                        placeholder="Name"
                                        value={editableData?.name ?? ""}
                                        errors={errors}
                                        onChange={(e) =>
                                            editableData &&
                                            setEditableData({
                                                ...editableData,
                                                name: e.target.value,
                                            })
                                        }
                                        name="name"
                                    />
                                    <FormInput
                                        required
                                        type="text"
                                        label="Forwarding Number"
                                        placeholder="Forwarding number"
                                        value={
                                            editableData?.forwarding_number ??
                                            ""
                                        }
                                        errors={errors}
                                        onChange={(e) =>
                                            editableData &&
                                            setEditableData({
                                                ...editableData,
                                                forwarding_number:
                                                    e.target.value,
                                            })
                                        }
                                        name="forwarding_number"
                                    />

                                    <Row className="mt-3 ">
                                        <Col>
                                            <Button
                                                type="submit"
                                                className="float-end"
                                                onClick={handleSubmit}
                                                disabled={updating}
                                            >
                                                <i
                                                    className={
                                                        (updating
                                                            ? "mdi mdi-spin mdi-loading"
                                                            : "mdi mdi-check") +
                                                        " me-1"
                                                    }
                                                />{" "}
                                                Update
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default EditPhoneNumber;
