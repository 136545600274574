import { useCallback, useEffect, useState } from "react";
import {
    Button,
    ButtonGroup,
    Card,
    Col,
    Form,
    Modal,
    Row,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import ActionCellMenu from "../../components/ActionCellMenu";
import ConfirmationModal from "../../components/ConfirmationModal";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import DatePicker from "../../components/DatePicker";
import PageLoader from "../../components/PageLoader";
import PlaceHolderImage from "../../components/PlaceholderImage";
import Table from "../../components/Table";
import {
    deleteCheckinAPI,
    getExportedCheckInsAPI,
    getFilteredCheckInsAPI,
    markCheckinLatePickupAPI,
    markCheckinTardyAPI,
    removeCheckinLatePickupAPI,
    removeCheckinTardyAPI,
} from "../../services/api/check_ins";
import { getGroupsAPI } from "../../services/api/groups";
import {
    CheckinModel,
    GroupModel,
    ITableData,
} from "../../services/api/models";
import Request from "../../services/api/request";
import { ToastNotification } from "../../services/toast_notification";
import { getFormattedDate } from "../../utils/DateFormat";
import { handleException } from "../../utils/exceptions/handle_exception";
import { groupToCustomOption } from "../utils";

// components

const ChildCheckinList = (props: any) => {
    const { id } = props.match.params;

    const [checkIns, setCheckIns] = useState<CheckinModel[]>([]);
    const [pageLoading, setPageLoading] = useState(true);
    const history = useHistory();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmCheckin, setConfirmCheckin] = useState<CheckinModel | null>(
        null
    );

    let child = props.location.state.child;
    let group = props.location.state.group;
    let site = props.location.state.site;

    useEffect(() => {
        getCheckInList();
    }, []);

    async function getCheckInList() {
        setPageLoading(true);
        try {
            const response = await getFilteredCheckInsAPI({
                child_id: id,
            });
            setCheckIns(response.data);
        } catch (e: any) {
            handleException(history, e);
        } finally {
            setPageLoading(false);
        }
    }

    async function markAsTardy(checkin_id: number) {
        const response = await markCheckinTardyAPI(checkin_id);
        if (response.isSuccess()) {
            ToastNotification.success("Marked as tardy");
            let data = checkIns.map((checkin) => {
                if (checkin.id === checkin_id) {
                    return {
                        ...checkin,
                        tardy: true,
                    };
                } else {
                    return checkin;
                }
            });
            setCheckIns(data);
        }
    }

    async function removeAsTardy(checkin_id: number) {
        const response = await removeCheckinTardyAPI(checkin_id);
        if (response.isSuccess()) {
            ToastNotification.success("Marked as tardy");
            let data = checkIns.map((checkin) => {
                if (checkin.id === checkin_id) {
                    return {
                        ...checkin,
                        tardy: false,
                    };
                } else {
                    return checkin;
                }
            });
            setCheckIns(data);
        }
    }

    async function markAsLatePickup(checkin_id: number) {
        const response = await markCheckinLatePickupAPI(checkin_id);
        if (response.isSuccess()) {
            ToastNotification.success("Marked as On Time");
            let data = checkIns.map((checkin) => {
                if (checkin.id === checkin_id) {
                    return {
                        ...checkin,
                        late_pickup: true,
                    };
                } else {
                    return checkin;
                }
            });
            setCheckIns(data);
        }
    }

    async function removeAsLatePickup(checkin_id: number) {
        const response = await removeCheckinLatePickupAPI(checkin_id);
        if (response.isSuccess()) {
            ToastNotification.success("Marked as On Time");
            let data = checkIns.map((checkin) => {
                if (checkin.id === checkin_id) {
                    return {
                        ...checkin,
                        late_pickup: false,
                    };
                } else {
                    return checkin;
                }
            });
            setCheckIns(data);
        }
    }

    const onDeleteCheckin = (checkin: CheckinModel) => () => {
        setConfirmCheckin(checkin);
        setShowConfirmationModal(true);
        return false;
    };

    async function deleteCheckin() {
        if (confirmCheckin == null) return;
        const response = await deleteCheckinAPI(confirmCheckin.id);
        if (response.isSuccess()) {
            ToastNotification.success("Checkin deleted");
            let data = checkIns.filter((checkin) => {
                return checkin.id !== confirmCheckin.id;
            });
            setCheckIns(data);
        }
        setConfirmCheckin(null);
    }

    const onEditCheckIn = (id: number) => () => {
        history.push(`/check_ins/${id}`);
    };

    const [showExportModal, setShowExportModal] = useState(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();
    const [exporting, setExporting] = useState<boolean>();

    async function getExportedCheckIns() {
        setExporting(true);
        try {
            if (group?.id) {
                await getExportedCheckInsAPI({
                    child_id: id,
                    group_id: group?.id,
                    from_date: fromDate,
                    to_date: toDate,
                });
                setFromDate(undefined);
                setToDate(undefined);
                setShowExportModal(false);
                setValidated(false);
            }
        } catch (e: any) {
            handleException(history, e);
        } finally {
            setExporting(false);
        }
    }

    const handleExportSubmit = (event: any) => {
        event.preventDefault();
        setValidated(false);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        if (!fromDate || !toDate) {
            ToastNotification.error("Select group, from and to date");
            return;
        }
        setValidated(true);
        getExportedCheckIns();
    };

    const columns = [
        {
            Header: "Date",
            accessor: "checkins",
            sort: true,
            maxWidth: 90,
            Cell: (data: ITableData<CheckinModel, string>) => {
                let checkin = data.row.original.checkin;
                let absence_date = data.row.original.absence_date;
                return (
                    <span className="fw-semibold">
                        {getFormattedDate(new Date(absence_date ?? checkin), {
                            withTime: false,
                            timezone: site?.timezone,
                        })}
                    </span>
                );
            },
        },
        {
            Header: "Name",
            accessor: "child",
            sort: true,
            Cell: (data: any) => {
                data = data.row.original;

                function getAvatar() {
                    let avatar = data.child.avatar;

                    if (avatar != null) {
                        return PlaceHolderImage({
                            src: Request.getImageUrl(avatar),
                            rounded: true,
                            size: 36,
                        });
                    }
                    return PlaceHolderImage({
                        rounded: true,
                        size: 36,
                    });
                }

                return (
                    <div className="d-flex align-items-center">
                        {getAvatar()}
                        <span className="ms-2 cursor-pointer">
                            {data.child.first_name + " " + data.child.last_name}
                        </span>
                    </div>
                );
            },
        },
        // {
        //     Header: "Group",
        //     accessor: "group",
        //     sort: true,
        //     Cell: (data: any) => {
        //
        //         return <p>{data.cell.value.name}</p>;
        //     },
        // },

        {
            Header: "Status",
            accessor: "status",
            sort: true,
            Cell: (data: ITableData<CheckinModel, string>) => {
                let absence_date = data.row.original.absence_date;
                return absence_date != null ? (
                    <span className="fw-bold text-danger ">Absent</span>
                ) : (
                    <span className="fw-semibold">Present</span>
                );
            },
        },
        {
            Header: "Checkin",
            accessor: "checkin",
            sort: true,
            Cell: (data: ITableData<CheckinModel, string>) => {
                let absence_date = data.row.original.absence_date;
                if (absence_date != null) {
                    return <></>;
                }
                return (
                    <span>
                        {" "}
                        <div
                            style={{ width: `90px` }}
                            className="d-inline-block"
                        >
                            {getFormattedDate(new Date(data.cell.value), {
                                withDate: false,
                                timezone: site?.timezone,
                            })}
                        </div>
                        <ButtonGroup className="ms-2">
                            <Button
                                variant={
                                    !data.row.original.tardy
                                        ? "success"
                                        : "light"
                                }
                                className="py-1 px-2"
                                onClick={() =>
                                    removeAsTardy(data.row.original.id)
                                }
                            >
                                On Time
                            </Button>
                            <Button
                                variant={
                                    data.row.original.tardy
                                        ? "warning"
                                        : "light"
                                }
                                className="py-1 px-2"
                                onClick={() =>
                                    markAsTardy(data.row.original.id)
                                }
                            >
                                Tardy
                            </Button>
                        </ButtonGroup>
                    </span>
                );
            },
        },
        {
            Header: "Checkout",
            accessor: "checkout",
            sort: true,
            Cell: (data: ITableData<CheckinModel, string>) => {
                let absence_date = data.row.original.absence_date;
                if (absence_date != null) {
                    return <></>;
                }
                if (data.cell.value === null) {
                    return <span className="text-info">Not checked out</span>;
                }
                return (
                    <span>
                        <div
                            style={{ width: `90px` }}
                            className="d-inline-block"
                        >
                            {getFormattedDate(new Date(data.cell.value), {
                                withDate: false,
                                timezone: site?.timezone,
                            })}
                        </div>
                        <ButtonGroup className="ms-2">
                            <Button
                                variant={
                                    !data.row.original.late_pickup
                                        ? "success"
                                        : "light"
                                }
                                className="py-1 px-2"
                                onClick={() =>
                                    removeAsLatePickup(data.row.original.id)
                                }
                            >
                                On Time
                            </Button>
                            <Button
                                variant={
                                    data.row.original.late_pickup
                                        ? "warning"
                                        : "light"
                                }
                                className="py-1 px-2"
                                onClick={() =>
                                    markAsLatePickup(data.row.original.id)
                                }
                            >
                                Late pickup
                            </Button>
                        </ButtonGroup>
                    </span>
                );
            },
        },

        {
            Header: "Actions",
            accessor: "id1",
            maxWidth: 100,
            Cell: (data: ITableData<CheckinModel, string>) => {
                const id = data.row.original.id;
                return (
                    <ActionCellMenu
                        id={`checkins-${id}-menu`}
                        items={[
                            {
                                id: `edit-${id}`,
                                label: "Edit",
                                icon: (
                                    <i className="mdi mdi-square-edit-outline"></i>
                                ),
                                onClick: onEditCheckIn(id),
                            },
                            {
                                id: `delete-${id}`,
                                label: "Delete",
                                icon: (
                                    <i className="mdi mdi-delete-outline"></i>
                                ),
                                onClick: onDeleteCheckin(data.row.original),
                            },
                        ]}
                    />
                );
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: checkIns.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <div className="page-title-box">
                                <h4 className="page-title">
                                    Child Attendance Report
                                </h4>
                            </div>
                        </Col>
                    </Row>

                    {checkIns.length > 0 && (
                        <div>
                            <Row className="mt-1">
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <div className="d-flex justify-content-end mb-2">
                                                <div>
                                                    <u
                                                        className="text-primary cursor-pointer"
                                                        onClick={() =>
                                                            setShowExportModal(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Export
                                                    </u>
                                                </div>
                                            </div>
                                            <Table
                                                id="child-checkins"
                                                columns={columns}
                                                data={checkIns}
                                                pageSize={10}
                                                sizePerPageList={
                                                    sizePerPageList
                                                }
                                                sortBy={[
                                                    {
                                                        id: "date",
                                                        desc: false,
                                                    },
                                                ]}
                                                isSortable={true}
                                                pagination={true}
                                                isSearchable={true}
                                                actions={
                                                    <div>
                                                        <Link
                                                            to={{
                                                                pathname:
                                                                    "/check_ins/create",
                                                                state: {
                                                                    child: child,
                                                                    group: group,
                                                                },
                                                            }}
                                                            className="btn btn-primary"
                                                        >
                                                            <i className="mdi mdi-plus-circle  me-1"></i>
                                                            New Checkin
                                                        </Link>
                                                        <Link
                                                            to={{
                                                                pathname:
                                                                    "/check_ins/add_absence",
                                                                state: {
                                                                    child: child,
                                                                    group: group,
                                                                },
                                                            }}
                                                            className="btn btn-outline-danger ms-3"
                                                        >
                                                            <i className="mdi mdi-minus-box-outline  me-1"></i>
                                                            Add Absence
                                                        </Link>
                                                    </div>
                                                }
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    )}

                    {checkIns.length === 0 && (
                        <div>
                            <div className={"text-end"}>
                                <Link
                                    to={{
                                        pathname: "/check_ins/create",
                                        state: {
                                            child: child,
                                            group: group,
                                        },
                                    }}
                                    className="btn btn-primary"
                                >
                                    <i className="mdi mdi-plus-circle  me-1"></i>
                                    New Checkin
                                </Link>
                                <Link
                                    to={{
                                        pathname: "/check_ins/add_absence",
                                        state: {
                                            child: child,
                                            group: group,
                                        },
                                    }}
                                    className="btn btn-outline-danger ms-3"
                                >
                                    <i className="mdi mdi-minus-box-outline  me-1"></i>
                                    Add Absence
                                </Link>
                            </div>
                            <h4 className="text-center mt-5">
                                No checkin records
                            </h4>
                        </div>
                    )}

                    <ConfirmationModal
                        show={showConfirmationModal}
                        onCancel={() => setShowConfirmationModal(false)}
                        title="Are you sure to delete checkin?"
                        // body={<div>
                        //     <p>This contact is associated with the multiple children</p>
                        //     <ul>{attachChildren != null && attachChildren.map(function(child, index) {
                        //         return <li> {child.first_name + " " + child.last_name} </li>;
                        //     })
                        //     }
                        //     </ul>
                        //     {/*<p>Phone: {phoneNumber}</p>*/}
                        //     <p>Would you like to edit contact for all children or just this one? </p>
                        //
                        // </div>}
                        actions={
                            <>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        deleteCheckin().then();
                                        setShowConfirmationModal(false);
                                    }}
                                >
                                    Delete
                                </Button>
                                <Button
                                    variant="light"
                                    onClick={() => {
                                        setShowConfirmationModal(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                </div>
            )}

            <Modal
                show={showExportModal}
                onHide={() => setShowExportModal(false)}
            >
                <Modal.Header closeButton style={{ padding: "8px 16px" }}>
                    <Modal.Title>Export</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleExportSubmit}
                    >
                        <Form.Label className="">From Date</Form.Label>
                        <DatePicker
                            showAddon={true}
                            tI={1}
                            dateFormat="MMMM d, yyyy"
                            timeCaption="time"
                            value={fromDate}
                            onChange={setFromDate}
                        />

                        <Form.Label className="mt-2">To Date</Form.Label>
                        <DatePicker
                            showAddon={true}
                            tI={1}
                            dateFormat="MMMM d, yyyy"
                            timeCaption="time"
                            value={toDate}
                            onChange={setToDate}
                        />
                        <Button
                            type="submit"
                            className="float-end mt-2"
                            disabled={exporting}
                        >
                            <i
                                className={
                                    (exporting
                                        ? "mdi mdi-spin mdi-loading"
                                        : "mdi mdi-check") + " me-1"
                                }
                            />
                            Export
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ChildCheckinList;
