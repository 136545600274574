import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table from "../../components/Table";
import { Link, useHistory } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import { handleException } from "../../utils/exceptions/handle_exception";
import { SiteModel } from "../../services/api/models";
import { getSitesAPI } from "../../services/api/sites";
import PageTitle from "../../components/PageTitle";
import { Permission } from "../../services/permission";

// components

const SiteList = () => {
    const [sites, setSites] = useState<SiteModel[]>([]);
    const [pageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getSiteList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getSiteList() {
        setPageLoading(true);
        try {
            const response = await getSitesAPI();
            setSites(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    const columns = [
        {
            Header: "ID",
            accessor: "id",
            sort: true,
            maxWidth: 60,
        },
        {
            Header: "Name",
            accessor: "name",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value}</span>;
            },
        },
        {
            Header: "Preamble",
            accessor: "preamble",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value}</span>;
            },
        },
        {
            Header: "Address",
            accessor: "address",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value}</span>;
            },
        },
        {
            Header: "City",
            accessor: "city",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value}</span>;
            },
        },
        {
            Header: "State",
            accessor: "state",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value}</span>;
            },
        },
        {
            Header: "Timezone",
            accessor: "timezone",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value}</span>;
            },
        },
        {
            Header: "Edit",
            accessor: "id1",
            maxWidth: 60,
            Cell: (data: any) => {
                let id = data.row.allCells[0].value;
                return (
                    Permission.canEditSite() && (
                        <Link to={`/sites/${id}`}>
                            <i className="mdi mdi-pencil-outline"></i>
                        </Link>
                    )
                );
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: sites.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Sites",
                                        active: true,
                                    },
                                ]}
                                title="Sites"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Table
                                        id="sites"
                                        columns={columns}
                                        data={sites}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={true}
                                        tableClass={"table-striped"}
                                        actions={
                                            Permission.canCreateSite() && (
                                                <Link
                                                    to="/sites/create"
                                                    className="btn btn-primary"
                                                >
                                                    <i className="mdi mdi-plus-circle  me-1"></i>
                                                    Add Site
                                                </Link>
                                            )
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default SiteList;
