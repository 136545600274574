import { useEffect, useState } from "react";
import { Button, Card, Col, Form, FormCheck, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import DatePicker from "../../components/DatePicker";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { SiteModel } from "../../services/api/models";
import { createSessionAPI } from "../../services/api/sessions";
import { getSitesAPI } from "../../services/api/sites";
import { ToastNotification } from "../../services/toast_notification";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";
import { siteToCustomSelectOption } from "../users/utils";

const CreateSession = () => {
    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    const [errors, setErrors] = useState();

    const [sites, setSites] = useState<SiteModel[]>([]);

    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [selectedSite, setSelectedSite] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [draft, setDraft] = useState(false);

    useEffect(() => {
        getSites();
    }, []);

    const getSites = async () => {
        try {
            const response = await getSitesAPI();
            const sites = response.data;
            setSites(sites);
            if (sites != null && sites.length === 1) {
                setSelectedSite(sites.map(siteToCustomSelectOption));
            }
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (selectedSite.length !== 1) {
            ToastNotification.error("Please select site");
            return;
        }

        createSession();
    };

    const createSession = async () => {
        setCreating(true);
        try {
            const response = await createSessionAPI(
                name,
                startDate,
                endDate,
                draft,
                selectedSite[0].id
            );
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Session is created");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setCreating(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Sessions",
                                        path: "/sessions",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Create ".concat(
                                    name.length > 0 ? name : "Session"
                                )}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <FormInput
                                        required
                                        type="text"
                                        label="Session Name"
                                        placeholder="Session Name"
                                        value={name}
                                        errors={errors}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        name="name"
                                    />

                                    <Form.Label>Start Date</Form.Label>

                                    <DatePicker
                                        showAddon={true}
                                        errors={errors}
                                        name="start_date"
                                        dateFormat="MMMM d, yyyy"
                                        value={startDate}
                                        onChange={(date) => {
                                            setStartDate(date);
                                        }}
                                    />

                                    <Form.Label className="mt-3">
                                        End Date
                                    </Form.Label>

                                    <DatePicker
                                        showAddon={true}
                                        dateFormat="MMMM d, yyyy"
                                        value={endDate}
                                        errors={errors}
                                        name="end_date"
                                        onChange={(date) => {
                                            setEndDate(date);
                                        }}
                                    />

                                    <Form.Label className="mt-3">
                                        Site
                                    </Form.Label>

                                    <CustomSelect
                                        id="select2"
                                        multiple={false}
                                        onChange={(e) => setSelectedSite(e)}
                                        options={sites.map(
                                            siteToCustomSelectOption
                                        )}
                                        placeholder="Select a site"
                                        selected={selectedSite}
                                    />

                                    <Row className="mt-3 ">
                                        <Col>
                                            <FormCheck
                                                className={"mt-2"}
                                                type="checkbox"
                                                id="default-checkbox2"
                                                label="Draft"
                                                checked={draft}
                                                onChange={(e) =>
                                                    setDraft(e.target.checked)
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                type="submit"
                                                className="float-end"
                                                onClick={handleSubmit}
                                                disabled={creating}
                                            >
                                                <i
                                                    className={
                                                        (creating
                                                            ? "mdi mdi-spin mdi-loading"
                                                            : "mdi mdi-check") +
                                                        " me-1"
                                                    }
                                                />{" "}
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default CreateSession;
