import { IdentifierModel } from "../services/api/models";

const groupByFields = (array: Array<any>, f: any): Array<any> => {
    /*
    params description :
        f : function which returnf the array of fields
        e.g. :  (item) => {
            return [itemField1, itemField2];
        }
        array : array of data to group e.g. : [{...}, {...}]
    */
    var groups: { [key: string]: any[] } = {};
    array.forEach((o) => {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });

    return Object.keys(groups).map((group) => {
        return groups[group];
    });
};

/**
 * split array into chunks
 * @param array - array to split
 * @param chunkSize - chunk size
 * @returns
 */
const splitArray = (array: Array<any>, chunkSize: number) => {
    const chunks = Array(Math.ceil(array.length / chunkSize))
        .fill(1)
        .map((_, index) => index * chunkSize)
        .map((begin) => array.slice(begin, begin + chunkSize));
    return chunks;
};

export function pushUniqueToArray<T extends IdentifierModel>(
    array: T[],
    element: T
): T[] {
    for (const t of array) {
        if (t.id === element.id) {
            return array;
        }
    }
    array.push(element);
    return array;
}

export function pushAllUniqueToArray<T extends IdentifierModel>(
    array: T[],
    elements: T[]
): T[] {
    elements.forEach(function (element) {
        array = pushUniqueToArray(array, element);
    });
    return array;
}

function groupBy<T>(arr: T[], fn: (item: T) => any) {
    return arr.reduce<Record<string, T[]>>((prev, curr) => {
        const groupKey = fn(curr);
        const group = prev[groupKey] || [];
        group.push(curr);
        return { ...prev, [groupKey]: group };
    }, {});
}

export { groupByFields, splitArray, groupBy };
