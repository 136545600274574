import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import FileUploader, { FileType } from "../../components/FileUploader";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { getChildrenAPI } from "../../services/api/children";
import { ToastNotification } from "../../services/toast_notification";
import { handleException } from "../../utils/exceptions/handle_exception";
import { FormInput } from "../../components";
import ConfirmationModal from "../../components/ConfirmationModal";
import { createChildParentAPI } from "../../services/api/child_parents";
import { getFamilyRelationsAPI } from "../../services/api/family_relations";
import {
    ChildModel,
    FamilyRelationModel,
    ParentModel,
    SessionModel,
} from "../../services/api/models";
import { checkParentNumberAPI } from "../../services/api/parents";
import { getSessionsAPI } from "../../services/api/sessions";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import {
    childToCustomOption,
    familyRelaionToCustomOption,
    sessionToCustomOption,
} from "../utils";

const CreateChildParent = (props: any) => {
    const history = useHistory();

    const state = props.location.state;

    const [pageLoading, setPageLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    const [errors, setErrors] = useState();

    const [children, setChildren] = useState<ChildModel[]>([]);
    const [sessions, setSessions] = useState<SessionModel[]>([]);
    const [familyRelations, setFamilyRelations] = useState<
        FamilyRelationModel[]
    >([]);

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedChildren, setSelectedChildren] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [selectedSession, setSelectedSession] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [selectedFamilyRelation, setSelectedFamilyRelation] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [attachParent, setAttachParent] = useState<ParentModel>();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [avatar, setAvatar] = useState<FileType | null>(null);
    const [primary, setPrimary] = useState(false);
    const [checkInNotification, setCheckInNotification] = useState(false);
    const [checkOutNotification, setCheckOutNotification] = useState(false);
    const [groupNotification, setGroupNotification] = useState(false);
    const [feedNotification, setFeedNotification] = useState(false);

    useEffect(() => {
        getData();
        if (state != null) {
            setSelectedChildren(
                state.child != null ? [childToCustomOption(state.child)] : []
            );
            setSelectedSession(
                state.session != null
                    ? [sessionToCustomOption(state.session)]
                    : []
            );
        }
    }, []);

    const getData = async () => {
        try {
            const childResponse = await getChildrenAPI();
            setChildren(childResponse.data);
            const sessionResponse = await getSessionsAPI();
            setSessions(sessionResponse.data);
            const familyRelationResponse = await getFamilyRelationsAPI();
            setFamilyRelations(familyRelationResponse.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    };

    const handleFileUpload = (files: FileType[]) => {
        if (files.length > 0) setAvatar(files[files.length - 1]);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            checkForMobileNumber();
        }
    };

    const checkForMobileNumber = async () => {
        if (selectedSession.length === 0) {
            ToastNotification.error("Please select session");
            return;
        }
        const response = await checkParentNumberAPI(
            phoneNumber,
            selectedSession[0].id
        );
        if (response.isSuccess()) {
            const parents = response.data;
            if (parents.length > 0) {
                setAttachParent(parents[0]);
                setShowConfirmationModal(false);
                setShowConfirmationModal(true);
            } else {
                await createChildParent();
            }
        } else {
            await createChildParent();
        }
    };

    const handlePhoneInput = (e: any) => {
        function formatPhoneNumber(value: string) {
            // if input value is falsy eg if the user deletes the input, then just return
            if (!value) return value;

            // clean the input for any non-digit values.
            const phoneNumber = value.replace(/[^\d]/g, "");

            // phoneNumberLength is used to know when to apply our formatting for the phone number
            const phoneNumberLength = phoneNumber.length;

            // we need to return the value with no formatting if its less then four digits
            // this is to avoid weird behavior that occurs if you  format the area code to early
            if (phoneNumberLength < 4) return phoneNumber;

            // if phoneNumberLength is greater than 4 and less the 7 we start to return
            // the formatted number
            if (phoneNumberLength < 7) {
                return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
            }

            // finally, if the phoneNumberLength is greater then seven, we add the last
            // bit of formatting and return it.
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
                3,
                6
            )}-${phoneNumber.slice(6, 10)}`;
        }

        // this is where we'll call the phoneNumberFormatter function
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setPhoneNumber(formattedPhoneNumber);
    };

    const createChildParent = async () => {
        try {
            const familyRelationId =
                selectedFamilyRelation.length !== 0
                    ? selectedFamilyRelation[0].id
                    : undefined;

            if (selectedChildren.length === 0) {
                ToastNotification.error("Please select child");
                return;
            }
            if (selectedSession.length === 0) {
                ToastNotification.error("Please select site");
                return;
            }
            setCreating(true);

            const response = await createChildParentAPI({
                parent_id: -1,
                parent: {
                    id: -1,
                    first_name: firstName,
                    middle_name: middleName,
                    last_name: lastName,
                    phone_number: phoneNumber,
                    avatar: avatar?.preview,
                    session_id: selectedSession[0].id,
                },

                is_primary: primary,
                receive_checkin_notification: checkInNotification,
                receive_checkout_notification: checkOutNotification,
                receive_group_notification: groupNotification,
                family_relation_id: familyRelationId,
                receive_feed_notification: feedNotification,
                child_id: selectedChildren[0].id,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Child Parent is created");
            }
        } catch (e: any) {
            if (e.isFormDataError()) {
                console.log(e);
                const errors = e.getErrors();
                setErrors(errors);
            } else {
                handleException(history, e);
            }
        }
        setCreating(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Parent",
                                        path: "/child_parents",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Create ".concat(
                                    firstName.length > 0 && lastName.length > 0
                                        ? firstName
                                              .concat(" ")
                                              .concat(middleName)
                                              .concat(" ")
                                              .concat(lastName)
                                              .concat("'s Profile")
                                        : " Parent's Profile"
                                )}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col lg={4}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="First name"
                                                placeholder="First name"
                                                value={firstName}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                                name="first_name"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="Middle Name"
                                                placeholder="Middle Name"
                                                value={middleName}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setMiddleName(
                                                        e.target.value
                                                    )
                                                }
                                                name="middle_name"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="Last Name"
                                                placeholder="LastName"
                                                value={lastName}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setLastName(e.target.value)
                                                }
                                                name="last_name"
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <FormInput
                                                required
                                                label="Phone Number"
                                                placeholder="Phone Number"
                                                value={phoneNumber}
                                                errors={errors}
                                                onChange={handlePhoneInput}
                                                name="phone_number"
                                            />

                                            <Form.Label>Children</Form.Label>

                                            <CustomSelect
                                                id="select2"
                                                onChange={(e) =>
                                                    setSelectedChildren(e)
                                                }
                                                options={children.map(
                                                    childToCustomOption
                                                )}
                                                placeholder="Select Children"
                                                selected={selectedChildren}
                                            />

                                            <Form.Label className="mt-3">
                                                Session
                                            </Form.Label>

                                            <CustomSelect
                                                id="select2"
                                                onChange={(e) =>
                                                    setSelectedSession(e)
                                                }
                                                options={sessions.map(
                                                    sessionToCustomOption
                                                )}
                                                placeholder="Select a Session"
                                                selected={selectedSession}
                                            />

                                            <Form.Label className="mt-3">
                                                Family Relation
                                            </Form.Label>

                                            <CustomSelect
                                                id="family-relation-select"
                                                multiple={false}
                                                onChange={(e) =>
                                                    setSelectedFamilyRelation(e)
                                                }
                                                options={familyRelations.map(
                                                    familyRelaionToCustomOption
                                                )}
                                                placeholder="Select a family relation"
                                                selected={
                                                    selectedFamilyRelation
                                                }
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Label>
                                                Profile Image
                                            </Form.Label>
                                            <FileUploader
                                                onFileUpload={handleFileUpload}
                                            />

                                            <Form.Check
                                                type="checkbox"
                                                id="primary-check"
                                                label="Primary"
                                                className="my-2"
                                                checked={primary}
                                                onChange={(e) =>
                                                    setPrimary(e.target.checked)
                                                }
                                            />

                                            <Form.Label>
                                                Receive Notification Settings
                                            </Form.Label>

                                            <Form.Check
                                                type="checkbox"
                                                id="check-in-check"
                                                label="Check In "
                                                checked={checkInNotification}
                                                onChange={(e) =>
                                                    setCheckInNotification(
                                                        e.target.checked
                                                    )
                                                }
                                            />

                                            <Form.Check
                                                type="checkbox"
                                                id="check-out-check"
                                                label="Check Out"
                                                className="mt-2"
                                                checked={checkOutNotification}
                                                onChange={(e) =>
                                                    setCheckOutNotification(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="group-check"
                                                label="Group Message"
                                                className="mt-2"
                                                checked={groupNotification}
                                                onChange={(e) =>
                                                    setGroupNotification(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="feed-check"
                                                label="Feed"
                                                className="mt-2"
                                                checked={feedNotification}
                                                onChange={(e) =>
                                                    setFeedNotification(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>

                                    <Button
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="float-end"
                                    >
                                        <i
                                            className={
                                                (creating
                                                    ? "mdi mdi-spin mdi-loading"
                                                    : "mdi mdi-check") + " me-1"
                                            }
                                        />{" "}
                                        Create
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <ConfirmationModal
                        show={showConfirmationModal}
                        title="Existing phone number found"
                        body={
                            <div>
                                <p>
                                    This phone number already exists for the
                                    following contact:
                                </p>
                                <p>
                                    Name: {attachParent?.first_name}{" "}
                                    {attachParent?.last_name}
                                </p>
                                <p>Phone: {phoneNumber}</p>
                                <p>Would you like to add this contact? </p>
                            </div>
                        }
                        onConfirm={() => {
                            setShowConfirmationModal(false);
                            createChildParent();
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default CreateChildParent;
