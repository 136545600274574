import Request from "./request";
import Response from "./response";
import {parseException} from "../../utils/exceptions/parse_exception";
import {ChildModel} from "./models";

export const getChildrenAPI = async () => {
    try {
        const response = await Request.getAuth("children/");
        return new Response<ChildModel[]>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const createChildAPI = async (child: Omit<ChildModel, 'archive' | 'id'>) => {
    try {
        let body = {
            first_name: child.first_name,
            middle_name: child.middle_name,
            last_name: child.last_name,
            medical_condition: child.medical_condition,
            approved_pickup: child.approved_pickup,
            notes: child.notes,
            pronouns: child.pronouns,
            session_id : child.session_id
        };

        if (child.avatar) {
            body = {
                ...body, ...{avatar: await Request.getBase64FromUrl(child.avatar)},
            };
        }

        if (child.groups && child.groups.length > 0) {
            const groups = (child.groups ?? []).map((group) => group.id);
            body = {
                ...body, ...{
                    group_ids: groups,
                },
            };
        }

        const response = await Request.postAuth("children/", body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const editChildAPI = async (child: ChildModel) => {
    try {
        let body = {
            first_name: child.first_name,
            middle_name: child.middle_name,
            last_name: child.last_name,
            medical_condition: child.medical_condition,
            archive: child.archive,
            approved_pickup: child.approved_pickup,
            notes: child.notes,
            pronouns: child.pronouns
        };

        if (child.avatar) {
            body = {
                ...body, ...{avatar: await Request.getBase64FromUrl(child.avatar)},
            };
        }

        if (child.groups && child.groups.length > 0) {
            const groups = (child.groups ?? []).map((group) => group.id);
            body = {
                ...body, ...{
                    group_ids: groups,
                },
            };
        }

        const response = await Request.patchAuth(`children/${child.id}/`, body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const getChildAPI = async (id: number) => {
    try {
        const response = await Request.getAuth(`children/${id}`);
        return new Response<ChildModel>(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};


