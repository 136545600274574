export const ResponseCode = {
    error: 400,
    unauthorized: 401,
    not_found: 404,
    forbidden: 403,
    unprocessable_entity: 422
};

export default class Response<T = any> {
    data: T;
    statusCode: number;

    constructor(data: string, statusCode: number) {
        this.data = JSON.parse(JSON.stringify(data));
        this.statusCode = statusCode;
    }

    isSuccess = () => {
        return this.statusCode >= 200 && this.statusCode < 300;
    };
}
