import { CustomSelectOption } from "../../components/CustomSelect";
import { roleToCustomSelectOption } from "./utils";

export const UserRoles: Record<string, string> = {
    site_admin: "Site Admin",
    site_lead: "Site Lead",
    staffer: "Staffer",
    organization_admin: "Organization Admin",
};

export const roleOptions: CustomSelectOption<string>[] = Object.entries(
    UserRoles
).map(([name, label]) => roleToCustomSelectOption({ name, label }));
