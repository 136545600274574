import { useEffect, useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import { PhoneNumberModel } from "../../services/api/models";
import { getPhoneNumbersAPI } from "../../services/api/phone_number";
import { Permission } from "../../services/permission";
import { handleException } from "../../utils/exceptions/handle_exception";

const PhoneNumbers = () => {
    const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberModel[]>([]);
    const [pageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        async function getData() {
            setPageLoading(true);
            try {
                const response = await getPhoneNumbersAPI();
                setPhoneNumbers(response.data);
            } catch (e: any) {
                handleException(history, e);
            }
            setPageLoading(false);
        }
        getData();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "Site",
                accessor: "site_name",
                sort: true,
                Cell: (data: any) => {
                    return <span>{data.cell.value}</span>;
                },
            },
            {
                Header: "Name",
                accessor: "name",
                sort: true,
                Cell: (data: any) => {
                    return <span>{data.cell.value}</span>;
                },
            },
            {
                Header: "Phone Number",
                accessor: "twilio_number",
                sort: true,
                Cell: (data: any) => {
                    return <span>{data.cell.value}</span>;
                },
            },
            {
                Header: "Forwarding Phone Number",
                accessor: "forwarding_number",
                sort: true,
                Cell: (data: any) => {
                    return <span>{data.cell.value}</span>;
                },
            },
            {
                Header: "Edit",
                accessor: "id1",
                maxWidth: 60,
                Cell: (data: any) => {
                    const id = data.row.original.id;
                    return (
                        Permission.canEditPhoneNumber() && (
                            <Link to={`/phonenumbers/${id}`}>
                                <i className="mdi mdi-pencil-outline"></i>
                            </Link>
                        )
                    );
                },
            },
        ],
        []
    );

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: phoneNumbers.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Phone Numbers",
                                        active: true,
                                    },
                                ]}
                                title="Phone Numbers"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Table
                                        id="phoneNumbers"
                                        columns={columns}
                                        data={phoneNumbers}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={true}
                                        tableClass={"table-striped"}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default PhoneNumbers;
