import { useEffect, useState } from "react";
import { Button, Card, Col, Form, FormCheck, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { getClassesAPI } from "../../services/api/classes";
import {
    ChildModel,
    SessionClassModel,
    UserModel,
} from "../../services/api/models";
import { getUsersAPI } from "../../services/api/user";
import { ToastNotification } from "../../services/toast_notification";
import { handleException } from "../../utils/exceptions/handle_exception";

import { FormInput } from "../../components";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import { editGroupAPI, getGroupAPI } from "../../services/api/groups";
import { BaseException } from "../../utils/exceptions/base_exception";
import {
    childToCustomOption,
    sessionClassToCustomOption,
    userToCustomOption,
} from "../utils";

const EditGroup = (props: any) => {
    const { id } = props.match.params;

    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [errors, setErrors] = useState();

    const [classes, setClasses] = useState<SessionClassModel[]>([]);
    const [selectedClass, setSelectedClass] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [users, setUser] = useState<UserModel[]>([]);
    const [selectedUser, setSelectedUser] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [children, setChildren] = useState<ChildModel[]>([]);
    const [selectedChildren, setSelectedChildren] = useState<
        CustomSelectOption<number>[]
    >([]);

    // const [children, setChildren] = useState<ChildModel[]>([]);
    // const [selectedChild, setSelectedChild] = useState<ChildModel[]>([]);
    const [archive, setArchive] = useState(false);
    const [draft, setDraft] = useState(false);

    const [name, setName] = useState("");

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (selectedClass.length > 0) {
            let selectedClassWithChildren: any = {};

            const cls = classes.find(({ id }) => id === selectedClass?.[0]?.id);
            if (cls?.groups?.length) {
                selectedClassWithChildren = cls;
            } else {
                selectedClassWithChildren =
                    classes.find((data) => data.id === cls?.id) || {};
            }

            const listOfChildren =
                selectedClassWithChildren?.groups
                    ?.map((data: any) =>
                        data?.children && data?.children?.length > 0
                            ? data?.children
                            : undefined
                    )
                    .filter((data: any) => data !== undefined) ?? [];

            setChildren(listOfChildren.flat());
        } else {
            setSelectedChildren([]);
        }
    }, [selectedClass]);

    const getData = async () => {
        setPageLoading(true);
        try {
            const groupResponse = await getGroupAPI(id);
            const group = groupResponse.data;

            const classResponse = await getClassesAPI(["active", "draft"]);
            setClasses(classResponse.data);
            const userResponse = await getUsersAPI();
            setUser(userResponse.data);

            // const childrenResponse = await getChildrenAPI();
            // setChildren(childrenResponse.data);
            setName(group.name);
            setSelectedUser((group.users ?? []).map(userToCustomOption));
            setSelectedChildren(
                (group?.children || []).map(childToCustomOption)
            );
            setSelectedClass(
                group.session_class != null
                    ? [sessionClassToCustomOption(group.session_class)]
                    : []
            );
            setArchive(group.archive);
            setDraft(group.draft);
        } catch (e) {
            ToastNotification.unknownError();
            handleException(history, e);
        }
        setPageLoading(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        editGroup();
    };

    const editGroup = async () => {
        setEditing(true);
        try {
            if (selectedClass.length === 0) {
                ToastNotification.error("Please select class");
                return;
            }

            const response = await editGroupAPI({
                id: id,
                name: name,
                session_class_id: selectedClass[0].id,
                users: users.filter(({ id }) =>
                    selectedUser.find((u) => u.id === id)
                ),
                children: children.filter(({ id }) =>
                    selectedChildren.find((u) => u.id === id)
                ),
                archive: archive,
                draft: draft,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Group is edited");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setEditing(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Groups",
                                        path: "/groups",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Edit "
                                    .concat(
                                        name && name.length > 0
                                            ? name.concat("'s ")
                                            : ""
                                    )
                                    .concat("Group")}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <FormInput
                                        required
                                        type="text"
                                        label="Group Name"
                                        placeholder="Name"
                                        value={name}
                                        errors={errors}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        name="name"
                                    />

                                    <Form.Label>Select Class</Form.Label>

                                    <CustomSelect
                                        id="class-select"
                                        multiple={false}
                                        emptyLabel={"There is no class"}
                                        onChange={(e) => {
                                            setSelectedClass(e);
                                        }}
                                        options={classes.map(
                                            sessionClassToCustomOption
                                        )}
                                        placeholder="Select a class"
                                        selected={selectedClass}
                                    />

                                    <Form.Label className="mt-3">
                                        Select Users
                                    </Form.Label>

                                    <CustomSelect
                                        id="user-select"
                                        multiple={true}
                                        emptyLabel={"There is no user"}
                                        onChange={(e) => {
                                            setSelectedUser(e);
                                        }}
                                        options={users.map(userToCustomOption)}
                                        placeholder="Select users"
                                        selected={selectedUser}
                                    />

                                    <Form.Label className="mt-3">
                                        Select Children
                                    </Form.Label>

                                    <CustomSelect
                                        id="user-select"
                                        multiple={true}
                                        emptyLabel={"There is no children"}
                                        onChange={(e) => {
                                            setSelectedChildren(e);
                                        }}
                                        options={children.map(
                                            childToCustomOption
                                        )}
                                        placeholder="Select Children"
                                        selected={selectedChildren}
                                    />

                                    <Row className="mt-3 ">
                                        <Col>
                                            <FormCheck
                                                type="checkbox"
                                                id="default-checkbox1"
                                                label="Archived"
                                                checked={archive}
                                                onChange={(e) =>
                                                    setArchive(e.target.checked)
                                                }
                                            />
                                            <FormCheck
                                                className={"mt-2"}
                                                type="checkbox"
                                                id="default-checkbox2"
                                                label="Draft"
                                                checked={draft}
                                                onChange={(e) =>
                                                    setDraft(e.target.checked)
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                type="submit"
                                                className="float-end"
                                                onClick={handleSubmit}
                                                disabled={editing}
                                            >
                                                <i
                                                    className={
                                                        (editing
                                                            ? "mdi mdi-spin mdi-loading"
                                                            : "mdi mdi-check") +
                                                        " me-1"
                                                    }
                                                />{" "}
                                                Update
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default EditGroup;
