import React from "react";
import { Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";
import ChildrenList from "../pages/children";
import CreateChild from "../pages/children/create";
import EditChild from "../pages/children/edit";
import CreateSession from "../pages/sessions/create";
import SessionList from "../pages/sessions";
import EditSession from "../pages/sessions/edit";
import CreateClass from "../pages/classes/create";
import ClassList from "../pages/classes";
import EditClass from "../pages/classes/edit";
import CreateChildParent from "../pages/child_parents/create";
import ChildParentsList from "../pages/child_parents";
import EditChildParent from "../pages/child_parents/edit";

import CheckInList from "../pages/check_ins";
import CreateCheckIn from "../pages/check_ins/create";
import EditCheckIn from "../pages/check_ins/edit";
import AddAbsence from "../pages/check_ins/add_absence";

import ClassChildren from "../pages/class_children";
import AttendanceList from "../pages/attendances";
import ChildCheckinList from "../pages/child_checkins";
import SiteList from "../pages/sites";
import CreateSite from "../pages/sites/create";
import EditSite from "../pages/sites/edit";
import GroupList from "../pages/groups";
import CreateGroup from "../pages/groups/create";
import EditGroup from "../pages/groups/edit";
import UserList from "../pages/users";
import CreateUser from "../pages/users/create";
import EditUser from "../pages/users/edit";
import Error403 from "../pages/errors/error403";
import Error404 from "../pages/errors/error404";
import ImportClassChildren from "../pages/class_children/import";
import GuestFeedList from "../pages/guest/guest_feeds";
import GroupMessages from "../pages/chats/group_messages";
import CreateGroupMessage from "../pages/chats/create";
import EditGroupMessage from "../pages/chats/edit";
import ResetPassword from "../pages/auth/ResetPassword";

import PhoneNumbers from "../pages/phoneNumbers";
import EditPhoneNumber from "../pages/phoneNumbers/edit";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));

export interface RoutesProps {
    path: RouteProps["path"];
    name?: string;
    component?: RouteProps["component"];
    route?: any;
    exact?: RouteProps["exact"];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: "/",
    exact: true,
    component: () => <Root />,
    route: Route,
};

const errorRoutes: RoutesProps[] = [
    {
        path: "/forbidden",
        name: "Forbidden",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: Error403,
    },
    {
        path: "/not_found",
        name: "Not Found",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: Error404,
    },
];

// dashboards
// const dashboardRoutes: RoutesProps = {
//   path: "/dashboard",
//   name: "Dashboards",
//   icon: "airplay",
//   header: "Navigation",
//   children: [],
// };

const childrenRoutes: RoutesProps[] = [
    {
        path: "/children/create",
        name: "Create Child",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: CreateChild,
    },
    {
        path: "/children",
        name: "Children",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: ChildrenList,
        exact: true,
    },
    {
        path: "/children/:id/checkins",
        name: "Child Checkins",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: ChildCheckinList,
    },
    {
        path: "/children/:id",
        name: "Edit Child",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: EditChild,
    },
];

const sessionsRoutes: RoutesProps[] = [
    {
        path: "/sessions/create",
        name: "Create Child",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: CreateSession,
    },
    {
        path: "/sessions",
        name: "Sessions",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: SessionList,
        exact: true,
    },
    {
        path: "/sessions/:id",
        name: "Edit Session",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: EditSession,
    },
];

const classesRoutes: RoutesProps[] = [
    {
        path: "/classes/create",
        name: "Create Class",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: CreateClass,
    },
    {
        path: "/classes",
        name: "Classes",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: ClassList,
        exact: true,
    },
    {
        path: "/classes/:id",
        name: "Edit Class",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: EditClass,
    },
];

const childParentRoutes: RoutesProps[] = [
    {
        path: "/child_parents/create",
        name: "Create Child Parent",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: CreateChildParent,
    },
    {
        path: "/child_parents",
        name: "Child Parent",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: ChildParentsList,
        exact: true,
    },
    {
        path: "/child_parents/:id",
        name: "Edit Child Parent",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: EditChildParent,
    },
];

const checkInsRoutes: RoutesProps[] = [
    {
        path: "/check_ins/create",
        name: "Create Check In",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: CreateCheckIn,
    },
    {
        path: "/check_ins",
        name: "Check Ins",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: CheckInList,
        exact: true,
    },
    {
        path: "/check_ins/add_absence",
        name: "Add Absence",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: AddAbsence,
    },
    {
        path: "/check_ins/:id",
        name: "Edit Check Ins",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: EditCheckIn,
    },
];

const classChildrenRoute: RoutesProps[] = [
    {
        path: "/class_children/:id/import",
        name: "Import Class Children",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: ImportClassChildren,
    },
    {
        path: "/class_children/:id",
        name: "Edit Check Ins",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: ClassChildren,
    },
];

const attendancesRoute: RoutesProps[] = [
    {
        path: "/attendances",
        name: "Attendances",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: AttendanceList,
    },
];

const sitesRoutes: RoutesProps[] = [
    {
        path: "/sites/create",
        name: "Create Site",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: CreateSite,
    },
    {
        path: "/sites",
        name: "Sites",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: SiteList,
        exact: true,
    },
    {
        path: "/sites/:id",
        name: "Edit Site",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: EditSite,
    },
];

const groupsRoutes: RoutesProps[] = [
    {
        path: "/groups/create",
        name: "Create Groups",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: CreateGroup,
    },
    {
        path: "/groups",
        name: "Groups",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: GroupList,
        exact: true,
    },
    {
        path: "/groups/:id",
        name: "Edit Group",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: EditGroup,
    },
];

const usersRoutes: RoutesProps[] = [
    {
        path: "/users/create",
        name: "Create Users",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: CreateUser,
    },
    {
        path: "/users",
        name: "Users",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: UserList,
        exact: true,
    },
    {
        path: "/users/:id",
        name: "Edit User",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: EditUser,
    },
];

const phoneNumbersRoutes: RoutesProps[] = [
    {
        path: "/phonenumbers/:id",
        name: "Edit Phone Number",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "phone",
        component: EditPhoneNumber,
    },
    {
        path: "/phonenumbers",
        name: "Phone Numbers",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "phone",
        component: PhoneNumbers,
    },
];

const chatsRoutes: RoutesProps[] = [
    {
        path: "/group_messages/create",
        name: "Create Group Chat",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: CreateGroupMessage,
    },
    {
        path: "/group_messages/:id",
        name: "Edit Group Chat",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: EditGroupMessage,
    },
    {
        path: "/group_messages",
        name: "Group Chats",
        route: PrivateRoute,
        roles: ["Admin"],
        icon: "user",
        component: GroupMessages,
    },
];

// auth
const authRoutes: RoutesProps[] = [
    {
        path: "/auth/login",
        name: "Login",
        component: Login,
        route: Route,
    },
    {
        path: "/auth/register",
        name: "Register",
        component: Register,
        route: Route,
    },
    {
        path: "/auth/confirm",
        name: "Confirm",
        component: Confirm,
        route: Route,
    },
    {
        path: "/auth/forget-password",
        name: "Forget Password",
        component: ForgetPassword,
        route: Route,
    },
    {
        path: "/auth/reset-password/:uidb64/:token",
        name: "Reset Password",
        component: ResetPassword,
        route: Route,
    },

    {
        path: "/auth/logout",
        name: "Logout",
        component: Logout,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== "undefined") {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [
    rootRoute,
    ...childrenRoutes,
    ...sessionsRoutes,
    ...classesRoutes,
    ...childParentRoutes,
    ...checkInsRoutes,
    ...classChildrenRoute,
    ...attendancesRoute,
    ...sitesRoutes,
    ...groupsRoutes,
    ...usersRoutes,
    ...errorRoutes,
    ...chatsRoutes,
    ...phoneNumbersRoutes,
];
const publicRoutes = [
    ...authRoutes,
    {
        path: "/guest_feeds",
        name: "guest_feeds",
        component: GuestFeedList,
        route: Route,
    },
];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
    publicRoutes,
    authProtectedRoutes,
    authProtectedFlattenRoutes,
    publicProtectedFlattenRoutes,
};
