export interface ITableData<M extends Model, P> {
    cell: {
        value: P;
    };
    row: {
        original: M;
    };
}

export interface Model {}

export interface IdentifierModel extends Model {
    id: number;
}

export interface ChildModel extends IdentifierModel {
    first_name: string;
    middle_name: string;
    last_name: string;
    medical_condition?: string;
    approved_pickup?: string;
    pronouns?: string;
    notes?: string;
    avatar?: string | undefined;
    group_id?: number;
    group?: GroupModel;
    class_id?: number;
    session_id?: number;
    class?: SessionClassModel;
    child_parents?: ChildParentModel[];
    checkins?: CheckinModel[];
    // parents?: ChildParentModel[];
    group_ids?: number[];
    groups?: GroupModel[];
    archive: boolean;
    feed_url?: string;
}

export interface ParentModel {
    id: number;
    first_name: string;
    middle_name: string;

    last_name: string;
    phone_number: string;
    avatar?: string | undefined;
    session_id?: number | undefined;
    session?: SessionModel;
}

export interface ChildParentModel {
    id?: string;
    is_primary: boolean;
    receive_group_notification: boolean;
    receive_checkin_notification: boolean;
    receive_checkout_notification: boolean;
    receive_feed_notification: boolean;
    // children_ids?: number[];
    // children?: ChildModel[];
    child_id: number;
    child?: ChildModel;
    parent_id: number;
    parent?: ParentModel;

    family_relation?: FamilyRelationModel;
    family_relation_id?: number;
}

export interface SessionModel {
    id: number;
    name: string;
    start_date: Date;
    end_date: Date;
    site_id: number;
    classes?: SessionClassModel[];
    site?: SiteModel;
    archive: boolean;
    draft: boolean;
}

export interface SessionClassModel {
    id: number;
    name: string;
    start_date: Date;
    end_date: Date;
    send_feed_link_at: string;
    groups?: GroupModel[];
    session_id?: number;
    session?: SessionModel;
    archive: boolean;
    draft: boolean;
    auto_checkout: boolean;
    auto_checkout_at: string;
    send_checkout_notification: boolean;
    send_report_to: string;
    attendance_notification: boolean;
    attendance_send_on: string;
    attendance_checked_in: boolean;
    attendance_checked_out: boolean;
    attendance_absent: boolean;
    attendance_sent_at: any;
    attendance_to: string;
}

export interface SiteModel {
    id: number;
    name: string;
    preamble: string;
    address: string;
    city: string;
    state: string;
    zip: number;
    organization_id?: number;
    archive: boolean;
    sessions?: SessionModel[];
    timezone?: string;
    phone_numbers?: PhoneNumberModel[];
}

export interface UserModel {
    id: number;
    username: string;
    first_name: string;
    password?: string;
    last_name: string;
    avatar?: string;
    phone_number_id?: number;
    phone_number?: PhoneNumberModel;
    site_id?: number;
    site?: SiteModel;
    role?: string;
    email: string;
    group: any;
}

export interface PhoneNumberModel {
    id: number;
    name: string;
    twilio_number: string;
    forwarding_number: string;
}

export type CheckinType = "checkin" | "checkout" | "absent" | "none";

export interface CheckinModel {
    id: number;
    checkin: Date;
    checkout?: Date;
    child_id: number;
    child?: ChildModel;
    group_id: number;
    group?: GroupModel;
    session_class_id?: number;
    session_class?: SessionClassModel;
    user_id?: number;
    user?: UserModel;
    tardy: boolean;
    late_pickup: boolean;
    absence_date?: Date;
    checkin_signature?: string;
    checkout_signature?: string;
}

export interface GroupModel {
    id: number;
    name: string;
    session_class_id?: number;
    session_class?: SessionClassModel;

    children?: ChildModel[];
    users?: UserModel[];
    archive: boolean;
    draft: boolean;
}

export interface FamilyRelationModel {
    id: number;
    kind: string;
}

export interface FeedMediaModel {
    id: number;
    media_type: string;
    media: string;
}

export interface FeedModel {
    id: number;
    description: string;
    medias: FeedMediaModel[];
    upload_at: Date;
}

export interface GroupChatModel {
    id: number;
    twilio_number: string;
    group?: GroupModel;
    messages?: GroupMessageModel[];
}

export interface GroupMessageModel {
    id: number;
    message: string;
    group_chat_id: number;
    schedule?: Date;
    send_date?: Date;
    phone_number?: PhoneNumberModel;
    group_chat?: GroupChatModel;
    statuses?: GroupMessageStatusModel[];
}
export interface GroupMessageStatusModel {
    id: number;
    status: "accepted" | "sent" | "received" | "delivered" | "failed";
    group_message?: GroupMessageModel;
    parent?: ParentModel;
}

export interface GroupGroupChat {
    id: number;
    name: string;
    chats: GroupChatModel[];
}

export interface SessionClassGroupChat {
    id: number;
    name: string;
    groups: GroupGroupChat[];
}

export interface SessionGroupChat {
    id: number;
    classes: SessionClassGroupChat[];
}

export interface SiteGroupChat {
    id: number;
    name: string;
    sessions: SessionGroupChat[];
}

export const statuses: string[] = ["active", "draft", "archived"];
