import { parseException } from "../../utils/exceptions/parse_exception";
import { GroupMessageModel, SiteModel } from "./models";
import Request from "./request";
import Response from "./response";

interface GroupMessageFilter {
    group_id?: number;
    session_class_id?: number;
    session_id?: number;
    from_date?: Date;
    to_date?: Date;
}

export const getGroupMessagesAPI = async (props?: GroupMessageFilter) => {
    try {
        let url = "group_messages?";
        if (props && props.group_id != null) {
            url += "group_id=" + props.group_id + "&";
        }

        if (props && props.session_class_id != null) {
            url += "session_class_ids=" + props.session_class_id + "&";
        }
        if (props && props.session_id != null) {
            url += "session_id=" + props.session_id + "&";
        }
        // if (props && props.from_date != null) {
        //     url += "from_date=" + props.from_date.setHours(0, 0, 0) / 1000 + "&";
        // }
        // if (props && props.to_date != null) {
        //     url += "to_date=" + props.to_date.setHours(23, 59, 59) / 1000 + "&";
        // }
        const response = await Request.getAuth(url);
        return new Response<GroupMessageModel[]>(
            response.data,
            response.status
        );
    } catch (e) {
        throw parseException(e);
    }
};

export const getSiteGroupsAPI = async () => {
    try {
        const response = await Request.getAuth("site_groups/");
        return new Response<SiteModel[]>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const sendGroupMessageAPI = async ({
    group_id,
    message,
    schedule,
}: {
    group_id: number;
    message: string;
    schedule?: Date;
}) => {
    try {
        let body: any = {
            group_id: group_id,
            message: message,
        };

        if (schedule != null) {
            body["schedule"] = (schedule.getTime() / 1000).toString();
        }

        const response = await Request.postAuth("group_messages/", body);
        return new Response<GroupMessageModel>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const sendMultiGroupMessageAPI = async ({
    group_ids,
    message,
    schedule,
}: {
    group_ids: number[];
    message: string;
    schedule?: Date;
}) => {
    try {
        let body: any = {
            group_ids: group_ids,
            message: message,
        };

        if (schedule != null) {
            body["schedule"] = (schedule.getTime() / 1000).toString();
        }

        const response = await Request.postAuth("multi_group_messages/", body);
        return new Response<GroupMessageModel>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const editGroupMessageAPI = async (
    id: number,
    { message, group_id }: { message: string; group_id?: number | null }
) => {
    try {
        let body: any = {
            message: message,
            group_id: group_id,
        };

        const response = await Request.patchAuth(
            "group_messages/" + id + "/",
            body
        );
        return new Response<GroupMessageModel>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const deleteGroupMessageAPI = async (id: number) => {
    try {
        const response = await Request.deleteAuth("group_messages/" + id + "/");
        return new Response<GroupMessageModel>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};
