import { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Inline from "yet-another-react-lightbox/plugins/inline";
// import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
// import "yet-another-react-lightbox/plugins/thumbnails.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import PageLoader from "../../components/PageLoader";
import { getGuestFeedsAPI } from "../../services/api/guest_feeds";
import { FeedModel } from "../../services/api/models";
import Request from "../../services/api/request";
import { getFormattedDate } from "../../utils/DateFormat";
import { handleException } from "../../utils/exceptions/handle_exception";
// import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Download from "yet-another-react-lightbox/plugins/download";
import LogoDark from "../../assets/images/logo-dark-test.png";
import "./guest_feeds.css";
import FullscreenImage from "./FullscreenImage";

const inline = {
    style: {
        width: "100%",
        maxWidth: "900px",
        aspectRatio: "3 / 3",
        margin: "0 auto",
    },
};

const GuestFeedList = (props: any) => {
    const [pageLoading, setPageLoading] = useState(true);
    const [feeds, setFeeds] = useState<FeedModel[]>([]);
    const history = useHistory();

    useEffect(() => {
        getFeedList();
    }, []);

    async function getFeedList() {
        setPageLoading(true);
        try {
            const response = await getGuestFeedsAPI(props.location.search);
            setFeeds(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    const fullscreenRef = useRef(null);

    const [currentImage, setCurrentImage] = useState();

    const getFeedView = (feed: FeedModel) => {
        const images: any[] = feed.medias.map((media) => ({
            src: Request.getImageUrl(media.media),
            // thumbnail: Request.getImageUrl(media.media),
            // thumbnailHeight: 75,
        }));

        return (
            <Card>
                <Card.Body>
                    <Lightbox
                        open={true}
                        slides={images}
                        carousel={{
                            preload: 2,
                            spacing: 0,
                            padding: 0,
                            imageFit: "cover",
                            finite: true,
                        }}
                        inline={inline}
                        plugins={[
                            Inline,
                            Fullscreen,
                            // Thumbnails,
                            Download,
                            // Slideshow,
                            Zoom,
                        ]}
                        fullscreen={{ ref: fullscreenRef }}
                        on={{
                            click: ({ index }) => {
                                // const f = fullscreenRef.current as any;
                                // if (!f?.fullscreen) {
                                //     f?.enter();
                                // } else {
                                //     f?.exit();
                                // }
                                setCurrentImage(images?.[index]?.src);
                            },
                        }}
                        render={{
                            iconZoomIn: () => <></>,
                            iconZoomOut: () => <></>,
                        }}
                        // thumbnails={{
                        //     position: "bottom",
                        //     width: 120,
                        //     height: 80,
                        //     gap: 0,
                        //     showToggle: false,
                        //     imageFit: "cover",
                        // }}
                        styles={{
                            container: {
                                backgroundColor: "transparent",
                            },
                            toolbar: {
                                visibility: "hidden",
                            },
                            ...(images.length === 1 && {
                                navigationPrev: {
                                    display: "none",
                                },
                                navigationNext: {
                                    display: "none",
                                },
                            }),

                            // thumbnail: {
                            //     backgroundColor: "transparent",
                            // },
                            // thumbnailsTrack: {
                            //     boxShadow: "none",
                            //     background: "none",
                            // },
                            // thumbnailsContainer: {
                            //     backgroundColor: "transparent",
                            // },
                        }}
                        // className={styles.lightbox}
                    />
                    <p className="mt-2 mb-0">
                        <span className="fw-bold">
                            {getFormattedDate(new Date(feed.upload_at), {
                                withTime: false,
                            })}
                        </span>
                        <h5>{feed.description}</h5>
                    </p>
                </Card.Body>
                {/*<Image rounded src={Request.getImageUrl(feed.medias[0].media)}></Image>*/}
            </Card>
        );
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {currentImage ? (
                <FullscreenImage
                    imageUrl={currentImage}
                    onClose={() => setCurrentImage(undefined)}
                />
            ) : (
                <></>
            )}

            {!pageLoading && (
                <div>
                    <Row className="justify-content-center">
                        <Col lg={4}>
                            <a
                                href="https://golooksee.app"
                                target="_blank"
                                className="logo text-center"
                                rel="noreferrer"
                            >
                                <span className="logo-lg">
                                    <img src={LogoDark} alt="" height="72" />
                                </span>
                            </a>
                            <h4 className="text-center my-3">Feeds</h4>

                            {feeds.map(function (feed, index) {
                                return (
                                    <div key={index}>{getFeedView(feed)}</div>
                                );
                            })}
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default GuestFeedList;
