import { ResponseCode } from "../../services/api/response";
import { BaseException } from "./base_exception";

export function parseException(e: any) {
    const response = e.response;

    const status = response.status;

    if (response.status === ResponseCode.unauthorized) {
        return new BaseException(response.data, status);
    } else if (response.status === ResponseCode.not_found) {
        return new BaseException(response.data, status);
    } else if (response.status === ResponseCode.forbidden) {
        return new BaseException(response.data, status);
    } else if (response.status === ResponseCode.unprocessable_entity) {
        return new BaseException(response.data, status);
    } else if (response.status === ResponseCode.error) {
        return new BaseException(response.data, status);
    }
}
