import { parseException } from "../../utils/exceptions/parse_exception";
import { ChildModel, ParentModel } from "./models";
import Request from "./request";
import Response from "./response";

export const checkParentNumberAPI = async (
    phone_number: string,
    session_id: number
) => {
    try {
        var url =
            "parents/check_for_number?phone_number=" +
            phone_number +
            "&session_id=" +
            session_id;

        const response = await Request.getAuth(url);
        console.log(response);
        return new Response<ParentModel[]>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const checkParentNumberForUpdateAPI = async (
    parent_id: number,
    session_id: number
) => {
    try {
        const url =
            "parents/check_number_for_update?parent_id=" +
            parent_id +
            "&session_id=" +
            session_id;

        const response = await Request.getAuth(url);
        return new Response<ChildModel[]>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};
