import { parseException } from "../../utils/exceptions/parse_exception";
import { CheckinModel, CheckinType, ChildModel } from "./models";
import Request from "./request";
import Response from "./response";

export const getCheckInsAPI = async () => {
    try {
        const response = await Request.getAuth("check_ins/");
        return new Response(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

interface CheckInsFilter {
    group_ids?: number[];
    child_id?: number;
    session_class_ids?: number[];
    session_id?: number;
    from_date?: Date;
    to_date?: Date;
    page_no?: any;
    page_size?: any;
}

export const getFilteredCheckInsAPI = async (props?: CheckInsFilter) => {
    try {
        let url = "check_ins?";
        if (props && props.group_ids != null && props.group_ids.length > 0) {
            url += "group_ids=" + props.group_ids.join(",") + "&";
        }
        if (props && props.child_id != null) {
            url += "child_id=" + props.child_id + "&";
        }
        if (
            props &&
            props.session_class_ids != null &&
            props.session_class_ids.length > 0
        ) {
            url +=
                "session_class_ids=" + props.session_class_ids.join(",") + "&";
        }
        if (props && props.session_id != null) {
            url += "session_id=" + props.session_id + "&";
        }
        if (props && props.from_date != null) {
            url +=
                "from_date=" + props.from_date.setHours(0, 0, 0) / 1000 + "&";
        }
        if (props && props.to_date != null) {
            url += "to_date=" + props.to_date.setHours(23, 59, 59) / 1000 + "&";
        }
        if (props && props.page_no && props.page_no != null) {
            url += "page=" + props.page_no + "&";
        }
        if (props && props.page_size && props.page_size != null) {
            url += "page_size=" + props.page_size;
        }

        const response = await Request.getAuth(url);
        return new Response(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const getExportedCheckInsAPI = async (props?: {
    child_id?: number;
    group_id: number;
    from_date?: Date;
    to_date?: Date;
}) => {
    try {
        let url = "check_ins/export?";
        if (props && props.child_id != null) {
            url += "child_id=" + props.child_id + "&";
        }
        if (props && props.group_id != null) {
            url += "group_id=" + props.group_id + "&";
        }
        if (props && props.from_date != null) {
            url +=
                "from_date=" + props.from_date.setHours(0, 0, 0) / 1000 + "&";
        }
        if (props && props.to_date != null) {
            url += "to_date=" + props.to_date.setHours(23, 59, 59) / 1000 + "&";
        }
        await Request.getFile(url, "check_ins.pdf");
    } catch (e) {
        throw parseException(e);
    }
};

export const createCheckinAPI = async (checkin: {
    checkin: Date;
    child_id: number;
    group_id: number;
    checkout?: Date;
}) => {
    try {
        let body = {
            group_id: checkin.group_id,
            child_id: checkin.child_id,
            skip_questionnaires: true,
            checkin: checkin.checkin,
            checkout: checkin.checkout,
        };

        // if (parent.avatar) {
        //     body = {
        //         ...body, ...{avatar: await Request.getBase64FromUrl(parent.avatar)},
        //     };
        // }

        const response = await Request.postAuth("check_ins/", body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const createAbsenceAPI = async (checkin: {
    start_date: Date;
    end_date: Date;
    child_id: number;
    group_id: number;
}) => {
    try {
        let body = {
            group_id: checkin.group_id,
            child_id: checkin.child_id,
            start_date:
                new Date(checkin.start_date.toString()).getTime() / 1000,
            end_date: new Date(checkin.end_date.toString()).getTime() / 1000,
            status: "add_absence",
        };

        // if (parent.avatar) {
        //     body = {
        //         ...body, ...{avatar: await Request.getBase64FromUrl(parent.avatar)},
        //     };
        // }

        const response = await Request.postAuth("check_ins/", body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const editCheckinAPI = async (
    checkin: {
        checkin: Date;
        child_id: number;
        group_id: number;
        id: number;
        checkout?: Date;
    },
    status?: string
) => {
    try {
        let body = {
            group_id: checkin.group_id,
            child_id: checkin.child_id,
            skip_questionnaires: true, // checkin
        };

        if (checkin.checkout) {
            body = {
                ...body,
                ...{
                    checkout: checkin.checkout,
                    status: status,
                },
            };
        }

        if (checkin.checkin) {
            body = {
                ...body,
                ...{
                    checkin: checkin.checkin,
                },
            };
        }

        const response = await Request.patchAuth(
            "check_ins/" + checkin.id + "/",
            body
        );
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const markCheckinTardyAPI = async (checkin_id: number) => {
    try {
        const response = await Request.patchAuth(
            "check_ins/" + checkin_id + "/",
            {
                status: "mark_as_tardy",
            }
        );
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const removeCheckinTardyAPI = async (checkin_id: number) => {
    try {
        const response = await Request.patchAuth(
            "check_ins/" + checkin_id + "/",
            {
                status: "remove_as_tardy",
            }
        );
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const markCheckinLatePickupAPI = async (checkin_id: number) => {
    try {
        const response = await Request.patchAuth(
            "check_ins/" + checkin_id + "/",
            {
                status: "mark_as_late_pickup",
            }
        );
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const removeCheckinLatePickupAPI = async (checkin_id: number) => {
    try {
        const response = await Request.patchAuth(
            "check_ins/" + checkin_id + "/",
            {
                status: "remove_as_late_pickup",
            }
        );
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const getCheckInAPI = async (id: number) => {
    try {
        const response = await Request.getAuth(`check_ins/${id}`);
        return new Response<CheckinModel>(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const deleteCheckinAPI = async (checkin_id: number) => {
    try {
        const response = await Request.deleteAuth(
            "check_ins/" + checkin_id + "/"
        );
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const getCheckinStatusFromChild = (child: ChildModel): CheckinType => {
    if (child.checkins == null || child.checkins.length === 0) {
        return "none";
    }

    // const lastCheckInEntry = [...child?.checkins].pop();
    // if(lastCheckInEntry!=null) {
    //     if (lastCheckInEntry.absence_date != null ) return 'absent';
    //     if (lastCheckInEntry.checkout != null) return 'checkout';
    //     if (lastCheckInEntry.checkin != null ) return 'checkin';
    // }

    const today = new Date();
    // const lastCheckin = child.checkins.reverse().find((c)=>c.absence_date==null);
    const lastCheckin = child.checkins
        .slice()
        .reverse()
        .find((c) => c.absence_date == null);

    let todayAbsent = child.checkins.filter(function (checkin) {
        if (checkin.absence_date != null) {
            let date = new Date(checkin.absence_date);
            return (
                date.getUTCDate() === today.getUTCDate() &&
                date.getUTCMonth() === today.getUTCMonth() &&
                date.getUTCFullYear() === today.getUTCFullYear()
            );
        }
        return false;
    });

    if (todayAbsent.length > 0) return "absent";
    // let date = lastCheckin.absence_date;
    // if(date!=null){
    //    date = new Date(date);
    //     if(date.getUTCDate()===today.getUTCDate() && date.getUTCMonth()===today.getUTCMonth() && date.getUTCFullYear()===today.getUTCFullYear()){
    //         if (lastCheckin.absence_date != null) return 'absent';
    //
    //     }
    // }
    if (lastCheckin != null) {
        if (lastCheckin.checkout != null) return "checkout";
        if (lastCheckin.checkin != null) return "checkin";
    }
    return "none";
};
