import { CustomSelectOption } from "../../components/CustomSelect";
import {
    GroupModel,
    PhoneNumberModel,
    SiteModel,
} from "../../services/api/models";

export const siteToCustomSelectOption = (
    site: SiteModel
): CustomSelectOption<number> => ({
    id: site.id,
    label: site.name,
});

export const phoneToCustomSelectOption = (
    phone: PhoneNumberModel
): CustomSelectOption<number> => ({
    id: phone.id,
    label: phone.twilio_number,
});

export const groupToCustomSelectOption = (
    group: GroupModel
): CustomSelectOption<number> => ({
    id: group.id,
    label: group.name,
});

export const roleToCustomSelectOption = ({
    name: id,
    label,
}: {
    name: string;
    label: string;
}): CustomSelectOption<string> => ({ id, label });
