import { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import {
    deleteGroupMessageAPI,
    getGroupMessagesAPI,
} from "../../services/api/chats";
import {
    GroupMessageModel,
    GroupModel,
    ITableData,
    SessionClassModel,
    SessionModel,
} from "../../services/api/models";
import {
    getAllClassesFromSessions,
    getSessionsForCheckinAPI,
} from "../../services/api/sessions";
import { ToastNotification } from "../../services/toast_notification";
import { getFormattedDate } from "../../utils/DateFormat";
import { handleException } from "../../utils/exceptions/handle_exception";

// components

const GroupMessages = () => {
    const history = useHistory();

    const [sessions, setSessions] = useState<SessionModel[]>([]);
    const [selectedSession, setSelectedSession] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [classes, setClasses] = useState<SessionClassModel[]>([]);
    const [selectedClass, setSelectedClass] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [groups, setGroups] = useState<GroupModel[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // const [fromDate, setFromDate] = useState<Date | undefined>();
    // const [toDate, setToDate] = useState<Date | undefined>();

    const [changeFilter, setChangeFilter] = useState<boolean>(false);

    const [groupMessages, setGroupMessages] = useState<GroupMessageModel[]>([]);
    const [needToDeleteMessage, setNeedToDeleteMessage] =
        useState<GroupMessageModel | null>(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [messagesLoading, setMessagesLoading] = useState(false);

    useEffect(() => {
        getSessionData();
        getGroupChats();
    }, []);

    useEffect(() => {
        if (changeFilter) {
            setChangeFilter(false);
            getGroupChats();
        }
    }, [changeFilter]);

    async function getGroupChats() {
        setMessagesLoading(true);
        try {
            const response = await getGroupMessagesAPI({
                group_id:
                    selectedGroup.length > 0 ? selectedGroup[0].id : undefined,

                session_id:
                    selectedSession.length > 0
                        ? selectedSession[0].id
                        : undefined,
                session_class_id:
                    selectedClass.length > 0 ? selectedClass[0].id : undefined,
                // from_date: fromDate,
                // to_date: toDate,
            });
            setGroupMessages(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setMessagesLoading(false);
    }

    async function getSessionData() {
        setPageLoading(true);
        try {
            const response = await getSessionsForCheckinAPI();
            setSessions(response.data);
            setClasses(getAllClassesFromSessions(response.data));
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    const deleteMessage = async (groupMessage: GroupMessageModel) => {
        setNeedToDeleteMessage(groupMessage);
        setShowConfirmationModal(true);
    };

    const deleteMessageWithConfirmation = async () => {
        let groupMessage = needToDeleteMessage;
        if (groupMessage == null) return;
        try {
            const response = await deleteGroupMessageAPI(groupMessage.id);
            if (response.isSuccess()) {
                setGroupMessages(
                    groupMessages.filter((gm) => gm.id !== groupMessage!.id)
                );
                ToastNotification.success("Group message deleted");
            }
        } catch (e: any) {
            handleException(history, e);
        }
    };

    const compareDateString = (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
    ): number => {
        return new Date(rowA.original.schedule).getTime() >
            new Date(rowB.original.schedule).getTime()
            ? 1
            : -1;
    };

    const onRowClick = (data: any) => {
        history.push({
            pathname: `/group_messages/${data.original.id}`,
            state: {
                group_message: data.original,
            },
        });
    };

    const columns = [
        {
            Header: "Date",
            accessor: "",
            sort: true,
            maxWidth: 210,
            sortType: compareDateString,
            Cell: (data: ITableData<GroupMessageModel, string>) => {
                const sendAt = data.row.original.send_date;
                const schedule = data.row.original.schedule;

                if (schedule != null) {
                    return (
                        <div className="d-flex">
                            <i className="mdi mdi-clock-outline  me-1"></i>
                            <span className="fw-semibold">
                                {getFormattedDate(new Date(schedule), {
                                    showSecond: false,
                                })}
                            </span>
                        </div>
                    );
                } else if (sendAt != null) {
                    return (
                        <span className="fw-semibold">
                            {getFormattedDate(new Date(sendAt), {
                                showSecond: false,
                            })}
                        </span>
                    );
                }
                return "-";
                // let checkin = data.row.original.checkin;
                // let absence_date = data.row.original.absence_date;
                // return (
                //     <span
                //         className='fw-semibold'>{getFormattedDate(new Date(absence_date ?? checkin), {withTime: false})}
                //     </span>
                //
                // );
            },
        },
        {
            Header: "Status",
            accessor: "",
            sort: true,
            maxWidth: 120,
            Cell: (data: ITableData<GroupMessageModel, string>) => {
                let message = data.row.original;
                const sendAt = message.send_date;
                const schedule = message.schedule;

                let partialSent = false;
                if (message.statuses != null) {
                    for (let status of message.statuses) {
                        if (status.status !== "delivered") {
                            partialSent = true;
                            break;
                        }
                    }
                }
                if (
                    schedule != null &&
                    new Date(schedule) > new Date(new Date())
                ) {
                    return <span>Scheduled</span>;
                } else if (sendAt != null) {
                    if (partialSent) {
                        return (
                            <span className="">
                                <i className="mdi mdi-information-outline font-14 text-danger"></i>
                                <span className="ms-1 text-success">Sent</span>
                            </span>
                        );
                    } else {
                        return (
                            <span className="text-success">
                                <i className="mdi mdi-check font-14"></i>
                                <span className="ms-1">Sent</span>
                            </span>
                        );
                    }
                }

                return "-";
                // let checkin = data.row.original.checkin;
                // let absence_date = data.row.original.absence_date;
                // return (
                //     <span
                //         className='fw-semibold'>{getFormattedDate(new Date(absence_date ?? checkin), {withTime: false})}
                //     </span>
                //
                // );
            },
        },
        {
            Header: "Site",
            accessor: "",
            sort: true,
            Cell: (data: ITableData<GroupMessageModel, string>) => {
                return (
                    data.row.original.group_chat?.group?.session_class?.session
                        ?.site?.name ?? "-"
                );
            },
        },

        {
            Header: "Class",
            accessor: "",
            sort: true,
            Cell: (data: ITableData<GroupMessageModel, string>) => {
                return (
                    data.row.original.group_chat?.group?.session_class?.name ??
                    "-"
                );
            },
        },
        {
            Header: "Group",
            accessor: "",
            sort: true,
            Cell: (data: any) => {
                return data.row.original.group_chat?.group?.name ?? "-";
            },
        },
        {
            Header: "Message",
            accessor: "",
            sort: true,
            maxWidth: 450,
            width: 450,
            className: "text-wrap",
            Cell: (data: ITableData<GroupMessageModel, string>) => {
                return data.row.original.message;
            },
        },
        {
            Header: "Edit",
            accessor: "id1",
            maxWidth: 100,
            Cell: (data: ITableData<GroupMessageModel, string>) => {
                let schedule = data.row.original.schedule;

                if (
                    schedule != null &&
                    new Date(schedule) > new Date(new Date())
                ) {
                    return (
                        <div>
                            <span className="text-primary">
                                <i className="mdi mdi-pencil-outline font-19"></i>
                            </span>

                            <span
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    deleteMessage(data.row.original);
                                }}
                                className="cursor-pointer"
                            >
                                <i className="mdi mdi-trash-can-outline ms-2 text-danger font-19"></i>
                            </span>
                        </div>
                    );
                }
                return "";
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: groupMessages.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Group Messages",
                                        active: true,
                                    },
                                ]}
                                title="Group Messages"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <Form.Label>Select Session</Form.Label>

                            <CustomSelect
                                id="session-select"
                                multiple={false}
                                onChange={(e) => {
                                    setSelectedSession(e);
                                    if (e.length === 0) {
                                        setClasses(
                                            getAllClassesFromSessions(sessions)
                                        );
                                        return;
                                    }
                                    setSelectedClass([]);
                                    setSelectedGroup([]);
                                    const session = sessions.find(
                                        ({ id }) => id === e?.[0]?.id
                                    );
                                    setClasses(session?.classes ?? []);
                                    setChangeFilter(true);
                                }}
                                options={sessions.map((s) => ({
                                    id: s.id,
                                    label: s.name,
                                }))}
                                placeholder="Select a session"
                                selected={selectedSession}
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Label>Select Class</Form.Label>

                            <CustomSelect
                                id="class-select"
                                multiple={true}
                                emptyLabel={
                                    selectedSession.length > 0
                                        ? "No any class with this session"
                                        : "Please select session first"
                                }
                                onChange={(e) => {
                                    setSelectedClass(e);
                                    if (e.length === 0) return;
                                    setSelectedGroup([]);
                                    const cl = classes.find(
                                        ({ id }) => id === e?.[0]?.id
                                    );
                                    setGroups(cl?.groups ?? []);
                                    setChangeFilter(true);
                                }}
                                options={classes.map((c) => ({
                                    id: c.id,
                                    label: `${c.name} ${
                                        c.draft ? "[Draft]" : ""
                                    }`,
                                }))}
                                placeholder="Select a class"
                                selected={selectedClass}
                            />
                        </Col>
                        <Col lg={4}>
                            <Form.Label>Select Group</Form.Label>

                            <CustomSelect
                                id="group-select"
                                emptyLabel={
                                    selectedClass.length > 0
                                        ? "No any group with this class"
                                        : "Please select class first"
                                }
                                onChange={(e) => {
                                    setSelectedGroup(e);
                                    if (e.length === 0) return;
                                    setChangeFilter(true);
                                }}
                                options={groups.map((g) => ({
                                    id: g.id,
                                    label: `${g.name} ${
                                        g.draft ? "[Draft]" : ""
                                    }`,
                                }))}
                                placeholder="Select a group"
                                selected={selectedGroup}
                            />
                        </Col>
                    </Row>

                    <div>
                        <Row className="mt-3">
                            <Col>
                                <Card>
                                    <Card.Body>
                                        {groupMessages.length === 0 && (
                                            <div className="d-flex align-content-center justify-content-between">
                                                <h4 className="text-center">
                                                    No Messages
                                                </h4>
                                                <Link
                                                    to="/group_messages/create"
                                                    className="btn btn-primary"
                                                >
                                                    <i className="mdi mdi-plus-circle  me-1"></i>
                                                    Send Group Message
                                                </Link>
                                            </div>
                                        )}
                                        {!messagesLoading &&
                                            groupMessages.length > 0 && (
                                                <>
                                                    <Table
                                                        id="group-messages"
                                                        columns={columns}
                                                        data={groupMessages}
                                                        pageSize={10}
                                                        sizePerPageList={
                                                            sizePerPageList
                                                        }
                                                        isSortable={true}
                                                        pagination={true}
                                                        isSearchable={true}
                                                        sortBy={[
                                                            {
                                                                id: "checkin",
                                                                desc: true,
                                                            },
                                                        ]}
                                                        tableClass={
                                                            "table-striped"
                                                        }
                                                        onRowClick={onRowClick}
                                                        actions={
                                                            <Link
                                                                to="/group_messages/create"
                                                                className="btn btn-primary"
                                                            >
                                                                <i className="mdi mdi-plus-circle  me-1"></i>
                                                                Send Group
                                                                Message
                                                            </Link>
                                                        }
                                                    />
                                                </>
                                            )}
                                        <PageLoader loading={messagesLoading} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
            <ConfirmationModal
                show={showConfirmationModal}
                title="Delete Scheduled message?"
                body={
                    <div>
                        <p>
                            Are you sure you want to delete this scheduled
                            message?
                        </p>
                    </div>
                }
                onCancel={() => {
                    setShowConfirmationModal(false);
                }}
                onConfirm={() => {
                    setShowConfirmationModal(false);
                    deleteMessageWithConfirmation().then();
                }}
            />
        </>
    );
};

export default GroupMessages;
