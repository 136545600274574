import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import PlaceHolderImage from "./PlaceholderImage";

export interface FileType {
    preview?: string;
    formattedSize?: string;
    name: string;
    status?: string;
}

interface FileUploaderProps {
    onFileUpload?: (files: FileType[]) => void;
    onFileRemoved?: (files: FileType[]) => void;

    showPreview?: boolean;
    multiple?: boolean;
    files?: FileType[];
    maxFiles: number;
}

const FileUploader = (props: FileUploaderProps) => {
    const [selectedFiles, setSelectedFiles] = useState<FileType[]>(
        props.files != null ? props.files : []
    );

    /**
     * Handled the accepted files and shows the preview
     */

    const handleAcceptedFiles = (uploadedFile: FileType[]) => {
        if (props.showPreview) {
            (uploadedFile || []).map((file: any) => {
                return Object.assign(file, {
                    preview: URL.createObjectURL(file),
                });
            });

            if (selectedFiles.length > 0) {
                removeFile(0);
            }
            setSelectedFiles(uploadedFile);
            if (props.onFileUpload) props.onFileUpload(uploadedFile);
        }
    };

    /**
     * Formats the size
     */
    /*
     * Removes the selected file
     */
    const removeFile = (fileIndex: number) => {
        const file = selectedFiles[fileIndex];
        const newFiles = [...selectedFiles];
        newFiles.splice(fileIndex, 1);
        setSelectedFiles(newFiles);
        if (props.onFileRemoved) props.onFileRemoved([file]);
    };

    return (
        <>
            <Dropzone
                {...props}
                maxFiles={props.maxFiles}
                onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
                multiple={props.multiple}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                        <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <i className="h3 text-muted dripicons-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                            <span className="text-muted font-13">
                                (This is just a demo dropzone. Selected files
                                are <strong>not</strong> actually uploaded.)
                            </span>
                        </div>
                    </div>
                )}
            </Dropzone>

            {props.showPreview && (
                <div className="dropzone-previews" id="uploadPreviewTemplate">
                    {(selectedFiles || []).map((f, i) => {
                        return (
                            <Card
                                className="mt-2 mb-0 shadow-none border"
                                key={i + "-file"}
                            >
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        {f.preview && (
                                            <Col className="col-auto">
                                                <PlaceHolderImage
                                                    src={f.preview}
                                                    className="avatar-sm rounded bg-light"
                                                />
                                            </Col>
                                        )}
                                        {!f.preview && (
                                            <Col className="col-auto">
                                                <div className="avatar-sm"></div>
                                            </Col>
                                        )}
                                        <Col className="ps-0">
                                            <Link
                                                to="#"
                                                className="text-muted fw-bold"
                                            >
                                                {f.name}
                                            </Link>
                                            <p className="mb-0">
                                                <strong>
                                                    {f.formattedSize}
                                                </strong>
                                            </p>
                                        </Col>
                                        <Col className="text-end">
                                            <Link
                                                to="#"
                                                className="btn btn-link btn-lg text-muted shadow-none"
                                            >
                                                <i
                                                    className="dripicons-cross"
                                                    onClick={() =>
                                                        removeFile(i)
                                                    }
                                                ></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        );
                    })}
                </div>
            )}
        </>
    );
};

FileUploader.defaultProps = {
    showPreview: true,
    multiple: false,
    maxFiles: 10,
    files: [],
};

export default FileUploader;
