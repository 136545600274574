import React, {forwardRef} from "react";
import RDatePicker from "react-datepicker";
import classNames from "classnames";
import {Form} from "react-bootstrap";

interface DatepickerInputProps {
    onClick?: () => void;
    value?: string;
    inputClass: string;
    children?: React.ReactNode;
}

/* Datepicker with Input */
const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => {
    const onDateValueChange = () => {
    };
    return (<input
        type="text"
        className={classNames("form-control", props.inputClass)}
        onClick={props.onClick}
        value={props.value}
        onChange={onDateValueChange}
        ref={ref}
    />);
});

/* Datepicker with Addon Input */
const DatepickerInputWithAddon = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => (
    <div className="input-group input-group-sm" ref={ref}>
        <input
            type="text"
            className={classNames("form-control", props.inputClass)}
            onClick={props.onClick}
            value={props.value}
            readOnly
        />
        <span className="input-group-text bg-blue border-blue text-white">
            <i className="mdi mdi-calendar-range"></i>
        </span>
    </div>));

interface DatepickerProps {
    value?: Date;
    onChange: (date: any) => void;
    showAddon?: boolean;
    inputClass?: string;
    dateFormat?: string;
    minDate?: Date;
    maxDate?: Date;
    showTimeSelect?: boolean;
    tI?: number;
    timeCaption?: string;
    timeFormat?: string;
    showTimeSelectOnly?: boolean;
    monthsShown?: number;
    inline?: boolean;
    errors?: any;
    name?: string
}

const DatePicker = (props: DatepickerProps) => {
    // handle custom input
    const input = !(props.showAddon || false) ? (<DatepickerInputWithAddon inputClass={props.inputClass!}/>) : (
        <DatepickerInput inputClass={props.inputClass!}/>);

    return (<>
        {/* date picker control */}
        <RDatePicker
            customInput={input}
            timeIntervals={props.tI}
            selected={props.value}
            onChange={(date) => props.onChange(date)}
            showTimeSelect={props.showTimeSelect}
            timeFormat={props.timeFormat || "hh:mm a"}
            timeCaption={props.timeCaption}
            dateFormat={props.dateFormat || "MM/dd/yyyy"}
            minDate={props.minDate}
            maxDate={props.maxDate}
            monthsShown={props.monthsShown}
            showTimeSelectOnly={props.showTimeSelectOnly}
            inline={props.inline}
            autoComplete="off"
        />
        {props.errors && props.name && props.errors[props.name] ? (
            <Form.Control.Feedback type="invalid" className='d-block'>
                {props.errors[props.name]}
            </Form.Control.Feedback>) : null}
    </>);
};

export default DatePicker;
