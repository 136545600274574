import Request from "./request";
import Response from "./response";
import { parseException } from "../../utils/exceptions/parse_exception";
import { UserModel } from "./models";

export const getUsersAPI = async () => {
    try {
        const response = await Request.getAuth("users/");
        return new Response<UserModel[]>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const createUserAPI = async (user: UserModel) => {
    const userIds = (user.group ?? []).map((group: any) => group.id);
    try {
        let body = {
            first_name: user.first_name,
            last_name: user.last_name,
            password: user.password,
            phone_number_id: user.phone_number_id,
            site_id: user.site_id,
            email: user.email,
            username: user.username,
            role: user.role,
            groups_id: userIds,
        };

        if (user.avatar) {
            body = {
                ...body,
                ...{ avatar: await Request.getBase64FromUrl(user.avatar) },
            };
        }

        const response = await Request.postAuth("users/", body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const editUserAPI = async (user: UserModel) => {
    const userIds = (user.group ?? []).map((group: any) => group.id);
    try {
        let body = {
            first_name: user.first_name,
            last_name: user.last_name,
            password: user.password,
            phone_number_id: user.phone_number_id,
            site_id: user.site_id,
            email: user.email,
            username: user.username,
            role: user.role,
            groups_id: userIds,
        };

        if (user.avatar) {
            body = {
                ...body,
                ...{ avatar: await Request.getBase64FromUrl(user.avatar) },
            };
        }

        const response = await Request.patchAuth(`users/${user.id}/`, body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const getUserAPI = async (id: number) => {
    try {
        const response = await Request.getAuth(`users/${id}/`);
        return new Response<UserModel>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const deleteUserAPI = async (userId: string) => {
    try {
        const response = await Request.deleteAuth(`users/${userId}/`);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};
