import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import {
    GroupModel,
    SessionClassModel,
    SessionModel,
} from "../../services/api/models";
import { ToastNotification } from "../../services/toast_notification";
import { handleException } from "../../utils/exceptions/handle_exception";

import { FormInput } from "../../components";
import DatePicker from "../../components/DatePicker";
import { sendMultiGroupMessageAPI } from "../../services/api/chats";
import {
    getAllClassesFromSessions,
    getSessionsForCheckinAPI,
} from "../../services/api/sessions";
import { BaseException } from "../../utils/exceptions/base_exception";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import {
    classToCustomOption,
    groupToCustomOption,
    sessionToCustomOption,
} from "../utils";

const CreateGroupMessage = () => {
    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState();

    const [message, setMessage] = useState<string>("");
    // const [selectedSite, setSelectedSite] = useState<SessionModel[]>([]);

    const [sessions, setSessions] = useState<SessionModel[]>([]);
    const [selectedSession, setSelectedSession] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [classes, setClasses] = useState<SessionClassModel[]>([]);
    const [selectedClass, setSelectedClass] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [groups, setGroups] = useState<GroupModel[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [schedule, setSchedule] = useState<Date | undefined>(undefined);

    useEffect(() => {
        getSessionData();
    }, []);

    async function getSessionData() {
        setPageLoading(true);
        try {
            const response = await getSessionsForCheckinAPI();
            setSessions(response.data);
            setClasses(getAllClassesFromSessions(response.data));
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    const sendMessage = async () => {
        if (selectedGroup.length === 0) {
            ToastNotification.error("Please select group");
            return;
        }
        if (message.length === 0) {
            ToastNotification.error("Please provide a message");
            return;
        }
        setSending(true);

        try {
            const response = await sendMultiGroupMessageAPI({
                group_ids: selectedGroup.map((value) => value.id),
                message: message,
                schedule: schedule,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Group message sent");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setSending(false);
    };

    function handleOrNow() {
        setSchedule(undefined);
    }

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Groups",
                                        path: "/groups",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Send Message "}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <Form.Label>Select Site</Form.Label>
                                    <CustomSelect
                                        id="session-select"
                                        multiple={false}
                                        onChange={(e) => {
                                            setSelectedSession(e);
                                            if (e.length === 0) {
                                                setClasses(
                                                    getAllClassesFromSessions(
                                                        sessions
                                                    )
                                                );
                                                return;
                                            }
                                            setSelectedClass([]);
                                            setSelectedGroup([]);
                                            const session = sessions.find(
                                                ({ id }) => id === e?.[0]?.id
                                            );
                                            setClasses(session?.classes ?? []);
                                        }}
                                        options={sessions.map(
                                            sessionToCustomOption
                                        )}
                                        placeholder="Select a site"
                                        selected={selectedSession}
                                    />

                                    <Form.Label className="mt-3">
                                        Select Class
                                    </Form.Label>

                                    <CustomSelect
                                        id="class-select"
                                        multiple={true}
                                        emptyLabel={
                                            selectedSession.length > 0
                                                ? "No any class with this session"
                                                : "Please select session first"
                                        }
                                        onChange={(e) => {
                                            setSelectedClass(e);
                                            if (e.length === 0) return;
                                            setSelectedGroup([]);
                                            const cl = classes.find(
                                                ({ id }) => id === e?.[0]?.id
                                            );
                                            setGroups(cl?.groups ?? []);
                                        }}
                                        options={classes.map(
                                            classToCustomOption
                                        )}
                                        placeholder="Select a class"
                                        selected={selectedClass}
                                    />
                                    <Form.Label className="mt-3">
                                        Select Group
                                    </Form.Label>

                                    <CustomSelect
                                        id="group-select"
                                        multiple={true}
                                        emptyLabel={
                                            selectedClass.length > 0
                                                ? "No any group with this class"
                                                : "Please select class first"
                                        }
                                        onChange={(e) => {
                                            setSelectedGroup(e);
                                            if (e.length === 0) return;
                                        }}
                                        options={groups.map(
                                            groupToCustomOption
                                        )}
                                        placeholder="Select a group"
                                        selected={selectedGroup}
                                        className="mb-3 "
                                    />

                                    <Form.Label className=""></Form.Label>
                                    <div>
                                        <FormInput
                                            required
                                            multiple
                                            rows="3"
                                            id="message"
                                            type="textarea"
                                            label="Message"
                                            placeholder="Message"
                                            value={message}
                                            onChange={(e) =>
                                                setMessage(e.target.value)
                                            }
                                            errors={errors}
                                            name="message"
                                            autoSpace={false}
                                            maxLength={2000}
                                        />
                                        <Form.Text id="message" muted>
                                            {`${message.length}/2000`}
                                        </Form.Text>
                                    </div>

                                    <Form.Label className="mt-2">
                                        Schedule
                                    </Form.Label>

                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <DatePicker
                                                showAddon={true}
                                                showTimeSelect
                                                timeFormat="h:mm aa"
                                                tI={30}
                                                errors={errors}
                                                name="start_date"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                timeCaption="time"
                                                value={schedule}
                                                onChange={(date) => {
                                                    setSchedule(date);
                                                }}
                                            />
                                        </div>

                                        {schedule != null && (
                                            <span
                                                className="text-primary fw-medium ms-2 cursor-pointer"
                                                onClick={handleOrNow}
                                            >
                                                Or now
                                            </span>
                                        )}
                                    </div>

                                    <div className="text-end mt-3">
                                        <Button
                                            type="submit"
                                            onClick={sendMessage}
                                            disabled={sending}
                                        >
                                            <i
                                                className={
                                                    (sending
                                                        ? "mdi mdi-spin mdi-loading"
                                                        : "mdi mdi-check") +
                                                    " me-1"
                                                }
                                            />{" "}
                                            {schedule === undefined
                                                ? "Send Now"
                                                : "Schedule"}
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default CreateGroupMessage;
