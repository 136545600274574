import { useEffect, useState } from "react";
import { Button, Card, Col, Form, FormCheck, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { getChildrenAPI } from "../../services/api/children";
import { getClassesAPI } from "../../services/api/classes";
import { createGroupAPI } from "../../services/api/groups";
import {
    ChildModel,
    SessionClassModel,
    UserModel,
} from "../../services/api/models";
import { getUsersAPI } from "../../services/api/user";
import LocalStorage from "../../services/local_storage";
import { ToastNotification } from "../../services/toast_notification";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";
import {
    childToCustomOption,
    sessionClassToCustomOption,
    userToCustomOption,
} from "../utils";

const CreateGroup = () => {
    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [errors, setErrors] = useState();

    const [classes, setClasses] = useState<SessionClassModel[]>([]);
    const [selectedClass, setSelectedClass] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [users, setUser] = useState<UserModel[]>([]);
    const [selectedUser, setSelectedUser] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [children, setChildren] = useState<ChildModel[]>([]);
    const [selectedChildren, setSelectedChildren] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [draft, setDraft] = useState(false);

    const [name, setName] = useState("");

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setPageLoading(true);
        try {
            const classResponse = await getClassesAPI(["active", "draft"]); //['draft']
            setClasses(classResponse.data);
            const userResponse = await getUsersAPI();
            setUser(userResponse.data);

            const childrenResponse = await getChildrenAPI();
            setChildren(childrenResponse.data);

            const user: UserModel | null = LocalStorage.getUser();
            if (user != null) {
                setSelectedUser([userToCustomOption(user)]);
            }
        } catch (e) {
            ToastNotification.unknownError();
            handleException(history, e);
        }
        setPageLoading(false);
    };

    useEffect(() => {
        if (selectedClass.length > 0) {
            let selectedClassWithChildren: any = {};

            const cls = classes.find(({ id }) => id === selectedClass?.[0]?.id);

            if (cls?.groups?.length) {
                selectedClassWithChildren = cls;
            } else {
                selectedClassWithChildren =
                    classes.find((data) => data.id === selectedClass[0]?.id) ||
                    {};
            }

            const listOfChildren =
                selectedClassWithChildren?.groups
                    ?.map((data: any) =>
                        data?.children && data?.children?.length > 0
                            ? data?.children
                            : undefined
                    )
                    .filter((data: any) => data !== undefined) ?? [];

            setChildren(listOfChildren.flat());
        } else {
            setSelectedChildren([]);
        }
    }, [classes, selectedClass]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (selectedClass.length === 0) {
            ToastNotification.error("Please select class");
            return;
        }
        createGroup();
    };

    const createGroup = async () => {
        setCreating(true);
        try {
            const response = await createGroupAPI({
                name: name,
                session_class_id: selectedClass[0].id,
                users: users.filter(({ id }) =>
                    selectedUser.find((u) => u.id === id)
                ),
                children: children.filter(({ id }) =>
                    selectedChildren.find((u) => u.id === id)
                ),
                draft: draft,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Group is created");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setCreating(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Groups",
                                        path: "/groups",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Create "
                                    .concat(
                                        name.length > 0
                                            ? name.concat("'s ")
                                            : ""
                                    )
                                    .concat("Group")}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <FormInput
                                        required
                                        type="text"
                                        label="Group Name"
                                        placeholder="Name"
                                        value={name}
                                        errors={errors}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        name="name"
                                    />

                                    <Form.Label>Select Class</Form.Label>

                                    <CustomSelect
                                        id="class-select"
                                        multiple={false}
                                        emptyLabel={"There is no class"}
                                        onChange={(e) => {
                                            setSelectedClass(e);
                                        }}
                                        options={classes.map(
                                            sessionClassToCustomOption
                                        )}
                                        placeholder="Select a class"
                                        selected={selectedClass}
                                    />

                                    <Form.Label className="mt-3">
                                        Select Users
                                    </Form.Label>

                                    <CustomSelect
                                        id="user-select"
                                        multiple={true}
                                        emptyLabel={"There is no user"}
                                        onChange={(e) => {
                                            setSelectedUser(e);
                                        }}
                                        options={users.map(userToCustomOption)}
                                        placeholder="Select users"
                                        selected={selectedUser}
                                    />

                                    <Form.Label className="mt-3">
                                        Select Children
                                    </Form.Label>

                                    <CustomSelect
                                        id="child-select"
                                        multiple={true}
                                        emptyLabel={"There is no children"}
                                        onChange={(e) => {
                                            setSelectedChildren(e);
                                        }}
                                        options={children.map(
                                            childToCustomOption
                                        )}
                                        placeholder="Select Children"
                                        selected={selectedChildren}
                                    />
                                    <Row className="mt-3 ">
                                        <Col>
                                            <FormCheck
                                                className={"mt-2"}
                                                type="checkbox"
                                                id="default-checkbox2"
                                                label="Draft"
                                                checked={draft}
                                                onChange={(e) =>
                                                    setDraft(e.target.checked)
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                type="submit"
                                                className="float-end"
                                                onClick={handleSubmit}
                                                disabled={creating}
                                            >
                                                <i
                                                    className={
                                                        (creating
                                                            ? "mdi mdi-spin mdi-loading"
                                                            : "mdi mdi-check") +
                                                        " me-1"
                                                    }
                                                />{" "}
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default CreateGroup;
