import React, {useEffect, useState} from "react";
import {Button, Card, Col, FormCheck, Row} from "react-bootstrap";
import {ToastNotification} from "../../services/toast_notification";
import PageTitle from "../../components/PageTitle";
import {useHistory} from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import {editSiteAPI, getSiteAPI} from "../../services/api/sites";
import {handleException} from "../../utils/exceptions/handle_exception";
import {FormInput} from "../../components";
import {BaseException} from "../../utils/exceptions/base_exception";


const EditSite = (props: any) => {
    const {id} = props.match.params;

    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [errors, setErrors] = useState();

    const [name, setName] = useState("");
    const [preamble, setPreamble] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState<number>(0);
    const [archive, setArchive] = useState(false);
    const [timezone, setTimezone] =useState(Intl.DateTimeFormat().resolvedOptions().timeZone)

    useEffect(() => {
        getSite();
    }, []);

    const getSite = async () => {
        try {
            const response = await getSiteAPI(id);
            const site = response.data;
            setName(site.name);
            setPreamble(site.preamble);
            setAddress(site.address);
            setState(site.state);
            setCity(site.city);
            setZip(site.zip);
            setArchive(site.archive);
            setTimezone(site.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);

            if (!response.isSuccess()) {
                ToastNotification.error("Site is not fetched");
            }
        } catch (e) {
            handleException(history, e);
            ToastNotification.unknownError();
        } finally {
            setPageLoading(false);
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        editSite();
    };

    const editSite = async () => {
        setCreating(true);
        try {
            const response = await editSiteAPI({
                id: id,
                name: name,
                city: city,
                address: address,
                preamble: preamble,
                state: state,
                zip: zip,
                archive: archive,
                timezone: timezone
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Site is edited");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setCreating(false);
    };

    return (<>
        <PageLoader loading={pageLoading}/>

        {!pageLoading && (<div>
            <Row>
                <Col>
                    <PageTitle
                        items={[{
                            label: "Sites", path: "/sites",
                        }, {
                            label: "Create", active: true,
                        },]}
                        title={"Edit ".concat(name.length > 0 ? name : "'s Site")}
                    />
                </Col>
            </Row>

            <Row>
                <Col lg={6} md={8} className="mx-auto">
                    <Card>
                        <Card.Body>

                            <FormInput
                                required
                                type="text"
                                label="Site Name"
                                placeholder="Site Name"
                                value={name}
                                errors={errors}
                                onChange={(e) => setName(e.target.value)}
                                name='name'/>

                            <FormInput
                                required
                                type="text"
                                label="Preamble"
                                placeholder="Preamble"
                                value={preamble}
                                errors={errors}
                                onChange={(e) => setPreamble(e.target.value)}
                                name='preamble'/>

                            <FormInput
                                required
                                type="text"
                                label="Address"
                                placeholder="Address"
                                value={address}
                                errors={errors}
                                onChange={(e) => setAddress(e.target.value)}
                                name='address'/>

                            <FormInput
                                required
                                type="text"
                                label="City"
                                placeholder="City"
                                value={city}
                                errors={errors}
                                onChange={(e) => setCity(e.target.value)}
                                name='city'/>

                            <FormInput
                                required
                                type="text"
                                label="State"
                                placeholder="State"
                                value={state}
                                errors={errors}
                                onChange={(e) => setState(e.target.value)}
                                name='state'/>

                            <FormInput
                                required
                                type="number"
                                label="Zip"
                                placeholder="Zip"
                                value={zip}
                                errors={errors}
                                onChange={(e) => setZip(parseInt(e.target.value))}
                                name='zip'/>

                            <FormInput
                                required
                                type="timezone"
                                label="Timezone"
                                placeholder="Timezone"
                                value={timezone}
                                errors={errors}
                                onChange={(e: any) => setTimezone(e.value)}
                                name='timezone'/>

                            <Row className="mt-3 ">
                                <Col>
                                    <FormCheck
                                        type="checkbox"
                                        id="default-checkbox1"
                                        label="Archived"
                                        checked={archive}
                                        onChange={(e) => setArchive(e.target.checked)}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        type="submit"
                                        className="float-end"
                                        onClick={handleSubmit}
                                        disabled={creating}
                                    >
                                        <i
                                            className={(creating ? "mdi mdi-spin mdi-loading" : "mdi mdi-check") + " me-1"}
                                        />{" "}
                                        Update
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>)}
    </>);
};

export default EditSite;
