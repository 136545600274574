import { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    FormCheck,
    Row,
    ToggleButton,
    ToggleButtonGroup,
} from "react-bootstrap";

import { useHistory, useLocation } from "react-router-dom";
import { FormInput } from "../../components";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import DatePicker from "../../components/DatePicker";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { createClassAPI, getClassAPI } from "../../services/api/classes";
import { SessionClassModel, SessionModel } from "../../services/api/models";
import { getSessionsAPI } from "../../services/api/sessions";
import { ToastNotification } from "../../services/toast_notification";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";

const parseTimeToDate = (time: string) => {
    const today = new Date();
    const formattedTimeString = `${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${today
        .getDate()
        .toString()
        .padStart(2, "0")}T${time}Z`;
    return new Date(formattedTimeString);
};

const CreateClass = () => {
    const [notificationDays, setNotificationDays] = useState<any>([]);
    const options = [
        { label: "S", value: "sunday" },
        { label: "M", value: "monday" },
        { label: "T", value: "tuesday" },
        { label: "W", value: "wednesday" },
        { label: "T", value: "thursday" },
        { label: "F", value: "friday" },
        { label: "S", value: "saturday" },
    ];

    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    const [errors, setErrors] = useState();

    const [sessions, setSessions] = useState<SessionModel[]>();

    const [name, setName] = useState("");

    const start = new Date();
    start.setHours(9);
    start.setMinutes(0);
    start.setSeconds(0);
    const [startDate, setStartDate] = useState<Date>(start);

    const end = new Date();
    end.setHours(17);
    end.setMinutes(0);
    end.setSeconds(0);
    const [endDate, setEndDate] = useState<Date>(end);

    const feedStart = new Date();
    feedStart.setHours(18);
    feedStart.setMinutes(0);
    feedStart.setSeconds(0);
    const [feedUrlAt, setFeedUrlAt] = useState<Date>(feedStart);

    const [selectedSession, setSelectedSession] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [autoCheckoutEnable, setAutoCheckoutEnable] = useState(false);
    const [sendCheckoutNotification, setSendCheckoutNotification] =
        useState(false);
    const [sendReportTo, setSendReportTo] = useState("");

    const checkout = new Date();
    checkout.setHours(19);
    checkout.setMinutes(0);
    checkout.setSeconds(0);
    const [autoCheckoutAt, setAutoCheckoutAt] = useState(checkout);

    const [draft, setDraft] = useState(false);
    const [attendenceNotification, setAttendenceNotification] = useState(false);
    const [checkIn, setCheckIn] = useState(false);
    const [checkOut, setCheckOut] = useState(false);
    const [absent, setAbsent] = useState(false);
    const [sentAt, setSentAt] = useState(start);
    const [sendTo, setSendTo] = useState("");

    useEffect(() => {
        getSessions();
    }, []);

    const location = useLocation<{ sourceClassId?: number }>();
    useEffect(() => {
        const getClass = async (classId: number) => {
            const sourceClass = (await getClassAPI(classId)).data;
            sourceClass.send_feed_link_at &&
                setFeedUrlAt(parseTimeToDate(sourceClass.send_feed_link_at));
            const session = sourceClass.session;
            session &&
                setSelectedSession([
                    {
                        id: session.id,
                        label: `${session.name} [${session.site?.name ?? ""}]${
                            session.draft ? " - Draft" : ""
                        }`,
                    },
                ]);
            setDraft(sourceClass.draft);
            sourceClass.auto_checkout_at &&
                setAutoCheckoutAt(
                    parseTimeToDate(sourceClass.auto_checkout_at)
                );
            setAutoCheckoutEnable(sourceClass.auto_checkout);
            setSendCheckoutNotification(sourceClass.send_checkout_notification);
            setSendReportTo(sourceClass.send_report_to);
            setAttendenceNotification(sourceClass.attendance_notification);
            setNotificationDays(
                (sourceClass.attendance_send_on ?? "").split(",")
            );
            sourceClass.attendance_sent_at &&
                setSentAt(parseTimeToDate(sourceClass.attendance_sent_at));
            setCheckIn(sourceClass.attendance_checked_in);
            setCheckOut(sourceClass.attendance_checked_out);
            setAbsent(sourceClass.attendance_absent);
            setSendTo(sourceClass.attendance_to);
        };

        if (location?.state?.sourceClassId) {
            getClass(location.state.sourceClassId);
        }
    }, [location, sessions]);

    const getSessions = async () => {
        try {
            const response = await getSessionsAPI(["draft"]);
            setSessions(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    };
    const handleChange = (value: any[]) => {
        console.log("value", value);
        setNotificationDays(value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (selectedSession.length !== 1) {
            ToastNotification.error("Please select session");
            return;
        }

        createClass();
    };

    const createClass = async () => {
        let result = notificationDays.map((a: any) => a);
        console.log(result);
        let send_on_day = result.join(",");
        setCreating(true);
        try {
            const response = await createClassAPI({
                name: name,
                startDate: startDate,
                endDate: endDate,
                sendFeedUrlAt: feedUrlAt,
                sessionId: selectedSession[0].id,
                draft: draft,
                autoCheckout: autoCheckoutEnable,
                sendReportTo: sendReportTo,
                autoCheckoutAt: autoCheckoutAt,
                sendCheckoutNotification: sendCheckoutNotification,
                attendanceNotification: attendenceNotification,
                sendOn: send_on_day,
                checkedIn: checkIn,
                checkedOut: checkOut,
                absent: absent,
                sendAt: sentAt,
                sendTo: sendTo,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Class is created");
            }
        } catch (e: any) {
            console.info(e);
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.data);
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setCreating(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Classes",
                                        path: "/classes",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Create ".concat(
                                    name.length > 0 ? name : "Class"
                                )}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <FormInput
                                        required
                                        type="text"
                                        label="Class Name"
                                        placeholder="Class Name"
                                        value={name}
                                        errors={errors}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        name="name"
                                    />

                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label>Start Date</Form.Label>

                                            <DatePicker
                                                showAddon={true}
                                                showTimeSelect
                                                tI={30}
                                                errors={errors}
                                                name="start_date"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                timeCaption="time"
                                                value={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                }}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Label>End Date</Form.Label>

                                            <DatePicker
                                                showAddon={true}
                                                showTimeSelect
                                                tI={30}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                timeCaption="time"
                                                value={endDate}
                                                errors={errors}
                                                name="end_date"
                                                onChange={(date) => {
                                                    setEndDate(date);
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <Form.Label className="mt-3">
                                        Send Feed Text At
                                    </Form.Label>

                                    <DatePicker
                                        showAddon={true}
                                        showTimeSelect
                                        showTimeSelectOnly={true}
                                        tI={30}
                                        dateFormat="h:mm aa"
                                        timeCaption="time"
                                        value={feedUrlAt}
                                        errors={errors}
                                        name="send_feed_link_at"
                                        onChange={(date) => {
                                            setFeedUrlAt(date);
                                        }}
                                    />

                                    <Form.Label className="mt-3">
                                        Session
                                    </Form.Label>

                                    <CustomSelect
                                        id="sessions"
                                        multiple={false}
                                        onChange={(e) => setSelectedSession(e)}
                                        options={(sessions ?? []).map((s) => ({
                                            id: s.id,
                                            label: `${s.name} [${
                                                s.site?.name ?? ""
                                            }]${s.draft ? " - Draft" : ""}`,
                                        }))}
                                        placeholder="Select a session"
                                        selected={selectedSession}
                                    />

                                    <FormCheck
                                        className={"my-3"}
                                        type="checkbox"
                                        id="auto-checkout-checkbox"
                                        label="Enable Auto Checkout"
                                        checked={autoCheckoutEnable}
                                        onChange={(e) =>
                                            setAutoCheckoutEnable(
                                                e.target.checked
                                            )
                                        }
                                    />

                                    {autoCheckoutEnable && (
                                        <div>
                                            {/*<FormCheck*/}
                                            {/*    className={'my-3'}*/}
                                            {/*    type="checkbox"*/}
                                            {/*    id="send-checkout-notification-checkbox"*/}
                                            {/*    label="Send Checkout Notification"*/}
                                            {/*    checked={sendCheckoutNotification}*/}
                                            {/*    onChange={(e) => setSendCheckoutNotification(e.target.checked)}*/}
                                            {/*/>*/}

                                            <div
                                                className={
                                                    "d-flex align-items-center"
                                                }
                                            >
                                                <Form.Label
                                                    className={"mb-0 me-3"}
                                                >
                                                    Checkout at
                                                </Form.Label>

                                                <DatePicker
                                                    showAddon={true}
                                                    showTimeSelect
                                                    showTimeSelectOnly={true}
                                                    tI={30}
                                                    dateFormat="h:mm aa"
                                                    timeCaption="time"
                                                    value={autoCheckoutAt}
                                                    errors={errors}
                                                    name="checkout_at"
                                                    onChange={(date) => {
                                                        setAutoCheckoutAt(date);
                                                    }}
                                                />
                                            </div>

                                            <div
                                                className={
                                                    "d-flex align-items-center"
                                                }
                                            >
                                                <Form.Label
                                                    className={"mb-0 me-3"}
                                                >
                                                    Send Report To
                                                </Form.Label>

                                                <FormInput
                                                    type="email"
                                                    name="send_report_to"
                                                    onChange={(e) =>
                                                        setSendReportTo(
                                                            e.target.value
                                                        )
                                                    }
                                                    value={sendReportTo}
                                                    errors={errors}
                                                    placeholder="Email Address"
                                                    containerClass={"mt-3"}
                                                />
                                            </div>

                                            <hr
                                                style={{
                                                    margin: "8px 0px",
                                                    borderStyle: "dashed",
                                                    border: 0,
                                                    borderTop:
                                                        "1px dashed rgba(135, 135, 135, 0.81)",
                                                    opacity: 0.25,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <Row className="mt-3">
                                        <FormCheck
                                            className={"m-2"}
                                            type="checkbox"
                                            id="attendence-notification-checkbox"
                                            label="Attendance Notification"
                                            checked={attendenceNotification}
                                            onChange={(e) =>
                                                setAttendenceNotification(
                                                    e.target.checked
                                                )
                                            }
                                        />
                                        {attendenceNotification && (
                                            <div>
                                                <div
                                                    className={
                                                        "d-flex align-items-center"
                                                    }
                                                >
                                                    {" "}
                                                    <Form.Label
                                                        className={"mb-0 me-3"}
                                                    >
                                                        Send On
                                                    </Form.Label>
                                                    <ToggleButtonGroup
                                                        type="checkbox"
                                                        value={notificationDays}
                                                        onChange={handleChange}
                                                    >
                                                        {options.map(
                                                            (day, index) => (
                                                                <ToggleButton
                                                                    value={
                                                                        day.value
                                                                    }
                                                                    key={index}
                                                                    id={`tbg-check-${index}`}
                                                                    style={{
                                                                        backgroundColor:
                                                                            notificationDays.includes(
                                                                                day.value
                                                                            )
                                                                                ? "var(--ct-form-check-input-checked-bg-color)"
                                                                                : "var(--ct-primary-color)",
                                                                        color: notificationDays.includes(
                                                                            day.value
                                                                        )
                                                                            ? "white"
                                                                            : "var(--ct-primary-color)",
                                                                        border: "1px solid var(--ct-input-border-color)",
                                                                    }}
                                                                >
                                                                    {day.label}
                                                                </ToggleButton>
                                                            )
                                                        )}
                                                    </ToggleButtonGroup>
                                                </div>
                                                <Form.Label className={"mt-3"}>
                                                    Send For
                                                </Form.Label>
                                                <FormCheck
                                                    className={"my-3"}
                                                    type="checkbox"
                                                    id="check-in-checkbox"
                                                    label="Checked In"
                                                    checked={checkIn}
                                                    name="attendance-status_checkbox"
                                                    onChange={(e) =>
                                                        setCheckIn(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                                <FormCheck
                                                    className={"my-3"}
                                                    type="checkbox"
                                                    id="check-out-checkbox"
                                                    label="Checked Out"
                                                    name="attendance-status_checkbox"
                                                    checked={checkOut}
                                                    onChange={(e) =>
                                                        setCheckOut(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                                <FormCheck
                                                    className={"my-3"}
                                                    type="checkbox"
                                                    id="absent-checkbox"
                                                    label="Absent"
                                                    name="attendance-status_checkbox"
                                                    checked={absent}
                                                    onChange={(e) =>
                                                        setAbsent(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                                <div
                                                    className={
                                                        "d-flex align-items-center"
                                                    }
                                                >
                                                    <Form.Label
                                                        className={"mb-0 me-3"}
                                                    >
                                                        Send At
                                                    </Form.Label>

                                                    <DatePicker
                                                        showAddon={true}
                                                        showTimeSelect
                                                        showTimeSelectOnly={
                                                            true
                                                        }
                                                        tI={30}
                                                        dateFormat="h:mm aa"
                                                        timeCaption="time"
                                                        value={sentAt}
                                                        errors={errors}
                                                        name="Sent At"
                                                        onChange={(date) => {
                                                            setSentAt(date);
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        "d-flex align-items-center"
                                                    }
                                                >
                                                    <Form.Label
                                                        className={"mb-0 me-3"}
                                                    >
                                                        Send To
                                                    </Form.Label>
                                                    <FormInput
                                                        type="email"
                                                        name="Send to"
                                                        onChange={(e) =>
                                                            setSendTo(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={sendTo}
                                                        errors={errors}
                                                        placeholder="Email Address"
                                                        containerClass={"mt-3"}
                                                    />
                                                </div>
                                                <hr
                                                    style={{
                                                        margin: "8px 0px",
                                                        borderStyle: "dashed",
                                                        border: 0,
                                                        borderTop:
                                                            "1px dashed rgba(135, 135, 135, 0.81)",
                                                        opacity: 0.25,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Row>

                                    <Row className="mt-3 align-items-end">
                                        <Col>
                                            <FormCheck
                                                className={"mt-2"}
                                                type="checkbox"
                                                id="default-checkbox2"
                                                label="Draft"
                                                checked={draft}
                                                onChange={(e) =>
                                                    setDraft(e.target.checked)
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                type="submit"
                                                className="float-end"
                                                onClick={handleSubmit}
                                                disabled={creating}
                                            >
                                                <i
                                                    className={
                                                        (creating
                                                            ? "mdi mdi-spin mdi-loading"
                                                            : "mdi mdi-check") +
                                                        " me-1"
                                                    }
                                                />{" "}
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default CreateClass;
