import { parseException } from "../../utils/exceptions/parse_exception";
import { ChildParentModel } from "./models";
import Request from "./request";
import Response from "./response";

export const getChildParentsAPI = async () => {
    try {
        const response = await Request.getAuth("child_parents/");
        return new Response(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const createChildParentAPI = async (childParent: ChildParentModel) => {
    try {
        const parent = childParent.parent;
        if (parent == null) throw new Error("parent is not found");
        let body = {
            first_name: parent.first_name,
            middle_name: parent.middle_name,
            last_name: parent.last_name,
            phone_number: parent.phone_number,
            session_id: parent.session_id,
            is_primary: childParent.is_primary,
            receive_checkin_notification:
                childParent.receive_checkin_notification,
            receive_checkout_notification:
                childParent.receive_checkout_notification,
            receive_group_notification: childParent.receive_group_notification,
            receive_feed_notification: childParent.receive_feed_notification,
            child_id: childParent.child_id,
            family_relation_id: childParent.family_relation_id,
        };

        if (parent.avatar) {
            body = {
                ...body,
                ...{ avatar: await Request.getBase64FromUrl(parent.avatar) },
            };
        }

        const response = await Request.postAuth("child_parents/", body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const editChildParentAPI = async (
    childParent: ChildParentModel,
    updateType?: string
) => {
    try {
        const parent = childParent.parent;
        if (parent == null) throw new Error("parent is not found");
        let body = {
            first_name: parent.first_name,
            middle_name: parent.middle_name,
            last_name: parent.last_name,
            phone_number: parent.phone_number,
            is_primary: childParent.is_primary,
            session_id: parent.session_id,

            receive_checkin_notification:
                childParent.receive_checkin_notification,
            receive_checkout_notification:
                childParent.receive_checkout_notification,
            receive_group_notification: childParent.receive_group_notification,
            receive_feed_notification: childParent.receive_feed_notification,

            child_id: childParent.child_id,
            family_relation_id: childParent.family_relation_id,
            updation_type: updateType,
        };

        if (parent.avatar) {
            body = {
                ...body,
                ...{ avatar: await Request.getBase64FromUrl(parent.avatar) },
            };
        }

        const response = await Request.putAuth(
            `child_parents/${childParent.id}/`,
            body
        );
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const getChildParentAPI = async (id: number) => {
    try {
        const response = await Request.getAuth(`child_parents/${id}`);
        return new Response<ChildParentModel>(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const deleteChildParentsAPI = async (
    childParentId: string | undefined
) => {
    try {
        const response = await Request.deleteAuth(
            `child_parents/${childParentId}/`
        );
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};
