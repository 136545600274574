import {
    ChildModel,
    FamilyRelationModel,
    GroupModel,
    SessionClassModel,
    SessionModel,
    UserModel,
} from "../services/api/models";

export const sessionToCustomOption = (s: SessionModel) => ({
    id: s.id,
    label: `${s.site?.name ?? ""} [${s.name}]`,
});

export const classToCustomOption = (c: SessionClassModel) => ({
    id: c.id,
    label: `${c.name}${c.draft ? " [Draft]" : ""}`,
});

export const groupToCustomOption = (g: GroupModel) => ({
    id: g.id,
    label: `${g.name}${g.draft ? " [Draft]" : ""}`,
});

export const childToCustomOption = (c: ChildModel) => ({
    id: c.id,
    label: `${c.first_name} ${c.last_name}`,
});

export const familyRelaionToCustomOption = (f: FamilyRelationModel) => ({
    id: f.id,
    label: f.kind,
});

export const sessionClassToCustomOption = (c: SessionClassModel) => ({
    id: c.id,
    label: `${c.name} - ${c.session?.name} - [ ${c.session?.site?.name} ]  ${
        c.draft ? "[Draft]" : ""
    }`,
});

export const userToCustomOption = (c: UserModel) => ({
    id: c.id,
    label: `${c.first_name} ${c.last_name}`,
});
