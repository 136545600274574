import { useEffect, useState } from "react";
import { Button, Card, Col, Form, FormCheck, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import FileUploader, { FileType } from "../../components/FileUploader";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { editChildAPI, getChildAPI } from "../../services/api/children";
import { getGroupsAPI } from "../../services/api/groups";
import { GroupModel } from "../../services/api/models";
import Request from "../../services/api/request";
import { ToastNotification } from "../../services/toast_notification";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";
import { groupToCustomOption } from "../utils";

const EditChild = (props: any) => {
    const { id } = props.match.params;
    const history = useHistory();
    const state = props.location.state;

    const [groups, setGroups] = useState<GroupModel[]>([]);

    const [errors, setErrors] = useState();
    const [pageLoading, setPageLoading] = useState(true);
    const [updating, setUpdating] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");

    const [lastName, setLastName] = useState("");
    const [medicalCondition, setMedicalCondition] = useState("");
    const [approvedPickup, setApprovedPickup] = useState("");
    const [pronouns, setPronouns] = useState("");
    const [notes, setNotes] = useState("");
    const [selectedGroups, setSelectedGroups] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [archive, setArchive] = useState(false);

    const [files, setFiles] = useState<FileType[]>([]);
    const [avatar, setAvatar] = useState<FileType | null>(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await getChildAPI(id);
            const child = response.data;
            setFirstName(child.first_name);
            setMiddleName(child.middle_name);
            setLastName(child.last_name);
            setMedicalCondition(child.medical_condition ?? "");
            setPronouns(child.pronouns ?? "");
            setNotes(child.notes ?? "");
            setApprovedPickup(child.approved_pickup ?? "");
            setSelectedGroups(
                (child.group ? [child.group] : child.groups ?? []).map(
                    groupToCustomOption
                )
            );
            setArchive(child.archive);
            const avatar = child.avatar;
            if (avatar != null) {
                setFiles([
                    {
                        preview: Request.getImageUrl(avatar.toString()),
                        name: "Uploaded",
                        status: "uploaded",
                    },
                ]);
            }
            if (state?.session_class?.groups) {
                setGroups(
                    state.session_class.groups?.filter(
                        (g: GroupModel) => !g.archive
                    )
                );
            } else {
                try {
                    const classResponse = await getGroupsAPI();
                    setGroups(
                        classResponse.data?.filter(
                            (g: GroupModel) => !g.archive
                        )
                    );
                } catch (e) {
                    ToastNotification.unknownError();
                    handleException(history, e);
                }
            }
        } catch (e) {
            handleException(history, e);
            ToastNotification.unknownError();
        } finally {
            setPageLoading(false);
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        updateChild();
    };

    const updateChild = async () => {
        setUpdating(true);
        try {
            const response = await editChildAPI({
                id: id,
                first_name: firstName,
                last_name: lastName,
                middle_name: middleName,
                medical_condition: medicalCondition,
                avatar: avatar != null ? avatar.preview : undefined,
                groups: groups.filter((g) =>
                    selectedGroups.find((s) => s.id === g.id)
                ),
                notes: notes,
                pronouns: pronouns,
                approved_pickup: approvedPickup,
                archive: archive,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Child is edited");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setUpdating(false);
    };

    const handleFileUpload = (uploadedFiles: FileType[]) => {
        if (uploadedFiles.length > 0) {
            setAvatar(uploadedFiles[0]);
        }
    };

    const handleFileRemoved = (uploadedFiles: FileType[]) => {
        if (uploadedFiles.length > 0) {
            if (uploadedFiles[0].status === "uploaded") {
            } else {
                setFiles([]);
            }
        }
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Children",
                                        path: "/children",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Create ".concat(
                                    firstName.length > 0 && lastName.length > 0
                                        ? firstName
                                              .concat(" ")
                                              .concat(lastName)
                                              .concat("'s Profile")
                                        : "Child's Profile"
                                )}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col lg={4}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="First Name"
                                                placeholder="First Name"
                                                value={firstName}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                                name="first_name"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="Middle Name"
                                                placeholder="Middle Name"
                                                value={middleName}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setMiddleName(
                                                        e.target.value
                                                    )
                                                }
                                                name="middle_name"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="Last Name"
                                                placeholder="Last Name"
                                                value={lastName}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setLastName(e.target.value)
                                                }
                                                name="last_name"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label>
                                                Profile Image
                                            </Form.Label>
                                            <FileUploader
                                                onFileUpload={handleFileUpload}
                                                files={files}
                                                onFileRemoved={
                                                    handleFileRemoved
                                                }
                                                maxFiles={1}
                                            />

                                            <Form.Label className="mt-3">
                                                Select Groups
                                            </Form.Label>

                                            <CustomSelect
                                                id="user-select"
                                                multiple={true}
                                                emptyLabel={"There is no group"}
                                                onChange={setSelectedGroups}
                                                options={groups.map(
                                                    groupToCustomOption
                                                )}
                                                placeholder="Select a groups"
                                                selected={selectedGroups}
                                            />

                                            <FormCheck
                                                type="checkbox"
                                                className="mt-3"
                                                id="default-checkbox1"
                                                label="Archived"
                                                checked={archive}
                                                onChange={(e) =>
                                                    setArchive(e.target.checked)
                                                }
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <FormInput
                                                required
                                                type="textarea"
                                                label="Medical Condition"
                                                placeholder="Medical Condition"
                                                value={medicalCondition}
                                                errors={errors}
                                                rows="2"
                                                onChange={(e) =>
                                                    setMedicalCondition(
                                                        e.target.value
                                                    )
                                                }
                                                name="medical_condition"
                                            />

                                            <FormInput
                                                required
                                                type="textarea"
                                                label="Approved Pickup"
                                                placeholder="Approved Pickup"
                                                value={approvedPickup}
                                                errors={errors}
                                                rows="2"
                                                onChange={(e) =>
                                                    setApprovedPickup(
                                                        e.target.value
                                                    )
                                                }
                                                name="approved_pickup"
                                            />

                                            <FormInput
                                                required
                                                label="Notes"
                                                placeholder="Notes"
                                                value={notes}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setNotes(e.target.value)
                                                }
                                                name="notes"
                                            />

                                            <FormInput
                                                required
                                                label="Pronouns"
                                                placeholder="Pronouns"
                                                value={pronouns}
                                                errors={errors}
                                                rows="1"
                                                onChange={(e) =>
                                                    setPronouns(e.target.value)
                                                }
                                                name="pronouns"
                                            />
                                        </Col>
                                    </Row>

                                    <Button
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="float-end"
                                    >
                                        <i
                                            className={
                                                (updating
                                                    ? "mdi mdi-spin mdi-loading"
                                                    : "mdi mdi-check") + " me-1"
                                            }
                                        />{" "}
                                        Update
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default EditChild;
