import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import PlaceHolderImage from "../../components/PlaceholderImage";
import Table from "../../components/Table";
import { getChildParentsAPI } from "../../services/api/child_parents";
import Request from "../../services/api/request";
import { handleException } from "../../utils/exceptions/handle_exception";

// components

const ChildParentsList = () => {
    const [childParents, setChildParents] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getChildParentsList();
    }, []);

    async function getChildParentsList() {
        setPageLoading(true);
        try {
            const response = await getChildParentsAPI();
            setChildParents(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    const columns = [
        {
            Header: "ID",
            accessor: "id",
            sort: true,
            maxWidth: 40,
        },
        {
            Header: "Name",
            accessor: "first_name",
            sort: true,
            Cell: (data: any) => {
                data = data.row.original;

                function getAvatar() {
                    let avatar = data.avatar;

                    if (avatar != null) {
                        return PlaceHolderImage({
                            src: Request.getImageUrl(avatar),
                            rounded: true,
                            size: 36,
                        });
                    }
                    return PlaceHolderImage({
                        rounded: true,
                        size: 36,
                    });
                }

                return (
                    <div className="d-flex align-items-center">
                        {getAvatar()}
                        <span className="ms-2 cursor-pointer">
                            {data.first_name + " " + data.last_name}
                        </span>
                    </div>
                );
            },
        },
        {
            Header: "Phone Number",
            accessor: "phone_number",
            sort: true,
        },
        {
            Header: "Edit",
            accessor: "id1",
            maxWidth: 60,
            Cell: (data: any) => {
                let id = data.row.allCells[0].value;
                return (
                    <Link to={`/child_parents/${id}`}>
                        <i className="mdi mdi-pencil-outline"></i>
                    </Link>
                );
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: childParents.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Parents",
                                        active: true,
                                    },
                                ]}
                                title={"Child Parents"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Table
                                        id="child-parents"
                                        columns={columns}
                                        data={childParents}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={true}
                                        tableClass={"table-striped"}
                                        actions={
                                            <Link
                                                to="/child_parents/create"
                                                className="btn btn-primary"
                                            >
                                                <i className="mdi mdi-plus-circle  me-1"></i>
                                                Add Parent
                                            </Link>
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default ChildParentsList;
