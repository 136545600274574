import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import { getChildrenAPI } from "../../services/api/children";
import Table from "../../components/Table";
import PlaceHolderImage from "../../components/PlaceholderImage";
import { Link, useHistory } from "react-router-dom";
import Request from "../../services/api/request";
import PageLoader from "../../components/PageLoader";
import { handleException } from "../../utils/exceptions/handle_exception";
import { ChildModel } from "../../services/api/models";
import PageTitle from "../../components/PageTitle";

// components

const ChildrenList = () => {
    const [children, setChildren] = useState<ChildModel[]>([]);
    const [pageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getChildrenList();
    }, []);

    async function getChildrenList() {
        setPageLoading(true);
        try {
            const response = await getChildrenAPI();
            setChildren(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    const columns = [
        {
            Header: "ID",
            accessor: "id",
            sort: true,
            maxWidth: 40,
        },
        {
            Header: "Name",
            accessor: "first_name",
            sort: true,
            Cell: (data: any) => {
                data = data.row.original;

                function getAvatar() {
                    let avatar = data.avatar;

                    return PlaceHolderImage({
                        src: Request.getImageUrlOrNull(avatar),
                        rounded: true,
                        size: 36,
                    });
                    // return PlaceHolderImage({
                    //     rounded: true,
                    //     size: 36,
                    // });
                }

                return (
                    <div className="d-flex align-items-center">
                        {getAvatar()}
                        <span className="ms-2 cursor-pointer">
                            {data.first_name + " " + data.last_name}
                        </span>
                    </div>
                );
            },
        },
        {
            Header: "Medical Condition",
            accessor: "medical_condition",
            maxWidth: 120,
            Cell: (data: any) => {
                let value = data.cell.value;

                return (
                    <span style={{ whiteSpace: "pre-wrap" }}>
                        {value ?? "-"}
                    </span>
                );
            },
        },

        {
            Header: "Edit",
            accessor: "id1",
            maxWidth: 60,
            Cell: (data: any) => {
                let id = data.row.allCells[0].value;
                return (
                    <Link to={`/children/${id}`}>
                        <i className="mdi mdi-pencil-outline"></i>
                    </Link>
                );
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: children.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Children",
                                        active: true,
                                    },
                                ]}
                                title={"Children"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Table
                                        id="children"
                                        columns={columns}
                                        data={children}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={true}
                                        tableClass={"table-striped"}
                                        actions={
                                            <Link
                                                to="/children/create"
                                                className="btn btn-primary"
                                            >
                                                <i className="mdi mdi-plus-circle  me-1"></i>
                                                Add Child
                                            </Link>
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default ChildrenList;
