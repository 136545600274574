const useStorage = ({ key }: { key: string }) => {
    const save = ({ data }: { data: Record<string, unknown> }) => {
        sessionStorage.setItem(key, JSON.stringify(data));
    };

    const get = () => {
        return JSON.parse(sessionStorage.getItem(key) ?? "{}");
    };

    return { save, get };
};

export default useStorage;
