import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import DatePicker from "../../components/DatePicker";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { editCheckinAPI, getCheckInAPI } from "../../services/api/check_ins";
import {
    CheckinModel,
    ChildModel,
    GroupModel,
} from "../../services/api/models";
import { ToastNotification } from "../../services/toast_notification";
import { handleException } from "../../utils/exceptions/handle_exception";
import { childToCustomOption, groupToCustomOption } from "../utils";

const EditCheckIn = (props: any) => {
    const { id } = props.match.params;

    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(true);
    const [editing, setEditing] = useState(false);

    const [groups, setGroups] = useState<GroupModel[]>([]);
    const [children, setChildren] = useState<ChildModel[]>([]);

    const [checkin, setCheckin] = useState<CheckinModel>();
    const [checkInTime, setCheckInTime] = useState<Date>(new Date());
    const [checkOutTime, setCheckOutTime] = useState<Date>();

    const [selectedGroup, setSelectedGroup] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [selectedChild, setSelectedChild] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [validated, setValidated] = useState<boolean>(false);

    useEffect(() => {
        getCheckIn();
    }, []);

    const getCheckIn = async () => {
        try {
            const response = await getCheckInAPI(id);
            const checkin = response.data;
            setCheckin(checkin);
            setGroups(response.data.group != null ? [response.data.group] : []);
            setChildren(
                response.data.child != null ? [response.data.child] : []
            );
            setSelectedGroup(
                response.data.group != null
                    ? [groupToCustomOption(response.data.group)]
                    : []
            );
            setSelectedChild(
                response.data.child != null
                    ? [childToCustomOption(response.data.child)]
                    : []
            );
            setCheckInTime(new Date(checkin.checkin));
            setCheckOutTime(
                checkin.checkout != null
                    ? new Date(checkin.checkout)
                    : undefined
            );
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);

        if (selectedGroup.length === 0 || selectedChild.length === 0) {
            ToastNotification.success("Select group & child for checkin");
            return;
        }

        editCheckin();
    };

    const editCheckin = async (checkOut?: Date) => {
        setEditing(true);
        try {
            const response = await editCheckinAPI(
                {
                    id: checkin?.id ?? -1,
                    checkin: checkInTime,
                    child_id: selectedChild[0].id,
                    group_id: selectedGroup[0].id,
                    checkout: checkOut ?? checkOutTime,
                },
                checkOut != null ? "check_out" : undefined
            );
            if (response.isSuccess()) {
                history.goBack();
                if (checkOutTime != null) {
                    ToastNotification.success("Child Checked Out");
                } else {
                    ToastNotification.success("Checkout edited");
                }
            }
        } catch (e) {
            handleException(history, e);
            ToastNotification.unknownError();
        }
        setEditing(false);
    };

    const checkOutNow = async () => {
        editCheckin(new Date());
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Check Ins",
                                        path: "/check_ins",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={`Edit ${
                                    selectedChild[0].label ?? ""
                                } Checkin`}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <Form
                                        noValidate
                                        validated={validated}
                                        onSubmit={handleSubmit}
                                    >
                                        <Form.Label className="mt-3">
                                            Group
                                        </Form.Label>

                                        <CustomSelect
                                            id="select2"
                                            multiple={false}
                                            onChange={(e) => {
                                                setSelectedGroup(e);
                                                const group = groups.find(
                                                    ({ id }) =>
                                                        id === e?.[0]?.id
                                                );
                                                setChildren(
                                                    group?.children ?? []
                                                );
                                            }}
                                            options={groups.map(
                                                groupToCustomOption
                                            )}
                                            placeholder="Select a group"
                                            selected={selectedGroup}
                                            disabled
                                        />

                                        <Form.Label className="mt-3">
                                            Child
                                        </Form.Label>

                                        <CustomSelect
                                            id="children"
                                            multiple={false}
                                            onChange={(e) =>
                                                setSelectedChild(e)
                                            }
                                            options={children.map(
                                                childToCustomOption
                                            )}
                                            placeholder="Select a child"
                                            selected={selectedChild}
                                            emptyLabel={
                                                "Please select group first"
                                            }
                                            disabled
                                        />

                                        <Form.Label className="mt-3">
                                            Checkin Time
                                        </Form.Label>

                                        <DatePicker
                                            showAddon={true}
                                            showTimeSelect
                                            tI={1}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            timeCaption="time"
                                            value={checkInTime}
                                            onChange={(date) => {
                                                setCheckInTime(date);
                                            }}
                                        />

                                        <Form.Label className="mt-3">
                                            Checkout Time
                                        </Form.Label>

                                        <DatePicker
                                            showAddon={true}
                                            showTimeSelect
                                            tI={1}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            timeCaption="time"
                                            value={checkOutTime}
                                            onChange={(date) => {
                                                setCheckOutTime(date);
                                            }}
                                        />

                                        <Row className="mt-3 ">
                                            <Col>
                                                <div className="float-end">
                                                    <Button
                                                        onClick={checkOutNow}
                                                        className="me-2 btn-danger"
                                                        disabled={editing}
                                                    >
                                                        <i
                                                            className={
                                                                (editing
                                                                    ? "mdi mdi-spin mdi-loading"
                                                                    : "mdi mdi-exit-to-app") +
                                                                " me-1"
                                                            }
                                                        />{" "}
                                                        Checkout Now
                                                    </Button>

                                                    <Button
                                                        type="submit"
                                                        disabled={editing}
                                                    >
                                                        <i
                                                            className={
                                                                (editing
                                                                    ? "mdi mdi-spin mdi-loading"
                                                                    : "mdi mdi-check") +
                                                                " me-1"
                                                            }
                                                        />{" "}
                                                        Update
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default EditCheckIn;
