import { useEffect, useState } from "react";
import { Button, Card, Col, Form, FormCheck, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import DatePicker from "../../components/DatePicker";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { SessionModel } from "../../services/api/models";
import { editSessionAPI, getSessionAPI } from "../../services/api/sessions";
import { getSitesAPI } from "../../services/api/sites";
import { ToastNotification } from "../../services/toast_notification";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";
import { siteToCustomSelectOption } from "../users/utils";

const EditSession = (props: any) => {
    const { id } = props.match.params;

    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [errors, setErrors] = useState();

    const [sites, setSites] = useState<any>();

    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [selectedSite, setSelectedSite] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [archive, setArchive] = useState(false);
    const [draft, setDraft] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setPageLoading(true);
        try {
            const response = await getSessionAPI(id);
            const session: SessionModel = response.data;
            setName(session.name);

            setStartDate(new Date(session.start_date));
            setEndDate(new Date(session.end_date));
            setSelectedSite(
                session.site != null
                    ? [siteToCustomSelectOption(session.site)]
                    : []
            );
            setArchive(session.archive);
            setDraft(session.draft);

            const siteResponse = await getSitesAPI();
            setSites(siteResponse.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (selectedSite.length !== 1) {
            ToastNotification.error("Please select site");
            return;
        }

        updateSession();
    };

    const updateSession = async () => {
        setUpdating(true);
        try {
            const response = await editSessionAPI(
                id,
                name,
                startDate,
                endDate,
                selectedSite[0].id,
                archive,
                draft
            );
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Session is edited");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setUpdating(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Sessions",
                                        path: "/sessions",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Edit ".concat(
                                    name.length > 0 ? name : "Session"
                                )}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <FormInput
                                        required
                                        type="text"
                                        label="Session Name"
                                        placeholder="Session Name"
                                        value={name}
                                        errors={errors}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        name="name"
                                    />

                                    <Form.Label>Start Date</Form.Label>

                                    <DatePicker
                                        showAddon={true}
                                        errors={errors}
                                        name="start_date"
                                        dateFormat="MMMM d, yyyy"
                                        timeCaption="time"
                                        value={startDate}
                                        onChange={(date) => {
                                            setStartDate(date);
                                        }}
                                    />

                                    <Form.Label className="mt-3">
                                        End Date
                                    </Form.Label>

                                    <DatePicker
                                        showAddon={true}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        tI={1}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        timeCaption="time"
                                        value={endDate}
                                        errors={errors}
                                        name="end_date"
                                        onChange={(date) => {
                                            setEndDate(date);
                                        }}
                                    />

                                    <Form.Label className="mt-3">
                                        Site
                                    </Form.Label>

                                    <CustomSelect
                                        id="select2"
                                        multiple={false}
                                        onChange={(e) => setSelectedSite(e)}
                                        options={sites.map(
                                            siteToCustomSelectOption
                                        )}
                                        placeholder="Select a site"
                                        selected={selectedSite}
                                    />

                                    <Row className="mt-3 ">
                                        <Col>
                                            <Form.Check
                                                type="checkbox"
                                                id="default-checkbox1"
                                                label="Archived"
                                                checked={archive}
                                                onChange={(e) =>
                                                    setArchive(e.target.checked)
                                                }
                                            />

                                            <FormCheck
                                                className={"mt-2"}
                                                type="checkbox"
                                                id="default-checkbox2"
                                                label="Draft"
                                                checked={draft}
                                                onChange={(e) =>
                                                    setDraft(e.target.checked)
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                type="submit"
                                                className="float-end"
                                                onClick={handleSubmit}
                                                disabled={updating}
                                            >
                                                <i
                                                    className={
                                                        (updating
                                                            ? "mdi mdi-spin mdi-loading"
                                                            : "mdi mdi-check") +
                                                        " me-1"
                                                    }
                                                />{" "}
                                                Update
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default EditSession;
