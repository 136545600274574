import {ResponseCode} from "../../services/api/response";

export class BaseException {
    public status: number;
    public data: any;

    constructor(data: any, status: number) {
        this.status = status;
        this.data = data;
    }

    isFormDataError(){
        return this.status === ResponseCode.unprocessable_entity;
    }

    getErrors(){
        return this.data.error;
    }
}
