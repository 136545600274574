import Request from "./request";
import Response from "./response";
import {parseException} from "../../utils/exceptions/parse_exception";
import {GroupModel} from "./models";

export const getGroupsAPI = async (statuses?: string[]) => {
    try {
        let url = "groups/" + (statuses != null ? '?statuses=' + statuses : '')

        const response = await Request.getAuth(url);
        return new Response<GroupModel[]>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const createGroupAPI = async (group: Omit<GroupModel, 'archive' | 'id'>) => {
    try {
        const childrenIds = (group.children ?? []).map((child) => child.id);
        const userIds = (group.users ?? []).map((user) => user.id);
        let body = {
            session_class_id: group.session_class_id,
            children_ids: childrenIds,
            user_ids: userIds,
            name: group.name,
            draft: group.draft
        };

        const response = await Request.postAuth("groups/", body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const editGroupAPI = async (group: GroupModel) => {
    try {
        const childrenIds = (group.children ?? []).map((child) => child.id);
        const userIds = (group.users ?? []).map((user) => user.id);
        let body = {
            session_class_id: group.session_class_id,
            children_ids: childrenIds,
            user_ids: userIds,
            name: group.name,
            archive: group.archive,
            draft: group.draft
        };


        const response = await Request.patchAuth(`groups/${group.id}`, body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const getGroupAPI = async (id: number) => {
    try {
        const response = await Request.getAuth(`groups/${id}`);
        return new Response<GroupModel>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};
