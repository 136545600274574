import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import { getGroupsAPI } from "../../services/api/groups";
import { GroupModel, statuses } from "../../services/api/models";
import { Permission } from "../../services/permission";
import { handleException } from "../../utils/exceptions/handle_exception";
import { capitalize } from "../../utils/strings";

const GroupList = () => {
    const [groups, setGroups] = useState<GroupModel[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<
        CustomSelectOption<string>[]
    >([{ id: "active", label: "Active" }]);

    const [pageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getGroupList();
    }, []);

    async function getGroupList(statuses?: string[]) {
        setPageLoading(true);
        try {
            const response = await getGroupsAPI(statuses);
            setGroups(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    function changeStatus(e: CustomSelectOption<string>[]) {
        setSelectedStatus(e);
        getGroupList(e.map(({ id }) => id));
    }

    const columns = [
        {
            Header: "ID",
            accessor: "id",
            sort: true,
            maxWidth: 60,
        },
        {
            Header: "Name",
            accessor: "name",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value}</span>;
            },
        },
        {
            Header: "Class",
            accessor: "session_class",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value.name}</span>;
            },
        },
        {
            Header: "Session",
            accessor: "session",
            sort: true,
            Cell: (data: any) => {
                return (
                    <span>{data.row.original.session_class.session.name}</span>
                );
            },
        },
        {
            Header: "Site",
            accessor: "",
            sort: true,
            Cell: (data: any) => {
                return (
                    <span>
                        {data.row.original.session_class.session.site.name}
                    </span>
                );
            },
        },
        {
            Header: "Status",
            accessor: "status",
            sort: true,
            Cell: (data: any) => {
                let draft = data.row.original.draft;
                let archive = data.row.original.archive;
                if (archive) {
                    return (
                        <span>
                            <small className="mdi mdi-circle text-danger fs-6" />{" "}
                            Archived
                        </span>
                    );
                } else if (draft) {
                    return (
                        <span>
                            <small className="mdi mdi-circle text-secondary fs-6" />{" "}
                            Draft
                        </span>
                    );
                }
                return (
                    <span>
                        <small className="mdi mdi-circle text-success fs-6" />{" "}
                        Active
                    </span>
                );
            },
        },
        {
            Header: "Edit",
            accessor: "id1",
            maxWidth: 60,
            Cell: (data: any) => {
                let id = data.row.allCells[0].value;
                return (
                    Permission.canEditGroup() && (
                        <Link to={`/groups/${id}`}>
                            <i className="mdi mdi-pencil-outline"></i>
                        </Link>
                    )
                );
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: groups.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Groups",
                                        active: true,
                                    },
                                ]}
                                title="Groups"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Table
                                        id="groups"
                                        columns={columns}
                                        data={groups}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={true}
                                        tableClass={"table-striped"}
                                        filters={
                                            <CustomSelect
                                                id="status-select"
                                                multiple={true}
                                                emptyLabel={
                                                    "There is no status"
                                                }
                                                onChange={(e) => {
                                                    changeStatus(e);
                                                }}
                                                options={statuses.map((s) => ({
                                                    id: s,
                                                    label: capitalize(s),
                                                }))}
                                                placeholder="Select a status"
                                                selected={selectedStatus}
                                            />
                                        }
                                        actions={
                                            Permission.canCreateGroup() && (
                                                <Link
                                                    to="/groups/create"
                                                    className="btn btn-primary"
                                                >
                                                    <i className="mdi mdi-plus-circle  me-1"></i>
                                                    Add Group
                                                </Link>
                                            )
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default GroupList;
