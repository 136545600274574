import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import FileUploader, { FileType } from "../../components/FileUploader";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import ValidationFeedback from "../../components/ValidationFeedback";
import { getGroupsAPI } from "../../services/api/groups";
import {
    GroupModel,
    PhoneNumberModel,
    SiteModel,
} from "../../services/api/models";
import { getPhoneNumbersAPI } from "../../services/api/phone_number";
import Request from "../../services/api/request";
import { getSitesAPI } from "../../services/api/sites";
import { editUserAPI, getUserAPI } from "../../services/api/user";
import { ToastNotification } from "../../services/toast_notification";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";
import { roleOptions, UserRoles } from "./types";
import {
    groupToCustomSelectOption,
    phoneToCustomSelectOption,
    roleToCustomSelectOption,
    siteToCustomSelectOption,
} from "./utils";

const EditUser = (props: any) => {
    const { id } = props.match.params;
    const [groups, setGroups] = useState<GroupModel[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [filterGroup, setFilterGroup] = useState<GroupModel[]>([]);
    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [errors, setErrors] = useState();

    const [sites, setSites] = useState<SiteModel[]>([]);
    const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberModel[]>([]);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const [avatar, setAvatar] = useState<FileType | null>(null);

    const [files, setFiles] = useState<FileType[]>([]);

    const handleFileUpload = (files: FileType[]) => {
        if (files.length > 0) setAvatar(files[files.length - 1]);
    };

    const [selectedSite, setSelectedSite] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [selectedRole, setSelectedRole] = useState<
        CustomSelectOption<string>[]
    >([]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setPageLoading(true);
        try {
            const userResponse = await getUserAPI(id);
            const user = userResponse.data;

            const siteResponse = await getSitesAPI();
            setSites(siteResponse.data);
            const phoneNumberResponse = await getPhoneNumbersAPI();
            setPhoneNumbers(phoneNumberResponse.data);
            const groupResponse = await getGroupsAPI();
            setGroups(groupResponse.data);
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setUsername(user.username);
            setEmail(user.email);
            setSelectedSite(
                user.site != null ? [siteToCustomSelectOption(user.site)] : []
            );
            setSelectedRole(
                user.role != null
                    ? [
                          roleToCustomSelectOption({
                              name: user.role,
                              label: UserRoles[user.role],
                          }),
                      ]
                    : []
            );
            setSelectedPhoneNumber(
                user.phone_number != null
                    ? [phoneToCustomSelectOption(user.phone_number)]
                    : []
            );
            setSelectedGroup(user.group.map(groupToCustomSelectOption));
            const avatar = user.avatar;
            if (avatar != null) {
                setFiles([
                    {
                        preview: Request.getImageUrl(avatar.toString()),
                        name: "Uploaded",
                        status: "uploaded",
                    },
                ]);
            }
        } catch (e) {
            handleException(history, e);

            ToastNotification.unknownError();
        }
        setPageLoading(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (selectedRole.length === 0) {
            ToastNotification.error("Please select a role");
            return;
        }

        updateUser();
    };

    const updateUser = async () => {
        setUpdating(true);
        try {
            const response = await editUserAPI({
                email: email,
                first_name: firstName,
                id: id,
                last_name: lastName,
                avatar: avatar?.preview,
                username: username,
                role: selectedRole[0].id,
                site_id:
                    selectedSite.length > 0 ? selectedSite[0].id : undefined,
                phone_number_id:
                    selectedPhoneNumber.length > 0
                        ? selectedPhoneNumber[0].id
                        : undefined,
                password: password,
                group: selectedGroup,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("User is edited");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setUpdating(false);
    };
    if (selectedSite.length > 0) {
        let f_group = groups.filter(
            (group) =>
                group?.session_class?.session?.site?.name ===
                selectedSite?.[0].label
        );
        setTimeout(() => setFilterGroup(f_group), 100);

        /*
        the function used to test each element of the array. It takes one parameter called group, which is each object in the groups array.

group?.session_class?.session?.site?.name is a chained property access that tries to access the name property of the site object inside the session object inside the session_class object inside the group object. The ?. operator is used to avoid throwing an error if any of the nested objects is null or undefined.

selectedSite?.[0].name tries to access the name property of the first element of the selectedSite array, if it exists. The ?. operator is used to avoid throwing an error if the selectedSite array is null or undefined.

Therefore, the condition group?.session_class?.session?.site?.name===selectedSite?.[0].name is checking if the name property of the site object inside the session object inside the session_class object inside the group object is equal to the name property of the first element of the selectedSite array, if it exists.

The filtered array is then assigned to the f_group variable, which contains only the objects in the groups array that meet the condition specified in the filter function.
        */
    }

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Users",
                                        path: "/users",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Edit ".concat(
                                    username.length > 0 ? username : "User"
                                )}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="First Name"
                                                placeholder="First Name"
                                                value={firstName}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                                name="first_name"
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="Last Name"
                                                placeholder="Last Name"
                                                value={lastName}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setLastName(e.target.value)
                                                }
                                                name="last_name"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="Username"
                                                placeholder="Username"
                                                value={username}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setUsername(e.target.value)
                                                }
                                                name="username"
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <FormInput
                                                required
                                                type="password"
                                                label="Password"
                                                value={password}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                                name="password"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6}>
                                            <FormInput
                                                required
                                                type="email"
                                                label="Email"
                                                placeholder="Email"
                                                value={email}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                name="email"
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Label>Sites</Form.Label>

                                            <CustomSelect
                                                id="sites"
                                                multiple={false}
                                                onChange={(e) =>
                                                    setSelectedSite(e)
                                                }
                                                options={sites.map(
                                                    siteToCustomSelectOption
                                                )}
                                                placeholder="Select a site"
                                                selected={selectedSite}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label>
                                                Profile Image
                                            </Form.Label>
                                            <FileUploader
                                                onFileUpload={handleFileUpload}
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <Form.Label>
                                                Phone numbers
                                            </Form.Label>

                                            <CustomSelect
                                                id="phones"
                                                multiple={false}
                                                onChange={(e) =>
                                                    setSelectedPhoneNumber(e)
                                                }
                                                options={phoneNumbers.map(
                                                    phoneToCustomSelectOption
                                                )}
                                                placeholder="Select a phone number"
                                                selected={selectedPhoneNumber}
                                            />

                                            <Form.Label className="mt-3">
                                                Select Role
                                            </Form.Label>

                                            <CustomSelect
                                                id="roles"
                                                multiple={false}
                                                onChange={setSelectedRole}
                                                options={roleOptions}
                                                placeholder="Select a role"
                                                selected={selectedRole}
                                            />

                                            <Form.Label className="mt-3">
                                                Select Group
                                            </Form.Label>

                                            <CustomSelect
                                                id="groups"
                                                multiple={true}
                                                onChange={setSelectedGroup}
                                                options={filterGroup.map(
                                                    groupToCustomSelectOption
                                                )}
                                                placeholder="Select a group"
                                                selected={selectedGroup}
                                            />
                                            <ValidationFeedback
                                                errors={errors}
                                                name="site_id"
                                            />
                                        </Col>
                                    </Row>

                                    <Button
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="float-end mt-3"
                                    >
                                        <i
                                            className={
                                                (updating
                                                    ? "mdi mdi-spin mdi-loading"
                                                    : "mdi mdi-check") + " me-1"
                                            }
                                        />{" "}
                                        Update
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default EditUser;
