import Request from "./request";
import Response from "./response";
import {parseException} from "../../utils/exceptions/parse_exception";
import {SessionClassModel, SessionModel} from "./models";
import {pushAllUniqueToArray} from "../../utils";

export const getSessionsAPI = async (statuses?: string[]) => {
    try {
        let url = "sessions/" + (statuses != null ? '?statuses=' + statuses : '')
        const response = await Request.getAuth(url);
        return new Response(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const getSessionsForCheckinAPI = async () => {
    try {
        const response = await Request.getAuth("sessions_to_children/");
        return new Response(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const createSessionAPI = async (name: string, startDate: Date, endDate: Date, draft: boolean, siteId?: number,) => {
    try {
        let body = {
            name: name, start_date: startDate, end_date: endDate, site_id: siteId, draft: draft
        };

        const response = await Request.postAuth("sessions/", body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const editSessionAPI = async (id: number, name: string, startDate: Date, endDate: Date, siteId: number, archive: boolean, draft: boolean,) => {
    try {
        let body = {
            name: name, start_date: startDate, end_date: endDate, site_id: siteId, archive: archive, draft: draft
        };

        const response = await Request.patchAuth(`sessions/${id}/`, body);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export const getSessionAPI = async (id: number) => {
    try {
        const response = await Request.getAuth(`sessions/${id}`);
        return new Response<SessionModel>(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};


export const getAllClassesFromSessions = (sessions: SessionModel[]) => {
    let sessionClasses: SessionClassModel[] = [];
    for (const session of sessions) {
        if (session.classes != null) sessionClasses = pushAllUniqueToArray(sessionClasses, session.classes)
    }
    return sessionClasses;
}
