import LocalStorage from "../../services/local_storage";
import { ResponseCode } from "../../services/api/response";

export function handleException(history: any, e: any) {
    if (e.status === ResponseCode.unauthorized) {
        LocalStorage.clear();
        history.push(`/auth/login`);
    } else if (e.status === ResponseCode.forbidden) {
        history.push(`/forbidden`);
    } else if (e.status === ResponseCode.not_found) {
        history.push(`/not_found`);
    } else if(e.status === ResponseCode.error){
        return e.data;
    }
    // if (e instanceof UnauthorizedException) {
    //     console.log("unauthorized")
    // }
}
