import LocalStorage from "./local_storage";
import { UserModel } from "./api/models";

export class Permission {
    static _is_organization_admin() {
        let user: UserModel | null = LocalStorage.getUser();
        if (user == null) {
            return false;
        }
        return user.role === "organization_admin";
    }

    static _is_site_admin() {
        let user: UserModel | null = LocalStorage.getUser();
        if (user == null) {
            return false;
        }
        return user.role === "site_admin";
    }

    static _is_site_lead() {
        let user: UserModel | null = LocalStorage.getUser();
        if (user == null) {
            return false;
        }
        return user.role === "site_lead";
    }

    static canCreateSessionClass() {
        return this._is_organization_admin() || this._is_site_admin();
    }

    static canEditSessionClass() {
        return this._is_organization_admin() || this._is_site_admin();
    }

    static canCreateSession() {
        return this._is_organization_admin() || this._is_site_admin();
    }

    static canEditSession() {
        return this._is_organization_admin() || this._is_site_admin();
    }

    static canCreateGroup() {
        return this._is_organization_admin() || this._is_site_admin();
    }

    static canEditGroup() {
        return this._is_organization_admin() || this._is_site_admin();
    }

    static canCreateSite() {
        return this._is_organization_admin();
    }

    static canEditSite() {
        return this._is_organization_admin() || this._is_site_admin();
    }
    static canCreateUser() {
        return this._is_organization_admin() || this._is_site_admin();
    }

    static canEditUser() {
        return this._is_organization_admin() || this._is_site_admin();
    }

    static canDeleteUser() {
        return this._is_organization_admin() || this._is_site_admin();
    }

    static canEditPhoneNumber() {
        return this._is_organization_admin() || this._is_site_admin();
    }
}
