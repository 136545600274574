import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import ActionCellMenu from "../../components/ActionCellMenu";
import ConfirmationModal from "../../components/ConfirmationModal";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import PlaceHolderImage from "../../components/PlaceholderImage";
import Table from "../../components/Table";
import { UserModel } from "../../services/api/models";
import Request from "../../services/api/request";
import { deleteUserAPI, getUsersAPI } from "../../services/api/user";
import { Permission } from "../../services/permission";
import { handleException } from "../../utils/exceptions/handle_exception";
import { UserRoles } from "./types";
import LocalStorage from "../../services/local_storage";

// components

const UserList = () => {
    const [users, setUsers] = useState<UserModel[]>([]);
    const [pageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState();

    useEffect(() => {
        getUserList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getUserList() {
        setPageLoading(true);
        try {
            const response = await getUsersAPI();
            setUsers(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    const columns = [
        {
            Header: "ID",
            accessor: "id",
            sort: true,
            maxWidth: 60,
        },
        {
            Header: "Username",
            accessor: "username",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value}</span>;
            },
        },
        {
            Header: "Full Name",
            accessor: "first_name",
            sort: true,
            Cell: (data: any) => {
                data = data.row.original;

                function getAvatar() {
                    let avatar = data.avatar;

                    if (avatar != null) {
                        return PlaceHolderImage({
                            src: Request.getImageUrl(avatar),
                            rounded: true,
                            size: 36,
                        });
                    }
                    return PlaceHolderImage({
                        rounded: true,
                        size: 36,
                    });
                }

                return (
                    <div className="d-flex align-items-center">
                        {getAvatar()}
                        <span className="ms-2 cursor-pointer">
                            {data.first_name + " " + data.last_name}
                        </span>
                    </div>
                );
            },
        },
        {
            Header: "Email",
            accessor: "email",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value}</span>;
            },
        },
        {
            Header: "Phone Number",
            accessor: "phone_number",
            sort: true,
            Cell: (data: any) => {
                return <span>{data.cell.value?.twilio_number}</span>;
            },
        },
        {
            Header: "Site",
            accessor: "site",
            sort: true,
            Cell: (data: any) => {
                if (data.cell.value != null) {
                    return <span>{data.cell.value.name}</span>;
                } else {
                    return <span>-</span>;
                }
            },
        },
        {
            Header: "Role",
            accessor: "role",
            sort: true,
            Cell: (data: any) => {
                return <span>{UserRoles[data.cell.value]}</span>;
            },
        },
        {
            Header: "Actions",
            accessor: "id1",
            maxWidth: 60,
            Cell: (data: any) => {
                const id = data.row.allCells[0].value;
                return (
                    <ActionCellMenu
                        id={`users-${id}-menu`}
                        items={[
                            ...(Permission.canEditUser()
                                ? [
                                      {
                                          id: `edit-${id}`,
                                          label: "Edit",
                                          icon: (
                                              <i className="mdi mdi-pencil-outline"></i>
                                          ),
                                          onClick: () => {
                                              history.push(`/users/${id}`);
                                          },
                                      },
                                  ]
                                : []),
                            ...(Permission.canDeleteUser()
                                ? [
                                      {
                                          id: `delete-${id}`,
                                          label: "Delete",
                                          icon: (
                                              <i className="mdi mdi-delete-outline"></i>
                                          ),
                                          onClick: () => {
                                              setUserToDelete(id);
                                              setShowConfirmationModal(true);
                                          },
                                          disabled:
                                              id === LocalStorage.getUser()?.id,
                                      },
                                  ]
                                : []),
                        ]}
                    />
                );
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: users.length,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Users",
                                        active: true,
                                    },
                                ]}
                                title="Users"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Table
                                        id="users"
                                        columns={columns}
                                        data={users}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={true}
                                        tableClass={"table-striped"}
                                        actions={
                                            Permission.canCreateUser() && (
                                                <Link
                                                    to="/users/create"
                                                    className="btn btn-primary"
                                                >
                                                    <i className="mdi mdi-plus-circle  me-1"></i>
                                                    Create User
                                                </Link>
                                            )
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}

            {userToDelete && (
                <ConfirmationModal
                    show={showConfirmationModal}
                    title="Delete User"
                    body={
                        <div>
                            <p>Are you sure you want to delete this user?</p>
                        </div>
                    }
                    onCancel={() => {
                        setShowConfirmationModal(false);
                    }}
                    onConfirm={async () => {
                        setShowConfirmationModal(false);
                        await deleteUserAPI(userToDelete).then();
                        await getUserList();
                    }}
                />
            )}
        </>
    );
};

export default UserList;
