import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import FileUploader, { FileType } from "../../components/FileUploader";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { createChildAPI } from "../../services/api/children";
import { getGroupsAPI } from "../../services/api/groups";
import { GroupModel } from "../../services/api/models";
import { ToastNotification } from "../../services/toast_notification";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";
import { groupToCustomOption } from "../utils";

const CreateChild = (props: any) => {
    const history = useHistory();

    const state = props.location.state;

    const [pageLoading, setPageLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    const [groups, setGroups] = useState<GroupModel[]>([]);
    const [errors, setErrors] = useState();

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [approvedPickup, setApprovedPickup] = useState("");
    const [pronouns, setPronouns] = useState("");
    const [notes, setNotes] = useState("");
    const [medicalCondition, setMedicalCondition] = useState("");
    const [avatar, setAvatar] = useState<FileType | null>(null);

    const [selectedGroups, setSelectedGroups] = useState<
        CustomSelectOption<number>[]
    >([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        if (state.session_class.groups != null) {
            setPageLoading(false);
            setGroups(state.session_class.groups);
        } else {
            setPageLoading(true);
            try {
                const classResponse = await getGroupsAPI();
                setGroups(classResponse.data);
            } catch (e) {
                ToastNotification.unknownError();
                handleException(history, e);
            }
            setPageLoading(false);
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        createChild();
    };

    const handleFileUpload = (files: FileType[]) => {
        if (files.length > 0) setAvatar(files[files.length - 1]);
    };

    const createChild = async () => {
        setCreating(true);
        try {
            const response = await createChildAPI({
                first_name: firstName,
                last_name: lastName,
                middle_name: middleName,
                medical_condition: medicalCondition,
                avatar: avatar != null ? avatar.preview : undefined,
                groups: groups.filter((g) =>
                    selectedGroups.find((s) => s.id === g.id)
                ),
                notes: notes,
                pronouns: pronouns,
                approved_pickup: approvedPickup,
                session_id: state.session_class.session.id,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Child is added");
            }
        } catch (e: any) {
            console.log(e);
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setCreating(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Children",
                                        path: "/children",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Create ".concat(
                                    firstName.length > 0 && lastName.length > 0
                                        ? firstName
                                              .concat(" ")
                                              .concat(lastName)
                                              .concat("'s Profile")
                                        : "Child's Profile"
                                )}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col lg={4}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="First Name"
                                                placeholder="First Name"
                                                value={firstName}
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                                errors={errors}
                                                name="first_name"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="Middle Name"
                                                placeholder="Middle Name"
                                                value={middleName}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setMiddleName(
                                                        e.target.value
                                                    )
                                                }
                                                name="middle_name"
                                            />
                                        </Col>
                                        <Col lg={4}>
                                            <FormInput
                                                required
                                                type="text"
                                                label="Last Name"
                                                placeholder="Last Name"
                                                value={lastName}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setLastName(e.target.value)
                                                }
                                                name="last_name"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label>
                                                Profile Image
                                            </Form.Label>
                                            <FileUploader
                                                onFileUpload={handleFileUpload}
                                                maxFiles={1}
                                            />

                                            <Form.Label className="mt-3">
                                                Select Groups
                                            </Form.Label>

                                            <CustomSelect
                                                id="user-select"
                                                multiple={true}
                                                emptyLabel={"There is no group"}
                                                onChange={setSelectedGroups}
                                                options={groups.map(
                                                    groupToCustomOption
                                                )}
                                                placeholder="Select a groups"
                                                selected={selectedGroups}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <FormInput
                                                required
                                                label="Pronouns"
                                                placeholder="Pronouns"
                                                value={pronouns}
                                                errors={errors}
                                                rows="1"
                                                onChange={(e) =>
                                                    setPronouns(e.target.value)
                                                }
                                                name="pronouns"
                                            />
                                            <FormInput
                                                required
                                                type="textarea"
                                                label="Medical Condition"
                                                placeholder="Medical Condition"
                                                value={medicalCondition}
                                                errors={errors}
                                                rows="2"
                                                onChange={(e) =>
                                                    setMedicalCondition(
                                                        e.target.value
                                                    )
                                                }
                                                name="medical_condition"
                                            />

                                            <FormInput
                                                required
                                                type="textarea"
                                                label="Approved Pickup"
                                                placeholder="Approved Pickup"
                                                value={approvedPickup}
                                                errors={errors}
                                                rows="2"
                                                onChange={(e) =>
                                                    setApprovedPickup(
                                                        e.target.value
                                                    )
                                                }
                                                name="approved_pickup"
                                            />

                                            <FormInput
                                                required
                                                label="Notes"
                                                placeholder="Notes"
                                                value={notes}
                                                errors={errors}
                                                onChange={(e) =>
                                                    setNotes(e.target.value)
                                                }
                                                name="notes"
                                            />
                                        </Col>
                                    </Row>

                                    <Button
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="float-end"
                                    >
                                        <i
                                            className={
                                                (creating
                                                    ? "mdi mdi-spin mdi-loading"
                                                    : "mdi mdi-check") + " me-1"
                                            }
                                        />{" "}
                                        Create
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default CreateChild;
