import classNames from "classnames";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
    SortByFn,
    useAsyncDebounce,
    useExpanded,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";

// components
import { useStorage } from "../hooks";
import Pagination from "./Pagination";

interface GlobalFilterProps {
    preGlobalFilteredRows: any;
    globalFilter: any;
    setGlobalFilter: any;
    searchBoxClass: any;
}

// Define a default UI for filtering
const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    searchBoxClass,
}: GlobalFilterProps) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState<any>(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <div className={classNames(searchBoxClass)}>
            <span className="d-flex align-items-center">
                Search :{" "}
                <input
                    type="search"
                    value={value || ""}
                    onChange={(e: any) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} records...`}
                    className="form-control w-auto ms-1"
                />
            </span>
        </div>
    );
};

interface IndeterminateCheckboxProps {
    indeterminate: any;
    children?: React.ReactNode;
}

const IndeterminateCheckbox = forwardRef<
    HTMLInputElement,
    IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef: any = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <div className="form-check">
                <input
                    type="checkbox"
                    className="form-check-input"
                    ref={resolvedRef}
                    {...rest}
                />
                <label
                    htmlFor="form-check-input"
                    className="form-check-label"
                ></label>
            </div>
        </>
    );
});

export interface TableColumnProps<D extends object> {
    Header: any;
    accessor: string;
    sort?: boolean;
    Cell?: any;
    className?: string;
    width?: number | string | undefined;
    minWidth?: number | undefined;
    maxWidth?: number | undefined;
    isVisible?: boolean;
    show?: boolean;
    sortType?: SortByFn<D>;
}

interface TableProps<D extends object> {
    id: string;
    isSearchable?: boolean;
    isSortable?: boolean;
    pagination?: boolean;
    manualPagination?: boolean;
    isSelectable?: boolean;
    isExpandable?: boolean;
    refetchData?: any;
    sizePerPageList?: {
        text: string;
        value: number;
    }[];
    sortBy?: any;
    columns: TableColumnProps<D>[];
    onRowClick?: (data: any) => void;
    data: any[];
    pageCount?: any;
    pageSize?: any;
    pageNumber?: any;
    searchBoxClass?: string;
    tableClass?: string;
    theadClass?: string;
    actions?: any;
    filters?: any;
}

const Table = <D extends object>(props: TableProps<D>) => {
    const uniqueId = `tbl-${props.id}`;
    const { save, get } = useStorage({ key: uniqueId });

    const initState = {
        pageSize: props.pageSize || 10,
        pageIndex: props["pageNumber"] || 0,
        ...(props["sortBy"] && { sortBy: props["sortBy"] }),
        ...get(),
    };

    const isSearchable = props.isSearchable ?? false;
    const isSortable = props.isSortable ?? false;
    const pagination = props.pagination ?? false;
    const isSelectable = props.isSelectable ?? false;
    const isExpandable = props.isExpandable ?? false;
    const sizePerPageList = props.sizePerPageList ?? [];

    // TODO - correct the type
    let otherProps: any = {};

    if (isSearchable) {
        otherProps["useGlobalFilter"] = useGlobalFilter;
    }
    if (isSortable) {
        otherProps["useSortBy"] = useSortBy;
    }
    if (isExpandable) {
        otherProps["useExpanded"] = useExpanded;
    }
    if (pagination) {
        otherProps["usePagination"] = usePagination;
    }
    if (isSelectable) {
        otherProps["useRowSelect"] = useRowSelect;
    }
    // const otherProps: any = [
    //     isSearchable && useGlobalFilter,
    //     isSortable && useSortBy,
    //     isExpandable && useExpanded,
    //     pagination && usePagination,
    //     isSelectable && useRowSelect,
    // ].filter(Boolean);

    const getHeaders = (headers: any) => {
        return headers;
        // const list: any[] = [];
        // headers.map((header: any) => {
        //     if (header.isVisible == null || !header.isVisible) {
        //     } else {
        //         list.push(header);
        //     }
        // });
        // return list;
    };

    const emptyArray: [] = [];

    const dataTable = useTable(
        {
            columns: props["columns"] || emptyArray,
            data: props["data"] || emptyArray,
            initialState: initState,
            autoResetPage: false,
            autoResetHiddenColumns: false,
            pageCount: props["pageCount"],
            manualPagination: props.manualPagination ? true : false,
        },
        otherProps.hasOwnProperty("useGlobalFilter") &&
            otherProps["useGlobalFilter"],
        otherProps.hasOwnProperty("useSortBy") && otherProps["useSortBy"],
        otherProps.hasOwnProperty("useExpanded") && otherProps["useExpanded"],
        otherProps.hasOwnProperty("usePagination") &&
            otherProps["usePagination"],
        otherProps.hasOwnProperty("useRowSelect") && otherProps["useRowSelect"],

        (hooks) => {
            isSelectable &&
                hooks.visibleColumns.push((columns: any) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({
                            getToggleAllPageRowsSelectedProps,
                        }: any) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...getToggleAllPageRowsSelectedProps()}
                                />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }: any) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]);

            isExpandable &&
                hooks.visibleColumns.push((columns: any) => [
                    // Let's make a column for selection
                    {
                        // Build our expander column
                        id: "expander", // Make sure it has an ID
                        Header: ({
                            getToggleAllRowsExpandedProps,
                            isAllRowsExpanded,
                        }: any) => (
                            <span {...getToggleAllRowsExpandedProps()}>
                                {isAllRowsExpanded ? "-" : "+"}
                            </span>
                        ),
                        Cell: ({ row }) =>
                            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                            // to build the toggle for expanding a row
                            row.canExpand ? (
                                <span
                                    {...row.getToggleRowExpandedProps({
                                        style: {
                                            // We can even use the row.depth property
                                            // and paddingLeft to indicate the depth
                                            // of the row
                                            paddingLeft: `${row.depth * 2}rem`,
                                        },
                                    })}
                                >
                                    {row.isExpanded ? "-" : "+"}
                                </span>
                            ) : null,
                    },
                    ...columns,
                ]);
        }
    );

    let rows = pagination ? dataTable.page : dataTable.rows;
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (props.manualPagination) {
            props.refetchData &&
                props.refetchData({
                    pageIndex: dataTable.state.pageIndex + 1,
                    pageSize: dataTable?.state?.pageSize || 10,
                });
        }
    }, [dataTable.state.pageIndex, dataTable.state.pageSize]);

    useEffect(() => {
        dataTable.state &&
            save({
                data: dataTable.state as unknown as Record<string, unknown>,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTable.state]);

    return (
        <>
            <div className="d-flex mb-1">
                {isSearchable && (
                    <>
                        <GlobalFilter
                            preGlobalFilteredRows={
                                dataTable.preGlobalFilteredRows
                            }
                            globalFilter={dataTable.state.globalFilter}
                            setGlobalFilter={dataTable.setGlobalFilter}
                            searchBoxClass={props["searchBoxClass"]}
                        />
                    </>
                )}
                {props.filters && (
                    <span className={"ms-3 d-flex align-items-center"}>
                        Filter:{" "}
                        <div className={"ms-1 d-inline"}>{props.filters}</div>
                    </span>
                )}

                {props.actions && (
                    <div className={"ms-auto"}>{props.actions}</div>
                )}
            </div>

            <div className="table-responsive">
                <table
                    {...dataTable.getTableProps()}
                    className={classNames(
                        "table table-centered react-table",
                        props["tableClass"]
                    )}
                >
                    <thead className={props["theadClass"]}>
                        {(dataTable.headerGroups || []).map(
                            (headerGroup: any) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {(
                                        getHeaders(headerGroup.headers) || []
                                    ).map((column: any) => {
                                        return (
                                            <th
                                                {...column.getHeaderProps(
                                                    column.sort &&
                                                        column.getSortByToggleProps()
                                                )}
                                                {...column.getHeaderProps({
                                                    style: {
                                                        maxWidth:
                                                            column.maxWidth,
                                                        width: column.width,
                                                    },
                                                })}
                                                className={classNames(
                                                    {
                                                        sorting_desc:
                                                            column.isSortedDesc ===
                                                            false,
                                                        sorting_asc:
                                                            column.isSortedDesc ===
                                                            false,
                                                        sortable:
                                                            column.sort ===
                                                            true,
                                                    },
                                                    column.className
                                                )}
                                            >
                                                {column.render("Header")}
                                            </th>
                                        );
                                    })}
                                </tr>
                            )
                        )}
                    </thead>
                    <tbody {...dataTable.getTableBodyProps()}>
                        {(rows || []).map((row: any, i: number) => {
                            dataTable.prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className={
                                        props.onRowClick != null
                                            ? "cursor-pointer"
                                            : null
                                    }
                                    onClick={() =>
                                        props.onRowClick
                                            ? props.onRowClick(row)
                                            : null
                                    }
                                >
                                    {(row.cells || []).map((cell: any) => {
                                        return (
                                            <td
                                                {...cell.getCellProps([
                                                    {
                                                        className:
                                                            cell.column
                                                                .className,
                                                        style: {
                                                            maxWidth:
                                                                cell.column
                                                                    .maxWidth,
                                                            width: cell.column
                                                                .width,
                                                        },
                                                    },
                                                ])}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {pagination && (
                <Pagination
                    refetchData={props.refetchData}
                    tableProps={dataTable}
                    sizePerPageList={sizePerPageList}
                />
            )}
        </>
    );
};

export default Table;
