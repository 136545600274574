import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Table, { TableColumnProps } from "../../components/Table";
import PlaceHolderImage from "../../components/PlaceholderImage";
import { Link, useHistory } from "react-router-dom";
import Request from "../../services/api/request";
import PageLoader from "../../components/PageLoader";
import { handleException } from "../../utils/exceptions/handle_exception";
import {
    deleteClassChildrenAPI,
    getClassChildrenAPI,
} from "../../services/api/class_children";
import {
    CheckinModel,
    ChildModel,
    ChildParentModel,
    ITableData,
    SessionClassModel,
} from "../../services/api/models";
import { ToastNotification } from "../../services/toast_notification";
import { deleteChildParentsAPI } from "../../services/api/child_parents";
import { formatPhoneNumber } from "../../services/api/phone_number";
import { getCheckinStatusFromChild } from "../../services/api/check_ins";
import Modal from "react-bootstrap/Modal";
import ActionCellMenu from "../../components/ActionCellMenu";

// components

const ChildrenList = (props: any) => {
    const { id } = props.match.params;

    const [sessionClass, setSessionClass] = useState<SessionClassModel>();
    const [children, setChildren] = useState<ChildModel[]>([]);
    const [child, setChild] = useState<ChildModel | undefined>(undefined);
    const [pageLoading, setPageLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getClassData();
    }, []);

    useEffect(() => {
        generateChildrenData();
    }, [sessionClass]);

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search);
        const cid = urlParams.get("cid");
        if (cid) {
            const childs = children.filter((item) => item.id === parseInt(cid));
            if (childs.length > 0) setChild(childs[0]);
        } else {
            setChild(undefined);
        }
    }, [history.location, children]);

    const showChild = (childId: number) => {
        const child = children.find((item) => item.id === childId);
        child && setChild(child);
    };

    const hideChild = () => {
        setChild(undefined);
    };

    async function getClassData() {
        setPageLoading(true);
        try {
            const response = await getClassChildrenAPI(id);
            setSessionClass(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    function generateChildrenData() {
        if (sessionClass != null && sessionClass.groups != null) {
            let children: ChildModel[] = [];

            sessionClass.groups.forEach(function (group) {
                children.push(
                    ...(group.children ?? []).map((child) => {
                        Object.defineProperty(child, "class", {
                            value: sessionClass,
                        });
                        Object.defineProperty(child, "group", {
                            value: group,
                        });
                        return child;
                    })
                );
            });

            const unique2 = children.filter((obj, index) => {
                return index === children.findIndex((o) => obj.id === o.id);
            });

            children.filter((item, index) => children.indexOf(item) === index);
            setChildren(unique2);
            setChild(undefined);
        }
    }

    const compareNumericString = (
        rowA: any,
        rowB: any,
        id: any,
        desc: any
    ): number => {
        return getCheckinStatusFromChild(rowA.original).localeCompare(
            getCheckinStatusFromChild(rowB.original)
        );
    };

    const childDelete = (child: ChildModel) => async () => {
        if (
            window.confirm(
                "Are you want to sure to delete " +
                    child.first_name +
                    " " +
                    child.last_name
            )
        ) {
            setPageLoading(true);
            try {
                const response = await deleteClassChildrenAPI(id, child.id);
                if (response.isSuccess()) {
                    ToastNotification.success(
                        "Child is removed from this class"
                    );
                    await getClassData();
                } else {
                    ToastNotification.unknownError();
                }
            } catch (e: any) {
                handleException(history, e);
            }
            setPageLoading(false);
        }
    };
    const deleteChildParent = (childParent: ChildParentModel) => async () => {
        if (
            window.confirm(
                "Are you want to sure to delete " +
                    childParent.parent?.first_name +
                    " " +
                    childParent.parent?.last_name
            )
        ) {
            setPageLoading(true);
            try {
                const response = await deleteChildParentsAPI(childParent.id);
                if (response.isSuccess()) {
                    ToastNotification.success(
                        "Child Parent is removed from this class"
                    );
                    await getClassData();
                } else {
                    ToastNotification.unknownError();
                }
            } catch (e: any) {
                handleException(history, e);
            }
            setPageLoading(false);
        }
    };

    const columns: TableColumnProps<ChildModel>[] = [
        {
            Header: "Status",
            accessor: "id",
            maxWidth: 60,
            sort: true,
            sortType: compareNumericString,
            Cell: (data: ITableData<ChildModel, any>) => {
                let child = data.row.original;
                const status = getCheckinStatusFromChild(child);
                if (status === "checkin") {
                    return (
                        <span className="text-success fw-semibold">
                            Checked In
                        </span>
                    );
                }
                if (status === "checkout") {
                    return <span className="fw-semibold">Checked Out</span>;
                }
                if (status === "absent") {
                    return (
                        <span className="text-danger fw-semibold">Absent</span>
                    );
                }
                return <span className="fw-semibold">Checked Out</span>;
            },
        },
        {
            Header: "Name",
            accessor: "first_name",
            sort: true,
            maxWidth: 120,

            Cell: (data: any) => {
                data = data.row.original;

                function getAvatar() {
                    let avatar = data.avatar;

                    if (avatar != null) {
                        return PlaceHolderImage({
                            src: Request.getImageUrl(avatar),
                            rounded: true,
                            size: 36,
                            style: { objectFit: "cover" },
                        });
                    }
                    return PlaceHolderImage({
                        rounded: true,
                        size: 36,
                    });
                }

                return (
                    <div className="d-flex align-items-center">
                        {getAvatar()}
                        <span
                            onClick={(e) => {
                                showChild(data.id);
                            }}
                            className="ms-2 cursor-pointer"
                        >
                            {data.first_name + " " + data.last_name}
                        </span>
                    </div>
                );
            },
        },
        {
            Header: "Medical Condition",
            accessor: "medical_condition",
            maxWidth: 60,
            Cell: (data: any) => {
                let value = data.cell.value;

                return (
                    <span style={{ whiteSpace: "pre-wrap" }}>
                        {value ?? "-"}
                    </span>
                );
            },
        },
        {
            Header: "Group",
            accessor: "groups",
            maxWidth: 120,
            Cell: (data: any) => {
                let value = data.cell.value;
                let groupItem = "";

                if (value?.length > 0) {
                    groupItem =
                        groupItem +
                        value.map((data: any) => data.name).join(", ");
                }
                return groupItem;
            },
        },
        {
            Header: "Attendance",
            accessor: "id1",
            maxWidth: 50,
            Cell: (data: ITableData<CheckinModel, string>) => {
                let id = data.row.original.id;
                return (
                    <Link
                        to={{
                            pathname: `/children/${id}/checkins`,
                            state: {
                                child: data.row.original,
                                group: data.row.original.group,
                                site: sessionClass?.session?.site,
                            },
                        }}
                        className="action-icon"
                    >
                        <i className="mdi mdi-account-clock-outline"></i>
                    </Link>
                );
            },
        },

        {
            Header: "Actions",
            accessor: "id2",
            maxWidth: 40,

            Cell: (data: any) => {
                const child = data.row.original;
                return (
                    <ActionCellMenu
                        id={`classes-${id}-menu`}
                        items={[
                            {
                                id: `edit-${id}`,
                                label: "Edit",
                                icon: (
                                    <i className="mdi mdi-square-edit-outline"></i>
                                ),
                                onClick: () => {
                                    history.push(`/children/${child.id}`, {
                                        session_class: sessionClass,
                                    });
                                },
                            },
                            {
                                id: `delete-${id}`,
                                label: "Delete",
                                icon: (
                                    <i className="mdi mdi-delete-outline"></i>
                                ),
                                onClick: childDelete(child),
                            },
                        ]}
                    />
                );
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
    ];

    const getPrimaryParent = (child: ChildModel) => {
        if (child.child_parents != null && child.child_parents.length > 0) {
            for (let i = 0; i < child.child_parents.length; i++) {
                if (child.child_parents[i].is_primary) {
                    return child.child_parents[i];
                }
            }
        }
        return null;
    };

    const getApprovedParent = (child: ChildModel) => {
        let list: ChildParentModel[] = [];
        let primary = false;
        if (child.child_parents != null && child.child_parents.length > 0) {
            for (let i = 0; i < child.child_parents.length; i++) {
                if (child.child_parents[i].is_primary && !primary) {
                    primary = true;
                } else {
                    list.push(child.child_parents[i]);
                }
            }
        }
        return list;
    };

    const getParentView = (childParent: ChildParentModel | null) => {
        const parent = childParent?.parent;
        if (childParent == null || parent == null) {
            return <div>There is no primary parent</div>;
        }
        return (
            <div key={parent.id} className="mb-2">
                <div className="d-flex">
                    <PlaceHolderImage
                        src={
                            parent.avatar != null
                                ? Request.getImageUrl(parent.avatar)
                                : undefined
                        }
                        rounded={true}
                        size={48}
                    />
                    <div className="ms-2">
                        <h5 className="mt-0 mb-1">
                            {parent.first_name + " " + parent.last_name}
                        </h5>
                        <p className="mt-0 mb-1">
                            {formatPhoneNumber(parent.phone_number)}
                        </p>
                        <p className="mt-0 mb-1">
                            {childParent.family_relation?.kind ?? "Unknown"}
                        </p>
                    </div>
                    <div className="d-inline text-end flex-grow-1">
                        <span
                            onClick={deleteChildParent(childParent)}
                            className="action-icon ms-1 cursor-pointer"
                        >
                            <i className="mdi mdi-delete-outline"></i>
                        </span>
                        <Link
                            to={`/child_parents/${childParent.id}`}
                            className="action-icon"
                        >
                            <i className="mdi mdi-square-edit-outline"></i>
                        </Link>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <div className="page-title-box">
                                <div className="page-title-right"></div>
                                <h4 className="page-title">
                                    {" "}
                                    {sessionClass != null
                                        ? sessionClass.name + " Class"
                                        : "Session Class"}
                                </h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12}>
                            <Card>
                                <Card.Body>
                                    <Table<ChildModel>
                                        id="class-children"
                                        columns={columns}
                                        data={children}
                                        pageSize={10}
                                        sizePerPageList={sizePerPageList}
                                        isSortable={true}
                                        pagination={true}
                                        isSearchable={true}
                                        sortBy={[
                                            {
                                                id: "first_name",
                                                desc: false,
                                            },
                                        ]}
                                        actions={
                                            <div>
                                                <Link
                                                    to={{
                                                        pathname:
                                                            "/children/create",
                                                        state: {
                                                            session_class:
                                                                sessionClass,
                                                        },
                                                    }}
                                                    className="btn btn-primary me-2"
                                                >
                                                    <i className="mdi mdi-plus-circle  me-1"></i>
                                                    Add Child
                                                </Link>
                                                <Link
                                                    to={
                                                        "/class_children/" +
                                                        id +
                                                        "/import"
                                                    }
                                                    className="btn btn-primary"
                                                >
                                                    <i className="mdi mdi-database-import-outline  me-1"></i>
                                                    Import
                                                </Link>
                                            </div>
                                        }
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Modal show={child} onHide={() => hideChild()}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            {child && (
                                <div className="card">
                                    <div className="card-body">
                                        <div className="align-items-start text-center mb-3">
                                            <PlaceHolderImage
                                                src={
                                                    child.avatar != null
                                                        ? Request.getImageUrl(
                                                              child.avatar
                                                          )
                                                        : undefined
                                                }
                                                rounded={true}
                                                size={64}
                                                style={{ objectFit: "cover" }}
                                            />

                                            <div className="mt-3">
                                                <h4 className="mt-0 mb-1">
                                                    {child.first_name +
                                                        " " +
                                                        child.last_name}
                                                </h4>
                                                {child.pronouns != null &&
                                                    child.pronouns.trim()
                                                        .length > 0 && (
                                                        <p>{child.pronouns} </p>
                                                    )}
                                                {child.feed_url && (
                                                    <p>
                                                        <a
                                                            href={
                                                                child.feed_url
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Image Feed
                                                        </a>
                                                    </p>
                                                )}
                                                {child.medical_condition !=
                                                    null &&
                                                    child.medical_condition.trim()
                                                        .length > 0 && (
                                                        <p>
                                                            <b>
                                                                Medical
                                                                Conditions:{" "}
                                                            </b>{" "}
                                                            {
                                                                child.medical_condition
                                                            }{" "}
                                                        </p>
                                                    )}
                                                {child.approved_pickup !=
                                                    null &&
                                                    child.approved_pickup.trim()
                                                        .length > 0 && (
                                                        <p>
                                                            <b>
                                                                Approved Pickup
                                                                List:{" "}
                                                            </b>{" "}
                                                            {
                                                                child.approved_pickup
                                                            }{" "}
                                                        </p>
                                                    )}

                                                {child.notes != null &&
                                                    child.notes.trim().length >
                                                        0 && (
                                                        <p>
                                                            <b>Notes: </b>{" "}
                                                            {child.notes}{" "}
                                                        </p>
                                                    )}
                                                {/*<p className="text-muted">*/}
                                                {/*    {child.group?.name}*/}
                                                {/*</p>*/}
                                                {/*<p className="text-muted">*/}
                                                {/*    {child.class?.name}*/}
                                                {/*</p>*/}
                                            </div>
                                        </div>

                                        <h5 className="mb-3 mt-4 text-uppercase bg-light p-2">
                                            <i className="mdi mdi-account-circle me-1"></i>{" "}
                                            Primary Guardian
                                        </h5>

                                        {getParentView(getPrimaryParent(child))}

                                        <h5 className="mb-3 mt-4 text-uppercase bg-light p-2">
                                            <i className="mdi mdi-account-circle me-1"></i>{" "}
                                            Alternate Contacts
                                        </h5>

                                        {getApprovedParent(child).map(function (
                                            parent
                                        ) {
                                            return getParentView(parent);
                                        })}

                                        <div className="text-center mt-3">
                                            <Link
                                                to={{
                                                    pathname:
                                                        "/child_parents/create",

                                                    state: {
                                                        child: child,
                                                        session:
                                                            sessionClass?.session,
                                                    },
                                                }}
                                                className="btn btn-primary"
                                            >
                                                <i className="mdi mdi-plus-circle  me-1"></i>
                                                Add Contact
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default ChildrenList;
