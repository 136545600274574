import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import { editGroupMessageAPI } from "../../services/api/chats";
import {
    GroupMessageModel,
    GroupMessageStatusModel,
    GroupModel,
    ITableData,
    SessionClassModel,
    SessionModel,
} from "../../services/api/models";
import {
    getAllClassesFromSessions,
    getSessionsForCheckinAPI,
} from "../../services/api/sessions";
import { ToastNotification } from "../../services/toast_notification";
import { getFormattedDate } from "../../utils/DateFormat";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";
import { capitalize } from "../../utils/strings";
import {
    classToCustomOption,
    groupToCustomOption,
    sessionToCustomOption,
} from "../utils";

const EditGroupMessage = (props: any) => {
    const { id } = props.match.params;

    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState();

    const [message, setMessage] = useState<string>("");

    const [sessions, setSessions] = useState<SessionModel[]>([]);
    const [selectedSession, setSelectedSession] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [classes, setClasses] = useState<SessionClassModel[]>([]);
    const [selectedClass, setSelectedClass] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [groups, setGroups] = useState<GroupModel[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [groupMessage, setGroupMessage] = useState<GroupMessageModel>();

    useEffect(() => {
        getMessageData();
        getSessionData();
    }, []);

    const canEdit = () => {
        return (
            groupMessage != null &&
            groupMessage.schedule != null &&
            new Date(groupMessage.schedule) > new Date(new Date())
        );
    };
    async function getSessionData() {
        setPageLoading(true);
        try {
            const response = await getSessionsForCheckinAPI();
            setSessions(response.data);
            setClasses(getAllClassesFromSessions(response.data));
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    async function getMessageData() {
        const groupMessage: GroupMessageModel =
            props.location.state?.group_message;

        if (groupMessage == null) {
        }
        setGroupMessage(groupMessage);
        setMessage(groupMessage.message);

        setPageLoading(true);
        try {
            const response = await getSessionsForCheckinAPI();
            setSessions(response.data);
            // setClasses(getAllClassesFromSessions(response.data));
            const s_g = groupMessage.group_chat?.group;
            const s_c = s_g?.session_class;
            const s_s = groupMessage.group_chat?.group?.session_class?.session;
            if (s_s != null && s_g != null && s_c != null) {
                setSelectedSession([sessionToCustomOption(s_s)]);
                setClasses(getAllClassesFromSessions([s_s]));
                setSelectedClass([classToCustomOption(s_c)]);
                setSelectedGroup([groupToCustomOption(s_g)]);
            }
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    }

    const editMessage = async () => {
        if (message.length === 0) {
            ToastNotification.error("Please provide a message");
            return;
        }
        if (groupMessage == null) return;
        setSending(true);

        try {
            const gid = selectedGroup.length > 0 ? selectedGroup[0].id : null;
            const response = await editGroupMessageAPI(groupMessage?.id, {
                message: message,
                group_id: gid,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Group message edited");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setSending(false);
    };

    const deleteMessage = async () => {
        history.goBack();
        return;
    };

    const columns = [
        {
            Header: "Parent",
            accessor: "",
            sort: true,
            maxWidth: 120,
            Cell: (data: ITableData<GroupMessageStatusModel, string>) => {
                return (
                    data.row.original.parent?.first_name +
                    " " +
                    data.row.original.parent?.last_name
                );
            },
        },
        {
            Header: "Phone Number",
            accessor: "",
            sort: true,
            maxWidth: 120,
            Cell: (data: ITableData<GroupMessageStatusModel, string>) => {
                return data.row.original.parent?.phone_number;
            },
        },
        {
            Header: "Status",
            accessor: "",
            sort: true,
            Cell: (data: ITableData<GroupMessageStatusModel, string>) => {
                const status = data.row.original.status;
                return (
                    <span
                        className={
                            status === "failed" ? "text-danger" : "text-success"
                        }
                    >
                        {capitalize(data.row.original.status)}
                    </span>
                );
            },
        },
    ];

    const sizePerPageList = [
        {
            text: "5",
            value: 5,
        },
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "All",
            value: groupMessage?.statuses?.length ?? 10,
        },
    ];

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Group Messages",
                                        path: "/group_messages",
                                    },
                                    {
                                        label: "Edit",
                                        active: true,
                                    },
                                ]}
                                title={"Edit Message "}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={10} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    {
                                        <div>
                                            <p>
                                                <span className="fw-semibold">
                                                    Site -{" "}
                                                </span>
                                                {groupMessage?.group_chat?.group
                                                    ?.session_class?.session
                                                    ?.site?.name ?? "-"}
                                            </p>

                                            <p>
                                                <span className="fw-semibold">
                                                    Session Class -{" "}
                                                </span>
                                                {groupMessage?.group_chat?.group
                                                    ?.session_class?.name ??
                                                    "-"}
                                            </p>

                                            <p>
                                                <span className="fw-semibold">
                                                    Group -{" "}
                                                </span>
                                                {groupMessage?.group_chat?.group
                                                    ?.name ?? "-"}
                                            </p>
                                        </div>
                                    }

                                    {false && canEdit() && (
                                        <div>
                                            <Form.Label>Select Site</Form.Label>
                                            <CustomSelect
                                                id="session-select"
                                                multiple={false}
                                                onChange={(e) => {
                                                    setSelectedSession(e);
                                                    if (e.length === 0) {
                                                        setClasses(
                                                            getAllClassesFromSessions(
                                                                sessions
                                                            )
                                                        );
                                                        return;
                                                    }
                                                    setSelectedClass([]);
                                                    setSelectedGroup([]);
                                                    const session =
                                                        sessions.find(
                                                            ({ id }) =>
                                                                id ===
                                                                e?.[0]?.id
                                                        );
                                                    setClasses(
                                                        session?.classes ?? []
                                                    );
                                                }}
                                                options={sessions.map(
                                                    sessionToCustomOption
                                                )}
                                                placeholder="Select a site"
                                                selected={selectedSession}
                                            />

                                            <Form.Label className="mt-3">
                                                Select Class
                                            </Form.Label>

                                            <CustomSelect
                                                id="class-select"
                                                multiple={false}
                                                emptyLabel={
                                                    selectedSession.length > 0
                                                        ? "No any class with this session"
                                                        : "Please select session first"
                                                }
                                                onChange={(e) => {
                                                    setSelectedClass(e);
                                                    if (e.length === 0) return;
                                                    setSelectedGroup([]);
                                                    const cl = classes.find(
                                                        ({ id }) =>
                                                            id === e?.[0]?.id
                                                    );
                                                    setGroups(cl?.groups ?? []);
                                                }}
                                                options={classes.map(
                                                    classToCustomOption
                                                )}
                                                placeholder="Select a class"
                                                selected={selectedClass}
                                            />
                                            <Form.Label className="mt-3">
                                                Select Group
                                            </Form.Label>

                                            <CustomSelect
                                                id="group-select"
                                                multiple={false}
                                                emptyLabel={
                                                    selectedClass.length > 0
                                                        ? "No groups in this class"
                                                        : "Please select class first"
                                                }
                                                onChange={(e) => {
                                                    setSelectedGroup(e);
                                                    if (e.length === 0) return;
                                                }}
                                                options={groups.map(
                                                    groupToCustomOption
                                                )}
                                                placeholder="Select a group"
                                                selected={selectedGroup}
                                                className="mb-3 "
                                            />
                                        </div>
                                    )}

                                    {!canEdit() && groupMessage != null && (
                                        <p>
                                            <span className="fw-semibold">
                                                Message -{" "}
                                                {groupMessage?.message}
                                            </span>
                                        </p>
                                    )}

                                    {canEdit() && (
                                        <p>
                                            <span className="fw-semibold">
                                                Scheduled -{" "}
                                            </span>
                                            {getFormattedDate(
                                                new Date(
                                                    groupMessage?.schedule!
                                                )
                                            )}
                                        </p>
                                    )}

                                    {!canEdit() && groupMessage != null && (
                                        <p>
                                            <span className="fw-semibold">
                                                Sent -{" "}
                                            </span>
                                            {getFormattedDate(
                                                new Date(
                                                    groupMessage?.schedule ??
                                                        groupMessage?.send_date!
                                                )
                                            )}
                                        </p>
                                    )}

                                    {canEdit() && (
                                        <div>
                                            <FormInput
                                                required
                                                multiple
                                                rows="3"
                                                type="textarea"
                                                label="Message"
                                                placeholder="Message"
                                                value={message}
                                                onChange={(e) =>
                                                    setMessage(e.target.value)
                                                }
                                                errors={errors}
                                                name="first_name"
                                            />

                                            <div className="d-flex justify-content-end gap-2">
                                                <Button
                                                    variant={"light"}
                                                    onClick={deleteMessage}
                                                    disabled={sending}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    onClick={editMessage}
                                                    disabled={sending}
                                                >
                                                    <i
                                                        className={
                                                            (sending
                                                                ? "mdi mdi-spin mdi-loading"
                                                                : "mdi mdi-check") +
                                                            " me-1"
                                                        }
                                                    />{" "}
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    )}

                                    {!canEdit() && (
                                        <div>
                                            {groupMessage?.statuses != null &&
                                                groupMessage?.statuses.length >
                                                    0 && (
                                                    <>
                                                        <Table
                                                            id="chats"
                                                            columns={columns}
                                                            data={
                                                                groupMessage?.statuses
                                                            }
                                                            pageSize={10}
                                                            sizePerPageList={
                                                                sizePerPageList
                                                            }
                                                            isSortable={true}
                                                            pagination={true}
                                                            isSearchable={true}
                                                            tableClass={
                                                                "table-striped"
                                                            }
                                                        />
                                                    </>
                                                )}

                                            {groupMessage?.statuses != null &&
                                                groupMessage?.statuses
                                                    .length === 0 && (
                                                    <span className="text-center">
                                                        There is no status
                                                        record
                                                    </span>
                                                )}
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default EditGroupMessage;
