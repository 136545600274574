import { Alert, Card, Col, Row } from "react-bootstrap";
import Table from "../../components/Table";

export type LineWithError = {
    lineNum: string;
    errors: string[];
};

const ErrorLines = ({
    linesWithErrors,
}: {
    linesWithErrors: LineWithError[];
}) => {
    const columns = [
        {
            Header: "Line Number",
            accessor: "lineNum",
            maxWidth: 40,
        },
        {
            Header: "Errors",
            accessor: "errors",
            sort: true,
            Cell: (data: any) => {
                const errors = data.cell.value;
                return (
                    <ul className="p-2">
                        {errors.map((e: string, i: number) => (
                            <li key={i}>{e}</li>
                        ))}
                    </ul>
                );
            },
        },
    ];

    return (
        <Row className="mt-3">
            <Col>
                <Card>
                    <Card.Body>
                        <Alert variant="danger">
                            Please review the following lines with errors:
                        </Alert>
                        <Table
                            id="lines-with-errors"
                            columns={columns}
                            data={linesWithErrors}
                            pageSize={25}
                            isSortable={true}
                            pagination={true}
                            isSearchable={false}
                            tableClass={"table-striped table-sm"}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default ErrorLines;
