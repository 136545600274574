import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DatePicker from "../../components/DatePicker";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { ToastNotification } from "../../services/toast_notification";
import { handleException } from "../../utils/exceptions/handle_exception";

import { createAbsenceAPI } from "../../services/api/check_ins";
import { getGroupsAPI } from "../../services/api/groups";
import { ChildModel, GroupModel } from "../../services/api/models";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import { childToCustomOption, groupToCustomOption } from "../utils";

const CreateCheckIn = (props?: any) => {
    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(true);
    const [creating, setCreating] = useState(false);

    const [groups, setGroups] = useState<GroupModel[]>([]);
    const [children, setChildren] = useState<ChildModel[]>([]);

    const [startDate, setCheckInTime] = useState<Date>(new Date());
    const [endDate, setCheckOutTime] = useState<Date>(new Date());

    const [selectedGroup, setSelectedGroup] = useState<
        CustomSelectOption<number>[]
    >([]);
    const [selectedChild, setSelectedChild] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [validated, setValidated] = useState<boolean>(false);

    useEffect(() => {
        if (props.location.state.group != null) {
            setSelectedGroup([groupToCustomOption(props.location.state.group)]);
        }
        if (props.location.state.child != null) {
            setSelectedChild([childToCustomOption(props.location.state.child)]);
        }
        getGroups();
    }, []);

    const getGroups = async () => {
        try {
            const response = await getGroupsAPI();
            setGroups(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);

        if (selectedGroup.length === 0 || selectedChild.length === 0) {
            ToastNotification.error("Select group & child for checkin");
            return;
        }

        addAbsence();
    };

    const addAbsence = async () => {
        setCreating(true);
        try {
            const response = await createAbsenceAPI({
                child_id: selectedChild[0].id,
                group_id: selectedGroup[0].id,
                start_date: startDate,
                end_date: endDate,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Absent added");
            }
        } catch (e) {
            ToastNotification.unknownError();
            handleException(history, e);
        }
        setCreating(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Check Ins",
                                        path: "/check_ins",
                                    },
                                    {
                                        label: "Add Absence",
                                        active: true,
                                    },
                                ]}
                                title="Add Absence"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <Form
                                        noValidate
                                        validated={validated}
                                        onSubmit={handleSubmit}
                                    >
                                        <Form.Label className="mt-3">
                                            Group
                                        </Form.Label>

                                        <CustomSelect
                                            id="groups"
                                            multiple={false}
                                            onChange={(e) => {
                                                setSelectedGroup(e);
                                                const grp = groups.find(
                                                    ({ id }) =>
                                                        id === e?.[0]?.id
                                                );
                                                setChildren(
                                                    grp?.children ?? []
                                                );
                                            }}
                                            options={groups.map(
                                                groupToCustomOption
                                            )}
                                            placeholder="Select a group"
                                            selected={selectedGroup}
                                        />

                                        <Form.Label className="mt-3">
                                            Child
                                        </Form.Label>

                                        <CustomSelect
                                            id="children"
                                            multiple={false}
                                            onChange={(e) =>
                                                setSelectedChild(e)
                                            }
                                            options={children.map(
                                                childToCustomOption
                                            )}
                                            placeholder="Select a child"
                                            selected={selectedChild}
                                            emptyLabel={
                                                "Please select group first"
                                            }
                                        />

                                        <Form.Label className="mt-3">
                                            Start Date
                                        </Form.Label>

                                        <DatePicker
                                            showAddon={true}
                                            dateFormat="MMMM d, yyyy"
                                            timeCaption="time"
                                            value={startDate}
                                            onChange={(date) => {
                                                setCheckInTime(date);
                                            }}
                                        />

                                        <Form.Label className="mt-3">
                                            End Date
                                        </Form.Label>

                                        <DatePicker
                                            showAddon={true}
                                            dateFormat="MMMM d, yyyy"
                                            timeCaption="time"
                                            value={endDate}
                                            minDate={startDate}
                                            onChange={(date) => {
                                                console.log(
                                                    "%cadd_absence.tsx line:187 date",
                                                    "color: #007acc;",
                                                    date
                                                );
                                                setCheckOutTime(date);
                                            }}
                                        />

                                        <Row className="mt-3 ">
                                            <Col>
                                                <Button
                                                    type="submit"
                                                    className="float-end"
                                                    disabled={creating}
                                                >
                                                    <i
                                                        className={
                                                            (creating
                                                                ? "mdi mdi-spin mdi-loading"
                                                                : "mdi mdi-check") +
                                                            " me-1"
                                                        }
                                                    />{" "}
                                                    Add absence
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default CreateCheckIn;
