import { useEffect, useState } from "react";
interface PageLoaderProps {
    loading?: boolean;
}

const PageLoader = (props: PageLoaderProps) => {
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (!props.loading) {
            setTimeout(() => {
                setHide(true);
                document.body.classList.remove("overflow-hidden");
            }, 300);
        } else {
            document.body.classList.add("overflow-hidden");
        }
        setHide(!props.loading ?? true);
    }, [props.loading]);

    return hide ? (
        <></>
    ) : (
        <div id="preloader" className={`${props.loading ? "show" : ""}`}>
            <div id="status">
                <div className="spinner">Loading...</div>
            </div>
        </div>
    );
};

PageLoader.props = {
    loading: false,
};

export default PageLoader;
