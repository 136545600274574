import { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    FormCheck,
    Row,
    ToggleButton,
    ToggleButtonGroup,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormInput } from "../../components";
import CustomSelect, {
    CustomSelectOption,
} from "../../components/CustomSelect";
import DatePicker from "../../components/DatePicker";
import PageLoader from "../../components/PageLoader";
import PageTitle from "../../components/PageTitle";
import { editClassAPI, getClassAPI } from "../../services/api/classes";
import { SessionModel } from "../../services/api/models";
import { getSessionsAPI } from "../../services/api/sessions";
import { ToastNotification } from "../../services/toast_notification";
import { BaseException } from "../../utils/exceptions/base_exception";
import { handleException } from "../../utils/exceptions/handle_exception";

const EditClass = (props: any) => {
    const { id } = props.match.params;

    const history = useHistory();
    const [pageLoading, setPageLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [errors, setErrors] = useState();

    const [sessions, setSessions] = useState<SessionModel[]>();

    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [feedUrlAt, setFeedUrlAt] = useState<Date | undefined>();
    const [selectedSession, setSelectedSession] = useState<
        CustomSelectOption<number>[]
    >([]);

    const [autoCheckoutEnable, setAutoCheckoutEnable] = useState(false);
    const [sendCheckoutNotification, setSendCheckoutNotification] =
        useState(false);
    const [sendReportTo, setSendReportTo] = useState("");
    const [autoCheckoutAt, setAutoCheckoutAt] = useState(new Date());

    const [archive, setArchive] = useState(false);
    const [draft, setDraft] = useState(false);
    const [notificationDays, setNotificationDays] = useState<any>([]);
    const options = [
        { label: "S", value: "sunday" },
        { label: "M", value: "monday" },
        { label: "T", value: "tuesday" },
        { label: "W", value: "wednesday" },
        { label: "T", value: "thursday" },
        { label: "F", value: "friday" },
        { label: "S", value: "saturday" },
    ];

    const [attendenceNotification, setAttendenceNotification] = useState(false);
    const [checkIn, setCheckIn] = useState(false);
    const [checkOut, setCheckOut] = useState(false);
    const [absent, setAbsent] = useState(false);
    const [sentAt, setSentAt] = useState(new Date());
    const [sendTo, setSendTo] = useState("");

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await getClassAPI(id);
            const sessionClass = response.data;

            setName(sessionClass.name);
            setStartDate(new Date(sessionClass.start_date));
            setEndDate(new Date(sessionClass.end_date));
            // setFeedUrlAt(new Date(sessionClass.send_feed_link_at));
            setSelectedSession(
                sessionClass.session != null
                    ? [
                          {
                              id: sessionClass.session.id,
                              label: `${sessionClass.session.name} [${
                                  sessionClass.session.site?.name ?? ""
                              }]`,
                          },
                      ]
                    : []
            );
            setAutoCheckoutEnable(sessionClass.auto_checkout);
            setSendCheckoutNotification(
                sessionClass.send_checkout_notification
            );
            setSendReportTo(sessionClass.send_report_to);
            setAttendenceNotification(sessionClass.attendance_notification);
            setCheckIn(sessionClass.attendance_checked_in);
            setCheckOut(sessionClass.attendance_checked_out);
            setAbsent(sessionClass.attendance_absent);
            setSendTo(sessionClass.attendance_to);

            if (sessionClass.attendance_send_on.length > 0) {
                const myArray = sessionClass.attendance_send_on.split(",");

                var days = myArray.map((item: any) => item);

                // setSelectedDays(days);
                setNotificationDays(days);
            }

            if (sessionClass.attendance_sent_at != null) {
                let parts = sessionClass.attendance_sent_at.split(":");
                if (parts != null) {
                    let hours = parseInt(parts[0]);
                    let minutes = parseInt(parts[1]);

                    let date = new Date(Date.now());
                    date.setUTCHours(hours, minutes);
                    setSentAt(date);
                }
            }

            if (sessionClass.auto_checkout_at != null) {
                let parts = sessionClass.auto_checkout_at.split(":");
                if (parts != null) {
                    let hours = parseInt(parts[0]);
                    let minutes = parseInt(parts[1]);

                    let date = new Date(Date.now());
                    date.setUTCHours(hours, minutes);

                    setAutoCheckoutAt(date);
                }
            }

            if (sessionClass.send_feed_link_at != null) {
                let parts = sessionClass.send_feed_link_at.split(":");
                if (parts != null) {
                    let hours = parseInt(parts[0]);
                    let minutes = parseInt(parts[1]);

                    let date = new Date(Date.now());
                    date.setUTCHours(hours, minutes);

                    setFeedUrlAt(date);
                }
            }

            setArchive(sessionClass.archive);
            setDraft(sessionClass.draft);
        } catch (e: any) {
            handleException(history, e);
        }

        getSessions();
    };

    const getSessions = async () => {
        try {
            const response = await getSessionsAPI(["draft"]);
            setSessions(response.data);
        } catch (e: any) {
            handleException(history, e);
        }
        setPageLoading(false);
    };

    const handleChange = (value: any[]) => {
        console.log("value", value);
        setNotificationDays(value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (selectedSession.length !== 1) {
            ToastNotification.error("Please select session");
            return;
        }

        updateSessionClass();
    };

    const updateSessionClass = async () => {
        let result = notificationDays.map((a: any) => a);
        console.log(result);
        let send_on_day = result.join(",");
        setUpdating(true);
        try {
            const response = await editClassAPI(id, {
                name: name,
                startDate: startDate,
                endDate: endDate,
                sendFeedUrlAt: feedUrlAt,
                sessionId: selectedSession[0].id,
                draft: draft,
                archive: archive,
                autoCheckout: autoCheckoutEnable,
                sendReportTo: sendReportTo,
                autoCheckoutAt: autoCheckoutAt,
                sendCheckoutNotification: sendCheckoutNotification,
                attendanceNotification: attendenceNotification,
                sendOn: send_on_day,
                checkedIn: checkIn,
                checkedOut: checkOut,
                absent: absent,
                sendAt: sentAt,
                sendTo: sendTo,
            });
            if (response.isSuccess()) {
                history.goBack();
                ToastNotification.success("Class is edited");
            }
        } catch (e: any) {
            if (e instanceof BaseException) {
                if (e.isFormDataError()) {
                    setErrors(e.getErrors());
                } else {
                    handleException(history, e);
                }
            } else {
                ToastNotification.unknownError();
            }
        }
        setUpdating(false);
    };

    return (
        <>
            <PageLoader loading={pageLoading} />

            {!pageLoading && (
                <div>
                    <Row>
                        <Col>
                            <PageTitle
                                items={[
                                    {
                                        label: "Classes",
                                        path: "/classes",
                                    },
                                    {
                                        label: "Create",
                                        active: true,
                                    },
                                ]}
                                title={"Edit ".concat(
                                    name.length > 0 ? name : "Class"
                                )}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={8} className="mx-auto">
                            <Card>
                                <Card.Body>
                                    <FormInput
                                        required
                                        type="text"
                                        label="Class Name"
                                        placeholder="Class Name"
                                        value={name}
                                        errors={errors}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        name="name"
                                    />

                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label>Start Date</Form.Label>

                                            <DatePicker
                                                showAddon={true}
                                                showTimeSelect
                                                tI={30}
                                                errors={errors}
                                                name="start_date"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                timeCaption="time"
                                                value={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                }}
                                            />
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Label>End Date</Form.Label>

                                            <DatePicker
                                                showAddon={true}
                                                showTimeSelect
                                                tI={30}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                timeCaption="time"
                                                value={endDate}
                                                errors={errors}
                                                name="end_date"
                                                onChange={(date) => {
                                                    setEndDate(date);
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <Form.Label className="mt-3">
                                        Send Feed Text At
                                    </Form.Label>

                                    <DatePicker
                                        showAddon={true}
                                        showTimeSelect
                                        showTimeSelectOnly={true}
                                        tI={1}
                                        dateFormat="h:mm aa"
                                        timeCaption="time"
                                        value={feedUrlAt}
                                        errors={errors}
                                        name="send_feed_link_at"
                                        onChange={(date) => {
                                            setFeedUrlAt(date);
                                        }}
                                    />

                                    <Form.Label className="mt-3">
                                        Session
                                    </Form.Label>

                                    <CustomSelect
                                        id="sessions"
                                        multiple={false}
                                        onChange={(e) => setSelectedSession(e)}
                                        options={(sessions ?? []).map((s) => ({
                                            id: s.id,
                                            label: `${s.name} [${
                                                s.site?.name ?? ""
                                            }]`,
                                        }))}
                                        placeholder="Select a session"
                                        selected={selectedSession}
                                    />

                                    <FormCheck
                                        className={"my-3"}
                                        type="checkbox"
                                        id="auto-checkout-checkbox"
                                        label="Enable Auto Checkout"
                                        checked={autoCheckoutEnable}
                                        onChange={(e) =>
                                            setAutoCheckoutEnable(
                                                e.target.checked
                                            )
                                        }
                                    />

                                    {autoCheckoutEnable && (
                                        <div>
                                            {/*<FormCheck*/}
                                            {/*    className={'my-3'}*/}
                                            {/*    type="checkbox"*/}
                                            {/*    id="send-checkout-notification-checkbox"*/}
                                            {/*    label="Send Checkout Notification"*/}
                                            {/*    checked={sendCheckoutNotification}*/}
                                            {/*    onChange={(e) => setSendCheckoutNotification(e.target.checked)}*/}
                                            {/*/>*/}

                                            <div
                                                className={
                                                    "d-flex align-items-center"
                                                }
                                            >
                                                <Form.Label
                                                    className={"mb-0 me-3"}
                                                >
                                                    Checkout at
                                                </Form.Label>

                                                <DatePicker
                                                    showAddon={true}
                                                    showTimeSelect
                                                    showTimeSelectOnly={true}
                                                    tI={1}
                                                    dateFormat="h:mm aa"
                                                    timeCaption="time"
                                                    value={autoCheckoutAt}
                                                    errors={errors}
                                                    name="checkout_at"
                                                    onChange={(date) => {
                                                        setAutoCheckoutAt(date);
                                                    }}
                                                />
                                            </div>

                                            <div
                                                className={
                                                    "d-flex align-items-center"
                                                }
                                            >
                                                <Form.Label
                                                    className={"mb-0 me-3"}
                                                >
                                                    Send Report To
                                                </Form.Label>

                                                <FormInput
                                                    type="email"
                                                    name="send_report_to"
                                                    onChange={(e) =>
                                                        setSendReportTo(
                                                            e.target.value
                                                        )
                                                    }
                                                    value={sendReportTo}
                                                    errors={errors}
                                                    placeholder="Email Address"
                                                    containerClass={"mt-3"}
                                                />
                                            </div>

                                            <hr
                                                style={{
                                                    margin: "8px 0px",
                                                    borderStyle: "dashed",
                                                    border: 0,
                                                    borderTop:
                                                        "1px dashed rgba(135, 135, 135, 0.81)",
                                                    opacity: 0.25,
                                                }}
                                            />
                                        </div>
                                    )}
                                    <Row>
                                        <div>
                                            <FormCheck
                                                className={"my-3"}
                                                type="checkbox"
                                                id="attendence-notification-checkbox"
                                                label="Attendance Notification"
                                                checked={attendenceNotification}
                                                onChange={(e) =>
                                                    setAttendenceNotification(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                            {attendenceNotification && (
                                                <div>
                                                    <div
                                                        className={
                                                            "d-flex align-items-center"
                                                        }
                                                    >
                                                        {" "}
                                                        <Form.Label
                                                            className={
                                                                "mb-0 me-3"
                                                            }
                                                        >
                                                            Send On
                                                        </Form.Label>
                                                        {/* <MultiSelect
                                                            options={options}
                                                            value={selectedDays}
                                                            onChange={
                                                                setSelectedDays
                                                            }
                                                            overrideStrings={{
                                                                allItemsAreSelected:
                                                                    "All days",
                                                                selectSomeItems:
                                                                    "Select Day",
                                                                selectAll:
                                                                    "Select All Day",
                                                            }}
                                                            labelledBy="Select"
                                                        /> */}
                                                        <ToggleButtonGroup
                                                            type="checkbox"
                                                            value={
                                                                notificationDays
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        >
                                                            {options.map(
                                                                (
                                                                    day,
                                                                    index
                                                                ) => (
                                                                    <ToggleButton
                                                                        value={
                                                                            day.value
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                        id={`tbg-check-${index}`}
                                                                        style={{
                                                                            backgroundColor:
                                                                                notificationDays.includes(
                                                                                    day.value
                                                                                )
                                                                                    ? "var(--ct-form-check-input-checked-bg-color)"
                                                                                    : "var(--ct-primary-color)",
                                                                            color: notificationDays.includes(
                                                                                day.value
                                                                            )
                                                                                ? "white"
                                                                                : "var(--ct-primary-color)",
                                                                            border: "1px solid var(--ct-input-border-color)",
                                                                        }}
                                                                    >
                                                                        {
                                                                            day.label
                                                                        }
                                                                    </ToggleButton>
                                                                    // <>
                                                                    //     <button
                                                                    //         style={{
                                                                    //             width: "auto",
                                                                    //             border: "1px solid var(--ct-input-border-color)",
                                                                    //             display:
                                                                    //                 "inline-flex",
                                                                    //         }}
                                                                    //         value={
                                                                    //             days
                                                                    //         }
                                                                    //         onChange={(value) => {
                                                                    //             // setDays(value)
                                                                    //         }}
                                                                    //         className='p-0'
                                                                    //     >
                                                                    //         <button
                                                                    //             style={{
                                                                    //                 minWidth: 32,
                                                                    //                 maxWidth: 32,
                                                                    //                 height: 32,
                                                                    //                 padding:
                                                                    //                     "0px 9px 0px 8px",
                                                                    //                 textTransform:
                                                                    //                     "unset",
                                                                    //                 fontSize:
                                                                    //                     "0.75rem",
                                                                    //                 color: "white",
                                                                    //                 border: "none",
                                                                    //                 backgroundColor:
                                                                    //                     "var(--ct-form-check-input-checked-bg-color)",
                                                                    //             }}
                                                                    //             key={
                                                                    //                 day.value
                                                                    //             }
                                                                    //             value={index}
                                                                    //         >
                                                                    //             {
                                                                    //                 day.label
                                                                    //             }
                                                                    //         </button>
                                                                    //     </button>
                                                                    // </>
                                                                )
                                                            )}
                                                        </ToggleButtonGroup>
                                                    </div>
                                                    <Form.Label
                                                        className={"mt-3"}
                                                    >
                                                        Send For
                                                    </Form.Label>
                                                    <FormCheck
                                                        className={"my-3"}
                                                        type="checkbox"
                                                        id="check-in-checkbox"
                                                        label="Checked In"
                                                        checked={checkIn}
                                                        onChange={(e) =>
                                                            setCheckIn(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                    <FormCheck
                                                        className={"my-3"}
                                                        type="checkbox"
                                                        id="check-out-checkbox"
                                                        label="Checked Out"
                                                        checked={checkOut}
                                                        onChange={(e) =>
                                                            setCheckOut(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                    <FormCheck
                                                        className={"my-3"}
                                                        type="checkbox"
                                                        id="absent-checkbox"
                                                        label="Absent"
                                                        checked={absent}
                                                        onChange={(e) =>
                                                            setAbsent(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                    <div
                                                        className={
                                                            "d-flex align-items-center"
                                                        }
                                                    >
                                                        <Form.Label
                                                            className={
                                                                "mb-0 me-3"
                                                            }
                                                        >
                                                            Send At
                                                        </Form.Label>

                                                        <DatePicker
                                                            showAddon={true}
                                                            showTimeSelect
                                                            showTimeSelectOnly={
                                                                true
                                                            }
                                                            tI={30}
                                                            dateFormat="h:mm aa"
                                                            timeCaption="time"
                                                            value={sentAt}
                                                            errors={errors}
                                                            name="Sent At"
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                setSentAt(date);
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            "d-flex align-items-center"
                                                        }
                                                    >
                                                        <Form.Label
                                                            className={
                                                                "mb-0 me-3"
                                                            }
                                                        >
                                                            Send To
                                                        </Form.Label>

                                                        <FormInput
                                                            type="email"
                                                            name="Send to"
                                                            onChange={(e) =>
                                                                setSendTo(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            value={sendTo}
                                                            errors={errors}
                                                            placeholder="Email Address"
                                                            containerClass={
                                                                "mt-3"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <hr
                                                style={{
                                                    margin: "8px 0px",
                                                    borderStyle: "dashed",
                                                    border: 0,
                                                    borderTop:
                                                        "1px dashed rgba(135, 135, 135, 0.81)",
                                                    opacity: 0.25,
                                                }}
                                            />
                                        </div>
                                    </Row>

                                    <Row className="mt-3 align-items-end">
                                        <Col>
                                            <FormCheck
                                                type="checkbox"
                                                id="default-checkbox1"
                                                label="Archived"
                                                checked={archive}
                                                onChange={(e) =>
                                                    setArchive(e.target.checked)
                                                }
                                            />

                                            <FormCheck
                                                className={"mt-2"}
                                                type="checkbox"
                                                id="default-checkbox2"
                                                label="Draft"
                                                checked={draft}
                                                onChange={(e) =>
                                                    setDraft(e.target.checked)
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                type="submit"
                                                className="float-end"
                                                onClick={handleSubmit}
                                                disabled={updating}
                                            >
                                                <i
                                                    className={
                                                        (updating
                                                            ? "mdi mdi-spin mdi-loading"
                                                            : "mdi mdi-check") +
                                                        " me-1"
                                                    }
                                                />{" "}
                                                Update
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default EditClass;
