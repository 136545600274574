import Request from "./request";
import Response from "./response";
import { parseException } from "../../utils/exceptions/parse_exception";
import { PhoneNumberModel } from "./models";

export const getPhoneNumbersAPI = async () => {
    try {
        const response = await Request.getAuth("phone_numbers/");
        return new Response<PhoneNumberModel[]>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const getPhoneNumberAPI = async ({ id }: { id: number }) => {
    try {
        const response = await Request.getAuth(`phone_numbers/${id}/`);
        return new Response<PhoneNumberModel>(response.data, response.status);
    } catch (e) {
        throw parseException(e);
    }
};

export const editPhoneNumberAPI = async (
    id: number,
    input: {
        name?: string;
        farwarding_number?: string;
    }
) => {
    try {
        const response = await Request.patchAuth(`phone_numbers/${id}/`, input);
        return new Response(response.data, response.status);
    } catch (error) {
        throw parseException(error);
    }
};

export function formatPhoneNumber(value: string) {
    if (!value) return value;

    let phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    if (phoneNumberLength > 10) {
        phoneNumber = phoneNumber.slice(phoneNumberLength - 10);
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
}
