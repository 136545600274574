import React from "react";
import {
    Route,
    Redirect,
    RouteProps,
    RouteComponentProps,
} from "react-router-dom";
import { isAuthenticated } from "../services/api/auth";

interface PrivateRouteProps {
    component: React.FunctionComponent<RouteProps>;
    roles?: string;
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({
    component: Component,
    roles,
    ...rest
}: PrivateRouteProps) => {
    return (
        <Route
            {...rest}
            render={(props: RouteComponentProps) => {
                if (!isAuthenticated()) {
                    // not logged in so redirect to login page with the return url
                    return (
                        <Redirect
                            to={{
                                pathname: "/auth/login",
                                state: { from: props["location"] },
                            }}
                        />
                    );
                }
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
