import axios, { AxiosRequestConfig } from "axios";
import LocalStorage from "../local_storage";

export enum RequestType {
    auth,
    guest,
}

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const DEFAULT_API_URL = "http://127.0.0.1:8000";
const STAGE_SERVER = "https://staging-api.golooksee.us";
const PROD_SERVER = "https://admin.golooksee.us";
export default class Request {
    static _delay = false;
    static _delayTime = 2000;

    static BASE_URL =
        process.env.REACT_APP_ENV === "stage"
            ? STAGE_SERVER
            : process.env.REACT_APP_ENV === "prod"
            ? PROD_SERVER
            : DEFAULT_API_URL;

    static BASE_URL_API = Request.BASE_URL + "/api/v1/";

    static async get(url: string, config?: AxiosRequestConfig) {
        if (this._delay) await sleep(this._delayTime);

        return axios.get(this.BASE_URL_API + url, config);
    }

    static async post(url: string, data?: any, config?: AxiosRequestConfig) {
        if (this._delay) await sleep(this._delayTime);
        return axios.post(this.BASE_URL_API + url, data, config);
    }

    static async patch(url: string, data?: any, config?: AxiosRequestConfig) {
        if (this._delay) await sleep(this._delayTime);
        return axios.patch(this.BASE_URL_API + url, data, config);
    }

    static async put(url: string, data?: any, config?: AxiosRequestConfig) {
        if (this._delay) await sleep(this._delayTime);
        return axios.put(this.BASE_URL_API + url, data, config);
    }

    static async delete(url: string, config?: AxiosRequestConfig) {
        if (this._delay) await sleep(this._delayTime);
        return axios.delete(this.BASE_URL_API + url, config);
    }

    static getAuth(url: string) {
        return this.get(url, this.getAuthHeaderConfig());
    }

    static postAuth(
        url: string,
        data?: any,
        withMultiFormData: boolean = false
    ) {
        return this.post(
            url,
            data,
            this.getAuthHeaderConfig(withMultiFormData)
        );
    }

    static patchAuth(
        url: string,
        data?: any,
        withMultiFormData: boolean = false
    ) {
        return this.patch(
            url,
            data,
            this.getAuthHeaderConfig(withMultiFormData)
        );
    }

    static putAuth(
        url: string,
        data?: any,
        withMultiFormData: boolean = false
    ) {
        return this.put(url, data, this.getAuthHeaderConfig(withMultiFormData));
    }

    static deleteAuth(url: string, withMultiFormData: boolean = false) {
        return this.delete(url, this.getAuthHeaderConfig(withMultiFormData));
    }

    private static getAuthHeaderConfig(withMultiFormData: boolean = false) {
        const token: string | null = LocalStorage.getToken();
        const headers: any = {
            Authorization: "Token " + token ?? "",
        };
        if (withMultiFormData) {
            headers["Content-Type"] = "multipart/form-data";
        }
        return {
            headers: headers,
        };
    }

    static getImageUrl = (url: string): string => {
        return Request.BASE_URL + url;
    };
    static getImageUrlOrNull = (url?: string): string | null => {
        if (url == null) {
            return null;
        }
        return Request.getImageUrl(url);
    };

    static getBase64FromUrl = async (
        url: string
    ): Promise<string | undefined> => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                if (base64data) {
                    resolve(base64data.toString().split(";base64,").pop());
                }
                resolve(undefined);
            };
        });
    };

    static getFile(url: string, fileName = "file.pdf") {
        return axios
            .get(this.BASE_URL_API + url, {
                ...this.getAuthHeaderConfig(),
                responseType: "blob",
            })
            .then((response) => {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    }
}
